import React, { useState } from "react";

const Network = ({ style, handler }) => {
  const drops = ['Airtel', 'Glo', 'Mtn', '9mobile'];

  const [dropActive, setDropActive] = useState(false);
  const [drop, setDrop] = useState(drops[0]);

  const toggleDrop = () => setDropActive(!dropActive);

  return (
    <div>
      <div>
        <p style={{ color: dropActive ? 'white' : 'black' }}>Network</p>
        <div
          className="drop"
          style={{
            width: 400,
            height: 60,
            borderRadius: 10,
            borderWidth: 1,
            borderColor: '#B7DF84',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 16px',
            alignItems: 'center',
            marginTop: 15,
            cursor: 'pointer', // Add cursor pointer for better UX
          }}
          onClick={toggleDrop}
        >
          <p style={{ color: dropActive ? 'white' : 'black' }}>{drop}</p>
          {/*<EntypoIcon
            name={dropActive ? 'chevron-small-up' : 'chevron-small-down'}
            color="#9D9D9D"
            size={30}
            handler={toggleDrop}
        />*/}
        </div>
      </div>

      {dropActive && (
        <div
          className="drop-list"
          style={{
            width: '100%',
            borderRadius: 10,
            backgroundColor: 'white',
            padding: 20,
            borderWidth: 1,
            borderColor: '#e7e7ed',
          }}
        >
          {drops.map((_, i) => (
            <div
              key={i}
              className={
                drop === _ ? 'drop-list-item ACTIVE' : 'drop-list-item'
              }
              style={{
                height: 35,
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 12,
                cursor: 'pointer', // Add cursor pointer for better UX
              }}
              onClick={() => {
                setDrop(_);
                setDropActive(!dropActive);
                handler(_);
              }}
            >
              <p style={{ color: 'black' }}>{_}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Network;
