// axios-config.js
import axios from 'axios';


axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      // Network error (no internet connection)
      window.alert('No internet connection. Please check your network.');
    }
    return Promise.reject(error);
  }
);

export default axios;
