//tabs active
export const active0 = require('./img/tab/active/active0.png');
export const active2 = require('./img/tab/active/active2.png');
//tabs inactive
export const inactive0 = require('./img/tab/inactive/inactive0.png');
export const inactive1 = require('./img/tab/inactive/inactive1.png');
export const inactive2 = require('./img/tab/inactive/inactive2.png');
export const inactive3 = require('./img/tab/inactive/inactive3.png');
//
export const o1 = require('./img/tab/01.png');
export const o2 = require('./img/tab/02.png');
export const o3 = require('./img/tab/03.png');
export const o4 = require('./img/tab/04.png');
//common
export const logo = require('./img/common/logo.png');
export const biometric = require('./img/common/biometric.png');
export const google = require('./img/common/google.png');
export const faceId = require('./img/common/faceId.png');
export const giftCard = require('./img/common/giftcard.png');
export const Vector = require('./img/common/Vector.png');
export const euro = require('./img/common/euro.png');
export const nzd = require('./img/common/nzd.png');
export const aud = require('./img/common/aud.png');
export const usa = require('./img/common/usa.png');
export const gbp = require('./img/common/gbp.png');
export const cad = require('./img/common/cad.png');
export const ohm = require('./img/common/ohm.png');
export const loginpic = require('./img/common/login.jpeg');
//home
export const tv = require('./img/home/tv.png');
export const bills = require('./img/home/bills.png');
export const airtime = require('./img/home/airtime.png');
export const data = require('./img/home/data.png');
export const avatar = require('./img/home/avatar.png');
export const betting = require('./img/home/betting.png');
export const dstv = require('./img/home/dstv.png');
export const sportybet = require('./img/home/sportybet.png');
export const Onexbet = require('./img/home/1xbet.png');
export const bangbet = require('./img/home/bangbet.png');
export const betbonanza = require('./img/home/betbonanza.png');
export const betking = require('./img/home/betking.png');
export const betlion = require('./img/common/betlion.png');
export const betway = require('./img/home/betway.png');
export const easywin = require('./img/home/easywin.png');
export const frapapa = require('./img/home/frapapa.png');
export const ilot = require('./img/home/ILOT.jpeg');
export const megamillions = require('./img/home/megamillions.png');
export const startimes = require('./img/home/Startimes.jpeg');
export const merrybet = require('./img/home/merrybet.png');
export const mylottohub = require('./img/home/mylottohub.png');
export const naijabet = require('./img/home/naijabet.png');
export const nairabet = require('./img/home/nairabet.png');
export const nairamillion = require('./img/home/nairamillion.jpeg');
export const parimatch = require('./img/home/parimatch.jpeg');
export const paripesa = require('./img/home/Paripesa.png');
export const yangasport = require('./img/home/yangasport.jpeg');
export const zebet = require('./img/home/zebet.jpeg');
export const bet9ja = require('./img/home/bet9ja.png');
export const gotv = require('./img/home/gotv.png');
export const showmax = require('./img/home/showmax.png');
export const book = require('./img/home/book1.png');
export const world = require('./img/home/world1.png');
export const water = require('./img/home/water.png');
export const benin = require('./img/home/benin.png');
export const ekedc = require('./img/home/ekedc.png');
export const aedc = require('./img/home/aedc.png');
export const kedc = require('./img/home/kedc.png');
export const eedc = require('./img/home/eedc.jpeg');
export const phed = require('./img/home/phed.jpeg');
export const jed = require('./img/home/jed.png');
export const kedco = require('./img/home/kedco.png');
export const transport = require('./img/home/transport.png');
export const electricity = require('./img/home/electricity.png');
export const ikedc = require('./img/home/ikedc.png');
export const ibedc = require('./img/home/ibedc.png');
export const apple = require('./img/home/apple.png');
export const amazon = require('./img/home/amazon.png');
export const nordstrom = require('./img/home/nordstrom.png');
export const Amx = require('./img/home/Amx.jpg');
export const binance = require('./img/home/binance.jpg');
export const eaplay = require('./img/home/eaplay.jpg');
export const ebay = require('./img/home/ebay.jpg');
export const footlocker = require('./img/home/footlocker.jpg');
export const freefire = require('./img/home/freefire.jpg');
export const googleplay = require('./img/home/googleplay.jpg');
export const itunes = require('./img/home/itunes.jpg');
export const macys = require('./img/home/macys.jpg');
export const netflix = require('./img/home/netflix.jpg');
export const nike = require('./img/home/nike.jpg');
export const othergiftcards = require('./img/home/othergiftcards.jpg');
export const psn = require('./img/home/psn.jpg');
export const razergold = require('./img/home/razergold.jpg');
export const sephora = require('./img/home/sephora.jpg');
export const steam = require('./img/home/steam.jpg');
export const Target = require('./img/home/Target.jpg');
export const vanilla = require('./img/home/vanilla.jpg');
export const xbox = require('./img/home/xbox.jpg');
export const Jointeepay = require('./img/common/Jointeepay.jpeg');
export const Awoof = require('./img/common/Awoof.jpeg');
export const Bestrate = require('./img/common/Bestrate.jpeg');
export const Bestrate2 = require('./img/common/Bestrate2.jpeg');
export const Download = require('./img/common/Download.jpeg');
export const Dontrunout = require('./img/common/Dontrunout.jpeg');
export const Followus = require('./img/common/Followus.jpeg');
export const Openingsoon = require('./img/common/Openingsoon.jpeg');
export const Paybills = require('./img/common/Paybills.jpeg');
export const Sendmoney = require('./img/common/Sendmoney.jpeg');
export const Smile = require('./img/common/Smile.jpeg');
export const Topup = require('./img/common/Topup.jpeg');
export const TVimage = require('./img/common/TVimage.jpeg');
export const waec = require('./img/common/waec.jpeg');
export const neco = require('./img/common/neco.jpeg');
export const betland = require('./img/common/betland.jpeg');
export const supabet = require('./img/common/supabet.png');
export const livescorebet = require('./img/common/livescorebet.png');
export const surebet = require('./img/common/surebet.png');
export const cloudbet = require('./img/common/cloudbet.png');

//profile
export const profile_pic = require('./img/profile/profile-pic.png');
export const contact = require('./img/profile/contact.png');
export const dark_mode = require('./img/profile/dark-mode.png');
export const help = require('./img/profile/help.png');
export const logout = require('./img/profile/logout.png');
export const language = require('./img/profile/language.png');
export const password = require('./img/profile/password.png');
export const notification = require('./img/profile/notification.png');
export const info = require('./img/profile/info.png');

// logos
export const _OFGR = require('./img/common/logos/0FGR.png');
export const _0JMM = require('./img/common/logos/0JMM.png');
export const _0RGM = require('./img/common/logos/0RGM.png');
export const _0RSL = require('./img/common/logos/0RSL.png');
export const _0UGM = require('./img/common/logos/0UGM.png');
export const _0USL = require('./img/common/logos/0USL.png');
export const _0VGM = require('./img/common/logos/0VGM.png');
export const _0WMW = require('./img/common/logos/0WMW.png');
export const _1AIN = require('./img/common/logos/1AIN.png');
export const _1DIN = require('./img/common/logos/1DIN.png');
export const _1GMZ = require('./img/common/logos/1GMZ.png');
export const _1KPH = require('./img/common/logos/1KPH.png');
export const _1PBJ = require('./img/common/logos/1PBJ.png');
export const _1QBJ = require('./img/common/logos/1QBJ.png');
export const _1SIN = require('./img/common/logos/1SIN.png');
export const _1UIN = require('./img/common/logos/1UIN.png');
export const _1VIN = require('./img/common/logos/1VIN.png');
export const _1ZTG = require('./img/common/logos/1ZTG.png');
export const _2AAE = require('./img/common/logos/2AAE.png');
export const _2AGB = require('./img/common/logos/2AGB.png');
export const _2ASA = require('./img/common/logos/2ASA.png');
export const _2AUS = require('./img/common/logos/2AUS.png');
export const _2FKM = require('./img/common/logos/2FKM.png');
export const _2NSN = require('./img/common/logos/2NSN.png');
export const _2RKZ = require('./img/common/logos/2RKZ.png');
export const _2SID = require('./img/common/logos/2SID.png');
export const _2VUS = require('./img/common/logos/2VUS.png');
export const _2XID = require('./img/common/logos/2XID.png');
export const _34BF = require('./img/common/logos/34BF.png');
export const _35CI = require('./img/common/logos/35CI.png');
export const _36GH = require('./img/common/logos/36GH.png');
export const _39EG = require('./img/common/logos/39EG.png');
export const _3AKZ = require('./img/common/logos/3AKZ.png');
export const _3GAE = require('./img/common/logos/3GAE.png');
export const _3GSA = require('./img/common/logos/3GSA.png');
export const _3HOM = require('./img/common/logos/3HOM.png');
export const _3SAU = require('./img/common/logos/3SAU.png');
export const _3SSG = require('./img/common/logos/3SSG.png');
export const _4AKZ = require('./img/common/logos/4AKZ.png');
export const _4FAZ = require('./img/common/logos/4FAZ.png');
export const _4HGH = require('./img/common/logos/4HGH.png');
export const _4RUS = require('./img/common/logos/4RUS.png');
export const _5ANG = require('./img/common/logos/5ANG.png');
export const _5CMR = require('./img/common/logos/5CMR.png');
export const _5FMR = require('./img/common/logos/5FMR.png');
export const _5GMR = require('./img/common/logos/5GMR.png');
export const _5MMR = require('./img/common/logos/5MMR.png');
export const _7MMR = require('./img/common/logos/7MMR.png');
export const _8MMR = require('./img/common/logos/8MMR.png');
export const _8TZA = require('./img/common/logos/8TZA.png');
export const _9CUS = require('./img/common/logos/9CUS.png');
export const _9MNG = require('./img/common/logos/9MNG.png');
export const _9RUS = require('./img/common/logos/9RUS.png');
export const _9SUS = require('./img/common/logos/9SUS.png');
export const _AALB = require('./img/common/logos/AALB.png');
export const _ACIQ = require('./img/common/logos/ACIQ.png');
export const _AEID = require('./img/common/logos/AEID.png');
export const _AFCD = require('./img/common/logos/AFCD.png');
export const _AFGM = require('./img/common/logos/AFGM.png');
export const _AFSL = require('./img/common/logos/AFSL.png');
export const _AFUG = require('./img/common/logos/AFUG.png');
export const _AICD = require('./img/common/logos/AICD.png');
export const _AIGH = require('./img/common/logos/AIGH.png');
export const _AIIN = require('./img/common/logos/AIIN.png');
export const _AIKE = require('./img/common/logos/AIKE.png');
export const _AILK = require('./img/common/logos/AILK.png');
export const _AIMW = require('./img/common/logos/AIMW.png');
export const _AITZ = require('./img/common/logos/AITZ.png');
export const _AIZM = require('./img/common/logos/AIZM.png');
export const _AJID = require('./img/common/logos/AJID.png');
export const _AQAF = require('./img/common/logos/AQAF.png');
export const _ASES = require('./img/common/logos/ASES.png');
export const _AVTR = require('./img/common/logos/AVTR.png');
export const _AWAF = require('./img/common/logos/AWAF.png');
export const _AXBD = require('./img/common/logos/AXBD.png');
export const _BEKG = require('./img/common/logos/BEKG.png');
export const _BEKZ = require('./img/common/logos/BEKZ.png');
export const _BERU = require('./img/common/logos/BERU.png');
export const _BETJ = require('./img/common/logos/BETJ.png');
export const _BEUZ = require('./img/common/logos/BEUZ.png');
export const _BFAZ = require('./img/common/logos/BFAZ.png');
export const _BGBD = require('./img/common/logos/BGBD.png');
export const _BHET = require('./img/common/logos/BHET.png');
export const _BLIN = require('./img/common/logos/BLIN.png');
export const _BMTJ = require('./img/common/logos/BMTJ.png');
export const _BOAU = require('./img/common/logos/BOAU.png');
export const _BTES = require('./img/common/logos/BTES.png');
export const _BVBH = require('./img/common/logos/BVBH.png');
export const _C1CN = require('./img/common/logos/C1CN.png');
export const _C2CN = require('./img/common/logos/C2CN.png');
export const _CEZA = require('./img/common/logos/CEZA.png');
export const _CFGR = require('./img/common/logos/CFGR.png');
export const _CFRO = require('./img/common/logos/CFRO.png');
export const _CGKH = require('./img/common/logos/CGKH.png');
export const _CHGN = require('./img/common/logos/CHGN.png');
export const _CKCA = require('./img/common/logos/CKCA.png');
export const _CMGN = require('./img/common/logos/CMGN.png');
export const _CMLR = require('./img/common/logos/CMLR.png');
export const _CNMY = require('./img/common/logos/CNMY.png');
export const _CSES = require('./img/common/logos/CSES.png');
export const _DGLK = require('./img/common/logos/DGLK.png');
export const _DIMY = require('./img/common/logos/DIMY.png');
export const _DJDZ = require('./img/common/logos/DJDZ.png');
export const _DNNP = require('./img/common/logos/DNNP.png');
export const _DSES = require('./img/common/logos/DSES.png');
export const _E6AE = require('./img/common/logos/E6AE.png');
export const _E7US = require('./img/common/logos/E7US.png');
export const _ECBI = require('./img/common/logos/ECBI.png');
export const _ECZW = require('./img/common/logos/ECZW.png');
export const _ESAU = require('./img/common/logos/ESAU.png');
export const _ESES = require('./img/common/logos/ESES.png');
export const _ESGB = require('./img/common/logos/ESGB.png');
export const _ESSA = require('./img/common/logos/ESSA.png');
export const _ETAE = require('./img/common/logos/ETAE.png');
export const _ETAF = require('./img/common/logos/ETAF.png');
export const _ETEG = require('./img/common/logos/ETEG.png');
export const _ETNG = require('./img/common/logos/ETNG.png');
export const _EXID = require('./img/common/logos/EXID.png');
export const _FAAE = require('./img/common/logos/FAAE.png');
export const _FHUS = require('./img/common/logos/FHUS.png');
export const _FIUS = require('./img/common/logos/FIUS.png');
export const _FROM = require('./img/common/logos/FROM.png');
export const _FRSA = require('./img/common/logos/FRSA.png');
export const _FUAE = require('./img/common/logos/FUAE.png');
export const _G0PH = require('./img/common/logos/G0PH.png');
export const _G6GM = require('./img/common/logos/G6GM.png');
export const _G7GN = require('./img/common/logos/G7GN.png');
export const _GAGM = require('./img/common/logos/GAGM.png');
export const _GBPH = require('./img/common/logos/GBPH.png');
export const _GEBD = require('./img/common/logos/GEBD.png');
export const _GLNG = require('./img/common/logos/GLNG.png');
export const _GMVN = require('./img/common/logos/GMVN.png');
export const _GPAE = require('./img/common/logos/GPAE.png');
export const _GPSA = require('./img/common/logos/GPSA.png');
export const _GPUS = require('./img/common/logos/GPUS.png');
export const _H5ES = require('./img/common/logos/H5ES.png');
export const _HAAE = require('./img/common/logos/HAAE.png');
export const _HEPL = require('./img/common/logos/HEPL.png');
export const _HMES = require('./img/common/logos/HMES.png');
export const _HPES = require('./img/common/logos/HPES.png');
export const _HSLK = require('./img/common/logos/HSLK.png');
export const _HTID = require('./img/common/logos/HTID.png');
export const _I2US = require('./img/common/logos/I2US.png');
export const _I3US = require('./img/common/logos/I3US.png');
export const _I4US = require('./img/common/logos/I4US.png');
export const _IAAE = require('./img/common/logos/IAAE.png');
export const _IASA = require('./img/common/logos/IASA.png');
export const _IAUS = require('./img/common/logos/IAUS.png');
export const _KAKZ = require('./img/common/logos/KAKZ.png');
export const _KSUA = require('./img/common/logos/KSUA.png');
export const _KTKE = require('./img/common/logos/KTKE.png');
export const _KXCH = require('./img/common/logos/KXCH.png');
export const _L2IE = require('./img/common/logos/L2IE.png');
export const _L3US = require('./img/common/logos/L3US.png');
export const _LCLR = require('./img/common/logos/LCLR.png');
export const _LIUA = require('./img/common/logos/LIUA.png');
export const _LRES = require('./img/common/logos/LRES.png');
export const _LSES = require('./img/common/logos/LSES.png');
export const _LTML = require('./img/common/logos/LTML.png');
export const _LYAU = require('./img/common/logos/LYAU.png');
export const _LYES = require('./img/common/logos/LYES.png');
export const _LYGB = require('./img/common/logos/LYGB.png');
export const _M4GW = require('./img/common/logos/M4GW.png');
export const _M6KG = require('./img/common/logos/M6KG.png');
export const _MARU = require('./img/common/logos/MARU.png');
export const _MAUA = require('./img/common/logos/MAUA.png');
export const _MBPK = require('./img/common/logos/MBPK.png');
export const _MDDZ = require('./img/common/logos/MDDZ.png');
export const _MFVN = require('./img/common/logos/MFVN.png');
export const _MGAO = require('./img/common/logos/MGAO.png');
export const _MKMZ = require('./img/common/logos/MKMZ.png');
export const _MLSA = require('./img/common/logos/MLSA.png');
export const _MOMA = require('./img/common/logos/MOMA.png');
export const _MRRU = require('./img/common/logos/MRRU.png');
export const _MRTJ = require('./img/common/logos/MRTJ.png');
export const _MSLK = require('./img/common/logos/MSLK.png');
export const _MTAF = require('./img/common/logos/MTAF.png');
export const _MTBJ = require('./img/common/logos/MTBJ.png');
export const _MTCG = require('./img/common/logos/MTCG.png');
export const _MTCI = require('./img/common/logos/MTCI.png');
export const _MTCM = require('./img/common/logos/MTCM.png');
export const _MTCY = require('./img/common/logos/MTCY.png');
export const _MTGH = require('./img/common/logos/MTGH.png');
export const _MTGN = require('./img/common/logos/MTGN.png');
export const _MTGW = require('./img/common/logos/MTGW.png');
export const _MTNG = require('./img/common/logos/MTNG.png');
export const _MTRW = require('./img/common/logos/MTRW.png');
export const _MTSZ = require('./img/common/logos/MTSZ.png');
export const _MTUG = require('./img/common/logos/MTUG.png');
export const _MTYE = require('./img/common/logos/MTYE.png');
export const _MTZA = require('./img/common/logos/MTZA.png');
export const _MTZM = require('./img/common/logos/MTZM.png');
export const _MVBJ = require('./img/common/logos/MVBJ.png');
export const _MVCF = require('./img/common/logos/MVCF.png');
export const _MVCI = require('./img/common/logos/MVCI.png');
export const _MVNE = require('./img/common/logos/MVNE.png');
export const _MVTG = require('./img/common/logos/MVTG.png');
export const _N3NG = require('./img/common/logos/N3NG.png');
export const _N4NG = require('./img/common/logos/N4NG.png');
export const _N5GH = require('./img/common/logos/N5GH.png');
export const _N6GH = require('./img/common/logos/N6GH.png');
export const _N7CM = require('./img/common/logos/N7CM.png');
export const _N8CI = require('./img/common/logos/N8CI.png');
export const _NCNP = require('./img/common/logos/NCNP.png');
export const _NENP = require('./img/common/logos/NENP.png');
export const _NQNP = require('./img/common/logos/NQNP.png');
export const _NYAE = require('./img/common/logos/NYAE.png');
export const _O5KM = require('./img/common/logos/O5KM.png');
export const _O6US = require('./img/common/logos/O6US.png');
export const _O7GN = require('./img/common/logos/O7GN.png');
export const _OMOM = require('./img/common/logos/OMOM.png');
export const _ONBF = require('./img/common/logos/ONBF.png');
export const _OODZ = require('./img/common/logos/OODZ.png');
export const _OOMM = require('./img/common/logos/OOMM.png');
export const _OOOM = require('./img/common/logos/OOOM.png');
export const _OPBF = require('./img/common/logos/OPBF.png');
export const _OPCI = require('./img/common/logos/OPCI.png');
export const _OPGW = require('./img/common/logos/OPGW.png');
export const _OPML = require('./img/common/logos/OPML.png');
export const _OPSL = require('./img/common/logos/OPSL.png');
export const _OPSN = require('./img/common/logos/OPSN.png');
export const _ORCI = require('./img/common/logos/ORCI.png');
export const _ORCM = require('./img/common/logos/ORCM.png');
export const _OREG = require('./img/common/logos/OREG.png');
export const _ORES = require('./img/common/logos/ORES.png');
export const _ORGN = require('./img/common/logos/ORGN.png');
export const _ORJO = require('./img/common/logos/ORJO.png');
export const _ORMA = require('./img/common/logos/ORMA.png');
export const _ORMD = require('./img/common/logos/ORMD.png');
export const _ORMG = require('./img/common/logos/ORMG.png');
export const _ORML = require('./img/common/logos/ORML.png');
export const _ORNE = require('./img/common/logos/ORNE.png');
export const _ORPL = require('./img/common/logos/ORPL.png');
export const _ORRO = require('./img/common/logos/ORRO.png');
export const _ORSL = require('./img/common/logos/ORSL.png');
export const _ORSN = require('./img/common/logos/ORSN.png');
export const _ORTN = require('./img/common/logos/ORTN.png');
export const _OXKG = require('./img/common/logos/OXKG.png');
export const _P0UZ = require('./img/common/logos/P0UZ.png');
export const _PFMM = require('./img/common/logos/PFMM.png');
export const _PGUS = require('./img/common/logos/PGUS.png');
export const _PNAE = require('./img/common/logos/PNAE.png');
export const _PNBH = require('./img/common/logos/PNBH.png');
export const _PNKW = require('./img/common/logos/PNKW.png');
export const _PNLB = require('./img/common/logos/PNLB.png');
export const _PNOM = require('./img/common/logos/PNOM.png');
export const _PNQA = require('./img/common/logos/PNQA.png');
export const _PNSA = require('./img/common/logos/PNSA.png');
export const _PNUS = require('./img/common/logos/PNUS.png');
export const _PSPL = require('./img/common/logos/PSPL.png');
export const _PWPH = require('./img/common/logos/PWPH.png');
export const _QLGM = require('./img/common/logos/QLGM.png');
export const _QLSL = require('./img/common/logos/QLSL.png');
export const _R0AU = require('./img/common/logos/R0AU.png');
export const _RAOM = require('./img/common/logos/RAOM.png');
export const _RHAF = require('./img/common/logos/RHAF.png');
export const _RJIN = require('./img/common/logos/RJIN.png');
export const _RLBD = require('./img/common/logos/RLBD.png');
export const _RPES = require('./img/common/logos/RPES.png');
export const _RZUS = require('./img/common/logos/RZUS.png');
export const _S6SA = require('./img/common/logos/S6SA.png');
export const _S7US = require('./img/common/logos/S7US.png');
export const _SAKE = require('./img/common/logos/SAKE.png');
export const _SCKH = require('./img/common/logos/SCKH.png');
export const _SFYE = require('./img/common/logos/SFYE.png');
export const _SMPH = require('./img/common/logos/SMPH.png');
export const _SSES = require('./img/common/logos/SSES.png');
export const _STPH = require('./img/common/logos/STPH.png');
export const _T4TJ = require('./img/common/logos/T4TJ.png');
export const _T5TH = require('./img/common/logos/T5TH.png');
export const _T6TH = require('./img/common/logos/T6TH.png');
export const _T7TH = require('./img/common/logos/T7TH.png');
export const _THPL = require('./img/common/logos/THPL.png');
export const _TIGH = require('./img/common/logos/TIGH.png');
export const _TILK = require('./img/common/logos/TILK.png');
export const _TISN = require('./img/common/logos/TISN.png');
export const _TITZ = require('./img/common/logos/TITZ.png');
export const _TKID = require('./img/common/logos/TKID.png');
export const _TMLB = require('./img/common/logos/TMLB.png');
export const _TMPH = require('./img/common/logos/TMPH.png');
export const _TPMM = require('./img/common/logos/TPMM.png');
export const _TPPK = require('./img/common/logos/TPPK.png');
export const _TQBD = require('./img/common/logos/TQBD.png');
export const _TUTN = require('./img/common/logos/TUTN.png');
export const _TZTR = require('./img/common/logos/TZTR.png');
export const _U0UZ = require('./img/common/logos/U0UZ.png');
export const _U5GB = require('./img/common/logos/U5GB.png');
export const _U6GB = require('./img/common/logos/U6GB.png');
export const _U7UZ = require('./img/common/logos/U7UZ.png');
export const _UDTG = require('./img/common/logos/UDTG.png');
export const _UFPK = require('./img/common/logos/UFPK.png');
export const _UJJO = require('./img/common/logos/UJJO.png');
export const _UKUZ = require('./img/common/logos/UKUZ.png');
export const _UMCN = require('./img/common/logos/UMCN.png');
export const _UTUG = require('./img/common/logos/UTUG.png');
export const _UUUZ = require('./img/common/logos/UUUZ.png');
export const _V8KW = require('./img/common/logos/V8KW.png');
export const _V9GH = require('./img/common/logos/V9GH.png');
export const _VDCD = require('./img/common/logos/VDCD.png');
export const _VDMZ = require('./img/common/logos/VDMZ.png');
export const _VDTZ = require('./img/common/logos/VDTZ.png');
export const _VDZA = require('./img/common/logos/VDZA.png');
export const _VFAL = require('./img/common/logos/VFAL.png');
export const _VFAU = require('./img/common/logos/VFAU.png');
export const _VFEG = require('./img/common/logos/VFEG.png');
export const _VFES = require('./img/common/logos/VFES.png');
export const _VFGH = require('./img/common/logos/VFGH.png');
export const _VFGR = require('./img/common/logos/VFGR.png');
export const _VFIN = require('./img/common/logos/VFIN.png');
export const _VFRO = require('./img/common/logos/VFRO.png');
export const _VFTR = require('./img/common/logos/VFTR.png');
export const _VHVN = require('./img/common/logos/VHVN.png');
export const _VIBH = require('./img/common/logos/VIBH.png');
export const _VIKW = require('./img/common/logos/VIKW.png');
export const _VLVN = require('./img/common/logos/VLVN.png');
export const _VNVN = require('./img/common/logos/VNVN.png');
export const _VRCL = require('./img/common/logos/VRCL.png');
export const _VRSA = require('./img/common/logos/VRSA.png');
export const _W2FR = require('./img/common/logos/W2FR.png');
export const _WAUG = require('./img/common/logos/WAUG.png');
export const _WCGB = require('./img/common/logos/WCGB.png');
export const _WIGR = require('./img/common/logos/WIGR.png');
export const _WTKW = require('./img/common/logos/WTKW.png');
export const _WTPS = require('./img/common/logos/WTPS.png');
export const _XMML = require('./img/common/logos/XMML.png');
export const _XPSN = require('./img/common/logos/XPSN.png');
export const _XUAE = require('./img/common/logos/XUAE.png');
export const _XUSA = require('./img/common/logos/XUSA.png');
export const _XUUS = require('./img/common/logos/XUUS.png');
export const _XZVE = require('./img/common/logos/XZVE.png');
export const _YAAU = require('./img/common/logos/YAAU.png');
export const _YEGB = require('./img/common/logos/YEGB.png');
export const _YMYE = require('./img/common/logos/YMYE.png');
export const _YRNA = require('./img/common/logos/YRNA.png');
export const _YSES = require('./img/common/logos/YSES.png');
export const _YUES = require('./img/common/logos/YUES.png');
export const _YXNA = require('./img/common/logos/YXNA.png');
export const _Z0ZW = require('./img/common/logos/Z0ZW.png');
export const _Z6BF = require('./img/common/logos/Z6BF.png');
export const _Z7SL = require('./img/common/logos/Z7SL.png');
export const _Z9AE = require('./img/common/logos/Z9AE.png');
export const _Z9KW = require('./img/common/logos/Z9KW.png');
export const _Z9SA = require('./img/common/logos/Z9SA.png');
export const _Z9US = require('./img/common/logos/Z9US.png');
export const _ZABH = require('./img/common/logos/ZABH.png');
export const _ZAKW = require('./img/common/logos/ZAKW.png');
export const _ZANG = require('./img/common/logos/ZANG.png');
export const _ZCTG = require('./img/common/logos/ZCTG.png');
export const _ZEAZ = require('./img/common/logos/ZEAZ.png');
export const _ZFKR = require('./img/common/logos/ZFKR.png');
export const _ZIKR = require('./img/common/logos/ZIKR.png');
export const _ZJJO = require('./img/common/logos/ZJJO.png');
export const _ZKSA = require('./img/common/logos/ZKSA.png');
export const _ZNPK = require('./img/common/logos/ZNPK.png');
export const _ZQUS = require('./img/common/logos/ZQUS.png');
export const _ZSKR = require('./img/common/logos/ZSKR.png');
export const _ZXES = require('./img/common/logos/ZXES.png');
