import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'; // Import useSearchParams
import Btn from '../components/Btn';
import { showError } from '../components/Error';
import Footer from '../components/Footer';
import '../css/Otp.css';
import Webcam from 'react-webcam';
import { saveToken, saveUserDetails } from '../redux/user';
import useIdentityPayKYC from 'react-identity-kyc';
import ReactModal from 'react-modal';

const Otp = () => {
  const [otpValues, setOtpValues] = useState(['', '', '', '']);
  const inputRefs = useRef([]);
  const [timeLeft, setTimeLeft] = useState(120);
  const [timerActive, setTimerActive] = useState(true);
  const [dashboardData, setDashboardData] = useState({});
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation(); // Import useLocation
  const state = location.state;
  const [searchParams] = useSearchParams();
  const user_ref = state.username + 'teepay';
  const email = state.email;
  const [notifications, setNotifications] = useState([]);
  const [latestNotification, setLatestNotification] = useState('');
  const [showModal, setShowModal] = useState(false);
  const userName = state.username;
   // Use useSearchParams to get URL query parameters
   // Use useSearchParams to get URL query parameters


  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedTime = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    return formattedTime;
  };


  const initializeWebSocket = () => {
  
    const ws = new WebSocket('wss://dev.api.teepay.site');
  
        ws.onopen = () => {
            console.log('WebSocket connection opened');
            ws.send(JSON.stringify({ type: 'init' , token: token }));
        };

        ws.onerror = (error) => {
          console.error('WebSocket error:', error);
        };


     // Handle incoming WebSocket messages
        ws.onmessage = (message)=>{
            console.log('Received Websocket Message', JSON.parse(message.data));
            let data = JSON.parse(message.data);

    };
  
    ws.onclose = (e) => {
      console.log('WebSocket connection closed:', e.code, e.reason);
    };
  };


  useEffect(() => {
    initializeWebSocket();
  }, []);

  useEffect(() => {
    if (timerActive && timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }

    if (timeLeft === 0) {
      setTimerActive(false);
      // Implement the action you want when the countdown is done
    }
  }, [timerActive, timeLeft]);

  const handleOTPInputChange = (text, index) => {
    const updatedValues = [...otpValues];
    updatedValues[index] = text;
    setOtpValues(updatedValues);

    if (text !== '' && index < 3) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleOTPInputKeyPress = (event, index) => {
    if (event.nativeEvent.key === 'Backspace' && otpValues[index] === '') {
      event.preventDefault();

      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state?.user?.token);

  const fetchDashboardData = async () => {
    try {
      const res = await axios.get('https://dev.api.teepay.site/user/dashboard', {
        headers: {
          Authorization: `Bearer ${state.token[0]?.token}`
        }
      });

      console.log(res.data, 'dashboard data');
      setDashboardData(res.data?.body);
    } catch (error) {
      { showError(error?.response?.data) }
      console.log(error.response.data.message)
    }
    if (token) {
        fetchDashboardData();
    }
  };


  // Inside the Otp component



  const verifyOtp = async () => {
    setLoading(true);

    const body = {
      "otp": otpValues.join(''),
    };

    const headers = {
      Authorization: `Bearer ${state.token[0]?.token}`
  }
  
  
    try {
      const res = await axios.post('https://dev.api.teepay.site/auth/verify-otp', body, { headers });
      console.log(res);
      setLoading(false);
      dispatch(saveUserDetails(state));
      dispatch(saveToken(state.token[0]?.token));
      history('/idverification', { userName: userName });
    } catch (error) {
      console.log('Full error response:', error.response);
      setLoading(false);
      showError(error.response.data?.message);
    }
  };

  const resendOtp = async () => {
    try {
      const res = await axios.post('https://dev.api.teepay.site/auth/resend-otp', null, {
        headers: {
          Authorization: `Bearer ${state.token[0]?.token}`,
        },
      });
      console.log(res);
    } catch (error) {
      console.error('Error resending OTP:', error);
      showError(error.response.data?.message);
    }
    setTimerActive(true);
    setTimeLeft(120);
  };
  console.log(latestNotification);

  

  return (
    <div>
      <Container>
        <div className="body">
          <p className="header">OTP VERIFICATION</p>
          <div className="container">
            <p className="text">Enter the OTP sent to </p>
            <p className="email">{state.email || 'example@gmail.com'}</p>
          </div>
          <p className="text">Don't forget to check your spam box</p>
          <div className="container">
            {otpValues.map((value, index) => (
              <input
                key={index}
                className="otp-input"
                value={value}
                onChange={(e) => handleOTPInputChange(e.target.value, index)}
                type="text"
                maxLength={1}
                autoFocus={index === 0}
                ref={(ref) => (inputRefs.current[index] = ref)}
                onKeyDown={(e) => handleOTPInputKeyPress(e, index)}
              />
            ))}
          </div>
          <p className="timer">{formatTime(timeLeft)}</p>
          <div className="container">
            <p className="text">Didn’t receive code? </p>
            <button
              onClick={() => {
                if (!timerActive) {
                  resendOtp();
                }
              }}
              disabled={timerActive}
              className="resend-btn"
            >
              Re-send
            </button>
          </div>
          <Btn
  text='Submit'
  onClick={async () => {   // Execute verifyWithIdentity
    verifyOtp();

      // If verifyWithIdentity succeeds, proceed to verifyOtp // Pass any necessary values to verifyO
  }}
  loading={loading}
/>

        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default Otp;




