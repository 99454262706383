import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { logo } from '../assets/exports';
import { Row } from 'react-bootstrap';

import './Header.css';

export const Header = () => {
  const history = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState({});

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  

  return (
    <div className='fixed-top'>
      <div className='navbar-container'>
        <div className="navbar">
          <div className="menu-icon" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} size="lg" color="#fff" />
            <img src={logo} alt='logo' className='logo5' />
          </div>
          <div className='name'><span>TEEPAY</span></div>
          <div className="notification">
          <FontAwesomeIcon icon={faBell} size='lg' color="#fff" onClick={() => history('/NotificationsScreen')}  />
          <FontAwesomeIcon icon={faUser} className='user' color="#fff" onClick={() => history('/Profile')}/>

          </div>
           
          
          {menuOpen && (
            <div className="nav-links">
              <Link to="/Home" onClick={toggleMenu}>Home</Link>
              <Link to="/Profile" onClick={toggleMenu}>Profile</Link>
              <Link to="/Contact" onClick={toggleMenu}>Contact Us</Link>
              <Link to="/Refferal" onClick={toggleMenu}>Refferal</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

