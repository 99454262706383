import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
    avatar,
    contact, dark_mode, faceId, help, logout, notification, password
} from '../../assets/exports';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
} from 'react-bootstrap';
import Footer from '../../components/Footer';
import {  Header } from '../../components/exports';
import { styles as sx, width } from '../../helpers/styles';
import { toggleDarkMode } from '../../redux/common';
import { deleteUserDetails } from '../../redux/user';
import { useNavigate, Link } from 'react-router-dom';
import { showError } from '../../components/Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser, faPhone } from '@fortawesome/free-solid-svg-icons';
import '../../css/Profile.css';

export default function Profile({ navigation }) {
    const [activeTab, setActiveTab] = useState(0);
    const history = useNavigate();
  const [dashboardData, setDashboardData] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [latestNotification, setLatestNotification] = useState('');
  const [refreshInterval, setRefreshInterval] = useState(null);
  const [loading, setLoading] = useState(true);
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const token = useSelector((state) => state?.user?.token);
    
    console.log(userDetails?.body?.user, 'userDetails');

    const data = [
        {
            t1: 'NAME',
            t2: userDetails?.body?.user?.full_name,
            icon:  <FontAwesomeIcon icon={faUser} color="#4C9049"  />
        },
        {
            t1: 'EMAIL',
            t2: userDetails?.body?.user?.email,
            icon: <FontAwesomeIcon icon={faEnvelope} color="#4C9049" />
        },
        {
            t1: 'USERNAME',
            t2: userDetails?.body?.user?.username,
            icon: <FontAwesomeIcon icon={faUser} color="#4C9049"  />
        },
        {
            t1: 'PHONE',
            t2: userDetails?.body?.user?.phone,
            icon: <FontAwesomeIcon icon={faPhone} color="#4C9049"  />
        },
    ];

    const preferences = [

        {
            icon: password,
            text: 'Password reset',
        },
        {
            icon: notification,
            text: 'Notification',
        },
        {
            icon: help,
            text: 'Help and Feedback',
        },
        {
            icon: contact,
            text: 'Contact Us',
        },
        {
            icon: logout,
            text: 'Logout',
        },
    ]

    const dispatch = useDispatch();
    const darkMode = useSelector((state) => state?.common?.darkMode);
    

    const [isEnabled, setIsEnabled] = useState(darkMode);
    const toggleSwitch = () => {
        setIsEnabled(previousState => !previousState);
        dispatch(toggleDarkMode(!isEnabled));
    };

    useEffect(() => {
        if (token) {
    
          const getDashboardData = async () => {
            try {
              const res = await axios.get(
                'https://dev.api.teepay.site/user/dashboard',
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
    
              console.log(res.data, 'dashboard data');
              setDashboardData(res.data?.body);
            } catch (error) {
              showError(error?.response?.data);
              console.log(error.response.data.message);
            }
          };
    
          if (token) {
            getDashboardData();
          }
        }
      }, [token]);

      useEffect(() => {
        // Start of Tawk.to Script
        var Tawk_API = Tawk_API || {},
          Tawk_LoadStart = new Date();
        (function () {
          var s1 = document.createElement('script'),
            s0 = document.getElementsByTagName('script')[0];
          s1.async = true;
          s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
          s1.charset = 'UTF-8';
          s1.setAttribute('crossorigin', '*');
          s0.parentNode.insertBefore(s1, s0);
        })();
        // End of Tawk.to Script
    
        // Clean up the script when the component unmounts
        return () => {
          // Add any cleanup code here, if necessary
        };
      }, []); // Empty depe


      const [avatarUri, setAvatarUri] = useState(null);

  useEffect(() => {
    const fetchAvatar = () => {
      const uri = localStorage.getItem('Avatar');
      setAvatarUri(uri);
    };
    fetchAvatar();
  }, []);



    


    return (
        <div>
            <Header />
        
        <Container>
            <Col>
                
            </Col>
                    <h1>Profile</h1>

                        <div>
                        <img
        src={avatarUri || avatar}
        alt="Profile Pic"
        className='profile_pic'
      />
                            <h3 className='username'>{userDetails?.body?.user?.username}</h3>
                            <div className='edit_profile_btn' onClick={() => history('/EditProfile')}>
                                <p className='edit_profile'>Edit profile</p>
                            </div>
                        </div>

                    <h2 className='refferal'>Referral Program</h2>
                    <p className='refferal_code' onClick={() => history('/Refferal')}>
                        Get Refferal Code
                    </p>

                    <div className='tabs'>
                        {
                            ['Account info', 'Preference'].map((_, i) => {
                                return (
                                    <div
                                        className={activeTab === i ? 'tab_active' : 'tab'}
                                        onClick={() => setActiveTab(i)}
                                    >
                                        <p className={activeTab === i ? 'tab_text_active' : 'tab_text_inactive'}>
                                            {_}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {
                        activeTab === 0 ?
                            <div>
                                {
                                    data.map((_, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className='rows'
                                                onClick={() => {
                                                    i === 0 && history('/EditProfile')
                                                    // i === 1 && history('refferal')
                                                }}
                                            >
                                                <div className='icon'>
                                                    {_.icon}
                                                </div>

                                                <div>
                                                    <p className='t1' >{_.t1}</p>
                                                    <p className='t2'>{_.t2}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                {/*
                                <View className={styles.rows}>
                                    <View className={{ width: 0.1 * width, ...sx.alignStart }}>
                                        <FontAwesomeIcon name="lock" color="#4C9049" />
                                    </View>

                                    <View className={{ marginLeft: 0 }}>
                                        <Text className={[styles.t1, { color: darkMode ? 'white' : '#9D9D9D' }]}>PASSWORD</Text>
                                        <Text className={[styles.t2, { color: darkMode ? 'white' : '#9D9D9D' }]}>******************</Text>
                                    </View>
                                </View>
                                 */}
                            </div>
                            :
                            <div>
                                {
                                    preferences.map((_, i) => {
                                        return (
                                            <div className='preference_row' key={i}>
                                                <div
                                                    onClick={() => {
                                                        i === 0 && history('/ResetPassword');
                                                        i === 1 && history('/NotificationsScreen');
                                                        i === 2 && history('/Contact');
                                                        i === 3 && Link.openURL('https://teepay.site#contact')
                                                        .catch((err) => console.error('Error:', err));
                                                        i === 4 && dispatch(deleteUserDetails()) && history('/')
                                                    }}
                                                >
                                                    <div className=''>
                                                        <img className='preference_img' src={_.icon} alt='icon'/>
                                                        <span className='preference_text'>{_.text}</span>
                                                    </div>
                                                </div>



                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }

                
                      
        </Container>
        <div className='bottom'>
                        <Footer />
                      </div>
        </div>
    )
}