// Contact.js
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Container, Col } from 'react-bootstrap';
import { Header, Textfield } from '../../components/exports';
import ContactForm from '../../components/ContactForm';
import Footer from '../../components/Footer';
import '../../css/Contact.css'; // Import your CSS file for styling

const Contact = () => {
  const darkMode = useSelector((state) => state?.common?.darkMode);
  const history = useNavigate();

  useEffect(() => {
    // Start of Tawk.to Script
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    // End of Tawk.to Script

    // Clean up the script when the component unmounts
    return () => {
      // Add any cleanup code here, if necessary
    };
  }, []); // Empty depe

  return (
    <div>
      <Header />

    <Container>
      <Col>
        
      </Col>

      <div className="header">
        <h1>Help and Feedback</h1>
      </div>

      <p className="lorem">
        Enter your message in the field below. We will respond to your message within the next 24 hours.
      </p>

     <ContactForm />

   
    </Container>
       <Footer />
    </div>
  );
};

export default Contact;

