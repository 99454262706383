// Textfield.js
import React from 'react';
import { useField } from 'formik';
import TextField from '@material-ui/core/TextField';

const Textfield = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  const inputStyle = {
    color: 'white',
    borderColor: 'white',
  };

  return (
    <div style={{ marginBottom: '15px', color: 'white' }}>
      <TextField
        {...field}
        {...props}
        label={label}
        error={meta.touched && meta.error ? true : false}
        helperText={meta.touched && meta.error ? meta.error : ''}
        variant="outlined"
        fullWidth
        InputProps={{
          style: inputStyle,
        }}
      />
    </div>
  );
};

export default Textfield;
