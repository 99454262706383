import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    avatar,
    benin,
    electricity,
    ibedc,
    ikedc,
    info,
    logo,
    transport,
    water
} from '../../assets/exports';
import { AntDesignIcons } from '../../components/Icons';
import { styles as sx, width } from '../../helpers/styles';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { showError, showSuccessThenAction } from '../../components/Error';
import '../../css/Referral.css';
import { Header } from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';

const bet = [
    {
        bet: 'Ikeja Electricity',
        img: ikedc,
    },
    {
        bet: 'Ibadan Electricity',
        img: ibedc,
    },
    {
        bet: 'Benin Electricity',
        img: benin,
    }
];

const bills_arr = [
    {
        img: water,
        name: 'Water',
    },
    {
        img: electricity,
        name: 'Electricity',
    },
    {
        img: transport,
        name: 'Transportation',
    }
]

const drops = ['Prepaid', 'Postpaid'];

export default function Refferal({ navigation }) {
    const [active, setActive] = useState(0);
    const [show, setShow] = useState(false);
    const [toggleCheckBox, setToggleCheckBox] = useState(false);
    const [activeBill, setActiveBill] = useState(1);
    const userDetails = useSelector((state) => state?.user?.userDetails);
    const darkMode = useSelector((state) => state?.common?.darkMode);
    const token = useSelector((state) => state?.user?.token); // Get the authorization token
     const history = useNavigate();

     const handleAction = () => {
        history('/Refferal'); // Adjust the route as needed
      };

    const [drop, setDrop] = useState(drops[0]);
    const [dropActive, setDropActive] = useState(false);

    const [referralPrice, setReferralPrice] = useState(null);


    useEffect(() => {
        const fetchReferralPrice = async () => {
          try {
            const response = await axios.get('https://dev.api.teepay.site/utils/referral-price', {
                headers: {
                  Authorization: `Bearer ${token}`,
                 },
              }
              );
            if (response.status === 200) {
              setReferralPrice(response.data.body.price);
            }
          } catch (error) {
            console.error('Error fetching referral price:', error);
          }
        };
    
        fetchReferralPrice();
      }, []);

      useEffect(() => {
        // Start of Tawk.to Script
        var Tawk_API = Tawk_API || {},
          Tawk_LoadStart = new Date();
        (function () {
          var s1 = document.createElement('script'),
            s0 = document.getElementsByTagName('script')[0];
          s1.async = true;
          s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
          s1.charset = 'UTF-8';
          s1.setAttribute('crossorigin', '*');
          s0.parentNode.insertBefore(s1, s0);
        })();
        // End of Tawk.to Script
    
        // Clean up the script when the component unmounts
        return () => {
          // Add any cleanup code here, if necessary
        };
      }, []); // Empty depe

      const [withdrawalButtonEnabled, setWithdrawalButtonEnabled] = useState(false);

      useEffect(() => {
        // Update the button pressability based on the referral count
        if (userDetails?.body?.user?.referral_count === 2) {
            setWithdrawalButtonEnabled(true);
        } else {
            setWithdrawalButtonEnabled(false);
        }
    }, [userDetails?.body?.user?.referral_count]);
    
    // Function to handle the withdrawal button press
    const handleWithdrawalRequest = async () => {
        try {
            // Send POST request to the withdrawal API with only headers
            const response = await axios.post(
                'https://dev.api.teepay.site/utils/request-withdrawal',
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
    
            // Check if the withdrawal request was successful
            if (response.status === 200) {
                // Display a success message to the user
                showSuccessThenAction('Withdrawal request sent successfully!', handleAction);
                // Disable the button after a successful request
                setWithdrawalButtonEnabled(false);
            } else {
                // Handle other response statuses or errors if needed
                showError('Failed to send withdrawal request. Please try again.');
            }
        } catch (error) {
            console.error('Error sending withdrawal request:', error);
            // Handle errors, display an error message, etc.
        }
    };


    const styles = {
        withdrawalButton: {
            backgroundColor: '#269C48',
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 5,
            margin: 20,
          // Your base styles for the withdrawal button
        },
        disabledButton: {
            backgroundColor: '#CCCCCC',
          // Your styles for the disabled state
          opacity: 0.5, // for example
          cursor: 'not-allowed', // for example
        },
        withdrawalButtonText: {
            padding: 20,
            color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
          // Your styles for the text
        },
      };



    return (
        <div>
            <Header />
        <Container>
            <Col>
              
            </Col>

                <div className='header'>
                    <h1>Refferal Program</h1>
                </div>

                <div className='refferal_container'>
                    <div>
                    <p className='refferal_amount'>
                        {referralPrice !== null
                            ? `${(referralPrice * userDetails?.body?.user?.referral_count).toLocaleString('en-NG', {
                                style: 'currency',
                                currency: 'NGN',
                            })}`
                            : 'Loading...'}
                    </p>
                    </div> <br></br>
                    <div>
                      <p className='redeems'>{userDetails?.body?.user?.referral_count} Referrals</p>
                    </div>

                
                    
                   
                </div>

                <div>
  <Col>
    <div
      style={{
        ...styles.withdrawalButton,
        ...(withdrawalButtonEnabled ? {} : styles.disabledButton),
      }}
      onClick={withdrawalButtonEnabled ? handleWithdrawalRequest : undefined}
    >
      <p style={styles.withdrawalButtonText}>Request Withdrawal</p>
    </div>
  </Col>
</div>

                
            
                


                <div className='info_wrapper'>
                    <img src={info} className='info' />
                    <p className='information'>
                        Our Referral Program is Here
                       
                    </p>
                </div>

                <div
                    className=''>
                    <p className='refferal'>Your referral code</p>
                    <p className='refferal_code' onClick={() => navigation.navigate('refferal')}>
                         {userDetails?.body?.user?.referral_code}
                    </p>
                </div> 

                <div
                    className=''>
                     <p className='refferalby'>You were referred by: {userDetails?.body?.user?.referred_by}</p>
                  </div>

                <div>
                    <p className='works'>What To Expect?</p>

                    <div className='info_wrapper'>
                        <img src={info} className='info'/>
                        <p className='information'>
                            You stand a Chance to win a whooping sum of NGN{' '}
                            {referralPrice !== null
                               ? `${(referralPrice).toLocaleString('en-NG', {
                                     style: 'currency',
                                     currency: 'NGN',
                                  })}`
                               : 'loading...'} per Referral. Our Referral Program is always open so Keep referring and earn with Us.
                        </p>
                    </div>

                    <div className='info_wrapper'>
                        <img src={info} className='info' />
                        <p className='information'>
                            Keep inviting your friends
                        </p>
                    </div>

                    <div className='tc'>
    <p className='tc1'>Read our </p>
    <a href='https://teepay.site/teepay-refferal-terms-and-conditions' target='_blank' rel='noopener noreferrer' className='tc2'>
        Referral Terms and Conditions
    </a>
</div>
                </div>
            </Container>
            <Footer />
            </div>
    )
}