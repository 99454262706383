import React, { useState } from 'react';
import './Btn.css';

const Btn = ({ text, style, onClick, wth, loading }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleButtonClick = () => {
    setIsDisabled(true);
    onClick(); // Assuming onClick performs the necessary action

    // Enable the button after 3 seconds
    setTimeout(() => {
      setIsDisabled(false);
    }, 3000);
  };

  return (
    <button className='btn' type='submit' onClick={handleButtonClick} disabled={isDisabled}>
      {loading ? <div className='loadingText'>Loading...</div> : <span className='loadingText'>{text}</span>}
    </button>
  );
};

export default Btn;
