// LogoComponent.js
import React, { useEffect, useState } from 'react';
import { logo } from '../assets/exports';
import './LoadingSpinner.css';
import ReactModal from 'react-modal';

const LoadingSpinner = ({ loading }) => {

  

  return (
    <ReactModal
      isOpen={loading}
      ariaHideApp={false} // Disables aria-hidden attribute
      shouldCloseOnOverlayClick={false} // Prevents closing modal on overlay click
      style={{
        overlay: {
         position: 'fixed',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparent background
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          backgroundColor: 'transparent', // Transparent content background
          border: 'none',
          borderRadius: 'none',
          padding: 0,
        },
      }}
    >
      <div className={`green-circle ${loading ? 'zoom-animation' : ''}`}>
        <img className={`rolling-logo ${loading ? 'loading-logo-animation' : ''}`} src={logo} alt="Logo" />
      </div>
    </ReactModal>
    
  );
};

export default LoadingSpinner;