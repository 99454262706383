export const { width, height } = window.innerWidth;

export const styles = {
  rows: {
    display: 'flex',
    flexDirection: 'row',
  },
  cols: {
    display: 'flex',
    flexDirection: 'column',
  },
  alignCenter: {
    alignItems: 'center',
  },
  alignStart: {
    alignItems: 'flex-start',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  justifyEvenly: {
    justifyContent: 'space-evenly',
  },
  flexWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  w8: {
    width: '80%',
  },
  w9: {
    width: '90%',
  },
  maxWidth9: {
    maxWidth: '90%',
  },
};
