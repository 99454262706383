import axios from 'axios';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  Form,
  Row
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Dontrunout
} from '../../assets/exports';
import Banner from '../../components/Banner';
import Btn from '../../components/Btn';
import { showError, showSuccessThenAction } from '../../components/Error';
import Footer from '../../components/Footer';
import { Header } from '../../components/Header';
import Network from '../../components/Sorts';
import { Textfield } from '../../components/Textfield';
import '../../css/Airtime.css'; // Import your CSS file for styles

const Airtime = () => {
  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [network, setNetwork] = useState('Airtel');
  const [amount, setAmount] = useState('');
  const [coupon, setCoupon] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const history = useNavigate();
  const darkMode = useSelector((state) => state?.common?.darkMode);
  const token = useSelector((state) => state?.user?.token);

  const handleAction = () => {
    history('/Home'); // Adjust the route as needed
  };

  const buyAirtime = async (phoneNo) => {
    setLoading(true);

    const req_body = {
      payment_method: 'wallet',
      service: 'airtime',
      provider: network.toUpperCase(),
      receiver: phoneNo,
      amount: parseFloat(amount),
    };

    if (coupon) {
      req_body.coupon = coupon;
    }

    try {
      const { data } = await axios.post(
        'https://dev.api.teepay.site/service/purchase',
        req_body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data) {
        showSuccessThenAction(
          'Airtime has been bought successfully',
          handleAction
        );
        setLoading(false);
      }
    } catch (error) {
      console.error(error?.response?.data);
      showError(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const initialValues = { phoneNumber: '', amount: '', coupon: '' };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowAlert(false);
      setAlertMessage('');
    }, 5000);

    return () => clearTimeout(timeout);
  }, [showAlert]);


  useEffect(() => {
    // Start of Tawk.to Script
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    // End of Tawk.to Script

    // Clean up the script when the component unmounts
    return () => {
      // Add any cleanup code here, if necessary
    };
  }, []); // Empty depe

  return (
    <div>
       <Header />
    <Container>
      <Row>
        <Col>
         
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8} className="mx-auto"> {/* Add mx-auto class for centering */}
          <h1>Buy Airtime</h1>
        </Col>
      </Row>
      <div>
      <Banner imageUrl={Dontrunout} altText="topup" />
      </div>

      
      <Row>
        <Col xs={12} md={8} className="mx-auto"> {/* Add mx-auto class for centering */}
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              console.log(values, 'Values');
              buyAirtime(values.phoneNumber);
            }}
          >
            {({ handleSubmit }) => (
              <>
                <Textfield name="phoneNumber" title="Phone No" type="number" />

                <div className="mb-3">
                  <p style={{ color: 'red' }}>
                    Please ensure to select the right Network to avoid loss of
                    funds
                  </p>
                </div>

                <div className="mb-3">
                  <Network handler={setNetwork} style={{ color: 'white' }} />
                </div>

                <Row className="mb-3">
                  {Array.from({ length: 9 }, (_, i) => (
                    <Col key={i} xs={4} md={3}>
                      <div
                        variant="primary"
                        className="prices"
                        onClick={() => setAmount((50 * 2 ** i).toString())}
                      >
                        {(50 * 2 ** i).toLocaleString('en-NG', {
                          style: 'currency',
                          currency: 'NGN',
                        })}
                      </div>
                    </Col>
                  ))}
                </Row>

                <Textfield
                  name="amount"
                  value={amount}
                  handleChange={(value) => setAmount(value)}
                  title="Amount"
                  type="number"
                />

                <Textfield
                  name="coupon"
                  value={coupon}
                  handleChange={(value) => setCoupon(value)}
                  title="Coupon"
                />

                <Btn
                  variant="primary"
                  text={loading ? 'Processing...' : 'Proceed'}
                  type="submit"
                  style={{ marginTop: '20px' }}
                  className="btn"
                  onClick={() => {
                    console.log('Login button clicked');
                    handleSubmit();
                  }}
                  disabled={loading}
                />
                </>
            )}
          </Formik>
        </Col>
      </Row>
      <Row>
        <Col>
          
        </Col>
      </Row>
    </Container>
    <Footer />
    </div>
  );
};

export default Airtime;

