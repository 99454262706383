import axios from 'axios';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Col,
  Container
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import CSS file
import {
  Onexbet,
  nairabet,
  surebet,
  supabet,
  livescorebet,
  cloudbet,
  betland,
  betlion,
  bangbet, bet9ja,
  betking,
  betway, easywin,
  frapapa,
  paripesa,
  mylottohub,
  merrybet,
  naijabet,
  sportybet
} from '../../assets/exports';
import Btn from '../../components/Btn';
import { showError, showSuccessThenAction } from '../../components/Error';
import Footer from '../../components/Footer';
import { Header } from '../../components/Header';
import { Textfield } from '../../components/exports';

const bet = [
  {
      bet: 'NairaBet',
      img: nairabet,
  },
  {
      bet: 'BetKing',
      img: betking,
  },
  {
      bet: 'OnexBet',
      img: Onexbet,
  },
  {
      bet: 'Bet9ja',
      img: bet9ja,
  },
  {
      bet: 'Bangbet',
      img: bangbet,
  },
  {
      bet: 'BetWay',
      img: betway,
  },
  {
      bet: 'Paripesa',
      img: paripesa,
  },
  {
      bet: 'Sportybet',
      img: sportybet,
  },
 
  {
      bet: 'NaijaBet',
      img: naijabet,
  },
  {
    bet: 'Supabet',
    img: supabet,
  },
  {
      bet: 'MerryBet',
      img: merrybet,
  },
  {
    bet: 'MyLottoHub',
    img: mylottohub,
  },
]

const Bet = ({ navigation }) => {
  const [active, setActive] = useState(0);
  const [show, setShow] = useState(false);
  const token = useSelector((state) => state?.user?.token);
  const darkMode = useSelector((state) => state?.common?.darkMode);
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [loaded, setLoaded] = useState(false);

  const handleFormSubmit = async (values) => {
    if (token) {
      setLoading(true);
      const url = `https://dev.api.teepay.site/service/purchase`;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const reqBody = {
        "payment_method": "wallet",
        "service": "betting",
        "provider": bet[active].bet,
        "receiver": values?.userId,
        "amount": values?.amount,
      };

      if (values?.coupon) {
        reqBody.coupon = values?.coupon;
      }

      const handleAction = () => {
        history('/Home'); // Adjust the route as needed
      };

      try {
        const res = await axios.post(url, reqBody, { headers });
        if (res) {
          setLoading(false);
          console.log(res, 'res');
          showSuccessThenAction(' Wallet has Been Funded successfully', handleAction);
        }
      } catch (error) {
        setLoading(false);
        console.log(error?.response?.data?.message, 'error');
        showError(error?.response?.data?.message);
      }
    } else {
      showError('Unauthorized request');
    }
  };

  useEffect(() => {
    // Start of Tawk.to Script
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    // End of Tawk.to Script

    // Clean up the script when the component unmounts
    return () => {
      // Add any cleanup code here, if necessary
    };
  }, []); // Empty depe

  return (
    <div>
      <Header />
    <Container>
      <Col>
        
      </Col>
     

      <div>
        <h1>Betting Wallets Funding</h1>
      </div>

      <div className='warning'>
        <p>Please Note: A Fee of 50NGN will be charged per wallet funding.</p>
      </div>

      <div className='select' onClick={() => setShow(!show)}>
        <img src={bet[active].img} className='select_img' alt="Selected Bet" />
        <p className='select_text'>{bet[active].bet}</p>
      </div>

      {show && (
        <div className='dropdown_wrapper'>
          {bet.map((_, i) => (
            <div
              className='dropdown_item'
              key={i}
              onClick={() => {
                setShow(!show);
                setActive(i);
              }}
            >
              <img src={_.img} className='select_img' alt={`Bet ${_.bet}`} />
              <p className='select_text'>
                {_.bet}
              </p>
            </div>
          ))}
        </div>
      )}

      <Formik initialValues={{ userId: '', amount: '', coupon: '' }} onSubmit={handleFormSubmit}>
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <div style={{ marginTop: 30 }}>
            <div style={{ marginTop: 30 }}>
              <Textfield
                name="userId"
                title="User ID"
                style={{ marginTop: 0 }}
                keyboardType="number-pad"
                value={values.userId}
              />
              <p style={{ color: 'red', marginBottom: 20 }}>Minimum of 100</p>
              <Textfield
                name="amount"
                title="Amount"
                style={{ marginTop: 0 }}
                keyboardType="number-pad"
                value={values.amount}
              />
              <Textfield
                name="coupon"
                title="Coupon"
                style={{ marginTop: 0 }}
                value={values.coupon}
              />
            </div>

            <div>
            <Btn
                  variant="primary"
                  text={loading ? 'Funding...' : 'Fund Wallet'}
                  type="submit"
                  style={{ marginTop: '20px' }}
                  className="btn"
                  onClick={() => {
                    console.log('Login button clicked');
                    handleSubmit();
                  }}
                  disabled={loading}
                />
            </div>
          </div>
        )}
      </Formik>
    </Container>
    <Footer />
    </div>
  );
};

export default Bet;
