import axios from 'axios';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  Container,
  Row
} from 'react-bootstrap';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import Banner from '../../components/Banner';
import Btn from '../../components/Btn';
import { showError, showSuccessThenAction } from '../../components/Error';
import Footer from '../../components/Footer';
import { Header } from '../../components/Header';
import { Textfield } from '../../components/exports';
import '../../css/education.css';

import {
 
  neco,
  waec,
 
} from '../../assets/exports';
import { useNavigate } from 'react-router-dom';

const bet = [
  {
      bet: 'NECO',
      img: neco,
  },
  {
          bet: 'WAEC',
          img: waec,
      },
      {
        bet: 'UTME',
        img: neco,
    },
    {
      bet: 'UTME_MOCK',
      img: neco,
  },
  {
    bet: 'DE',
    img: neco,
},
]

export default function Education({ navigation }) {
  const [active, setActive] = useState(0);
  const [show, setShow] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const tabs = ['Hot Offers'];

  const darkMode = useSelector((state) => state?.common?.darkMode);
  const [tvPlans, setTvPlans] = useState([]);
  const [selectedTvPlan, setSelectedTvPlan] = useState(null);
  const token = useSelector((state) => state?.user?.token);
  const [loading, setLoading] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const history = useNavigate();

  const formikRef = useRef(null);

  const handleFormSubmit = async (values) => {
    console.log(values);
    buyPin(values);

  };

  useEffect(() => {
    const getProductPlans = async () => {
      try {
        const { data } = await axios.get(`https://dev.api.teepay.site/service/product-plans?service=education&provider=${bet[active].bet}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (data) {
          setTvPlans(data?.body?.plans);
        }
      } catch (error) {
        console.log(error, 'error getting data plans');
        showError(error?.response?.data?.message);
      }
    };

    if (token) {
      getProductPlans();
    }
  }, [active, token]);

  

  const buyPin = async (values) => {
    if (token) {
        setLoading(true);
        const url = `https://dev.api.teepay.site/service/purchase`;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const reqBody = {
            "payment_method": "wallet",
            "service": "education",
            "provider": bet[active].bet,
            "receiver": bet[active].bet.toUpperCase() !== 'NECO' && bet[active].bet.toUpperCase() !== 'WAEC' ? values.account || '1234' : '1234',
            "service_plan_ref": selectedTvPlan?.plan,
          };


        if (values?.coupon) {
            reqBody.coupon = values?.coupon;
        }

        if (values?.phoneNo) {
            reqBody.iso = values?.phoneNo;
        }

        const handleAction = () => {
            navigation.navigate('app', { screen: 'app', key: Date.now() })
        };

        try {
            const res = await axios.post(url, reqBody, { headers });
            if (res) {
                setLoading(false);
                console.log(res, 'res');
                { showSuccessThenAction('Education Pin has been bought successfully', handleAction) }
                
            }
        } catch (error) {
            console.log(error?.response?.data?.message, 'error');
            { showError(error?.response?.data?.message) }
            
        }
    } else {
        { showError('Unauthorized request') }
    }
};



  useEffect(() => {
    // Start of Tawk.to Script
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    // End of Tawk.to Script

    // Clean up the script when the component unmounts
    return () => {
      // Add any cleanup code here, if necessary
    };
  }, []); // Empty depe

  return (
    <div>
      <Header />
    <Container>


      <Col>
        
      </Col>

      <div>
        <h1>Purchase Result Checker PIN</h1>
      </div>


      <div className='select' onClick={() => setShow(!show)}>
        <img src={bet[active].img} className='select_img' alt="Tv" />
        <span className='select_text'>{bet[active].bet}</span>
      </div>

      {show && (
        <div className='dropdown_wrapper'>
          {bet.map((_, i) => (
            <div
              className='dropdown_item'
              key={i}
              onClick={() => {
                setShow(!show);
                setActive(i);
              }}
            >
              <img src={_.img} className='select_img' alt="electricity-logo" />
              <p className='select_text'>{_.bet}</p>
            </div>
          ))}
        </div>
      )}


      <Formik
        innerRef={formikRef}
        initialValues={{ coupon: '' }}
        onSubmit={(values) => { handleFormSubmit(values) }}
      >
        {
          ({ handleSubmit, setFieldValue, values }) => (
           <div>

              <div>
                {
                  tabs.map((_, i) => (
                    <div
                      key={i}
                      onClick={() => setActiveTab(i)}
                      className={activeTab === i ? 'tab tab_active' : 'tab'}
                    >
                      <span className={activeTab === i ? 'tab_p_inactive tab_p_active' : 'tab_p_inactive'}>
                        { _ }
                      </span>
                    </div>
                  ))
                }
              </div>
           <Row className='mt-3'>
           <div className='data_bundle_container text-center'>
  {tvPlans.length !== 0 &&
    <div className='tv_plans_wrapper'>
      {tvPlans.map((_, i) => {
        const tvPlanDescription = _.short_desc; // Split TV plan description
        return (
          <div
            key={i}
            onClick={() => {
              setSelectedTvPlan(_);
            }}
            className={`education_bundle_item ${selectedTvPlan === _ ? 'selected' : ''}`}
          >
            <div className='education_bundle_item_v1'>
              <p className={`data_price ${selectedTvPlan === _ ? 'selected' : ''}`}>
                {(_.amount).toLocaleString('en-NG', { style: 'currency', currency: 'NGN' })}
              </p>
            </div>
            <div className='education_bundle_item_v2'>
              <p className={`data_bundle ${selectedTvPlan === _ ? 'selected' : ''}`}>{tvPlanDescription}</p>
              
            </div>
          </div>
        );
      })}
    </div>
  }
</div>
           </Row>
           {bet[active].bet.toUpperCase() !== 'NECO' && bet[active].bet.toUpperCase() !== 'WAEC' && (
    <Textfield
        name='account'
        title='Account Number'
        style={{ marginTop: 0 }}
        keyboardType="number-pad"
    />
)}


{bet[active].bet.toUpperCase() !== 'NECO' && bet[active].bet.toUpperCase() !== 'WAEC' && (
    <Textfield
        name='phoneNo'
        title='Phone Number'
        style={{ marginTop: 0 }}
        keyboardType="number-pad"
    />
)}

              <Textfield
                name='coupon'
                title='Coupon'
                onChange={(e) => setFieldValue('coupon', e.target.value)}
                value={values.coupon}
              />

<Btn
        variant="primary"
        text={loading ? 'Paying...' : 'Pay'}
        type="submit"
        style={{ marginTop: '20px' }}
        className="btn"
        onClick={() => {
          console.log('Button clicked');
          handleSubmit();
        }}
        disabled={loading}
      />
            </div>
          )
        }
      </Formik>
      <Col>
        
      </Col>
    </Container>
    <Footer />
    </div>
  );
}