import axios from 'axios';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CountryFlag from 'react-country-flag';
import { useSelector } from 'react-redux';
import {
  _0JMM, _0RGM, _0RSL, _0UGM, _0USL, _0VGM, _0WMW, _1AIN, _1DIN, _1GMZ, _1KPH, _1PBJ, _1QBJ, _1SIN, _1UIN, _1VIN, _1ZTG, _2AAE, _2AGB, _2ASA, _2AUS, _2FKM, _2NSN, _2RKZ, _2SID, _2VUS, _2XID, _34BF, _35CI, _36GH, _39EG, _3AKZ, _3GAE, _3GSA, _3HOM, _3SAU, _3SSG, _4AKZ, _4FAZ, _4HGH, _4RUS, _5ANG, _5CMR, _5FMR, _5GMR, _5MMR, _7MMR, _8MMR, _8TZA, _9CUS, _9MNG, _9RUS, _9SUS, _AALB, _ACIQ, _AEID, _AFCD, _AFGM, _AFSL, _AFUG, _AICD, _AIGH, _AIIN, _AIKE, _AILK, _AIMW, _AITZ, _AIZM, _AJID, _AQAF, _ASES, _AVTR, _AWAF, _AXBD, _BEKG, _BEKZ, _BERU, _BETJ, _BEUZ, _BFAZ, _BGBD, _BHET, _BLIN, _BMTJ, _BOAU, _BTES, _BVBH, _C1CN, _C2CN, _CEZA, _CFGR, _CFRO, _CGKH, _CHGN, _CKCA, _CMGN, _CMLR, _CNMY, _CSES, _DGLK, _DIMY, _DJDZ, _DNNP, _DSES, _E6AE, _E7US, _ECBI, _ECZW, _ESAU, _ESES, _ESGB, _ESSA, _ETAE, _ETAF, _ETEG, _ETNG, _EXID, _FAAE, _FHUS, _FIUS, _FROM, _FRSA, _FUAE, _G0PH, _G6GM, _G7GN, _GAGM, _GBPH, _GEBD, _GLNG, _GMVN, _GPAE, _GPSA, _GPUS, _H5ES, _HAAE, _HEPL, _HMES, _HPES, _HSLK, _HTID, _I2US, _I3US,
  _L2IE, _L3US, _LCLR, _LIUA, _LRES, _LSES, _LTML, _LYAU, _LYES, _LYGB, _M4GW, _M6KG, _MARU, _MAUA, _MBPK, _MDDZ, _MFVN, _MGAO, _MKMZ, _MLSA, _MOMA, _MRRU, _MRTJ, _MSLK, _MTAF, _MTBJ, _MTCG, _MTCI, _MTCM, _MTCY, _MTGH, _MTGN, _MTGW, _MTNG, _MTRW, _MTSZ, _MTUG, _MTYE, _MTZA, _MTZM, _MVBJ, _MVCF, _MVCI, _MVNE, _MVTG, _N3NG, _N4NG, _N5GH, _N6GH, _N7CM, _N8CI, _NCNP, _NENP, _NQNP, _NYAE, _O5KM, _O6US, _O7GN,
  _OFGR,
  _OMOM, _ONBF, _OODZ, _OOMM, _OOOM, _OPBF, _OPCI, _OPGW, _OPML, _OPSL, _OPSN, _ORCI, _ORCM, _OREG, _ORES, _ORGN, _ORJO, _ORMA, _ORMD, _ORMG, _ORML, _ORNE, _ORPL, _ORRO, _ORSL, _ORSN, _ORTN, _OXKG, _P0UZ, _PFMM, _PGUS, _PNAE, _PNBH, _PNKW, _PNLB, _PNOM, _PNQA, _PNSA, _PNUS, _PSPL, _PWPH, _QLGM, _QLSL, _R0AU, _RAOM, _RHAF, _RJIN, _RLBD, _RPES, _RZUS, _S6SA, _S7US, _SAKE, _SCKH, _SFYE, _SMPH, _SSES, _STPH, _T4TJ, _T5TH, _T6TH, _T7TH, _THPL, _TIGH, _TILK,
  _TISN, _TITZ,
  _TKID,
  _TMLB,
  _TMPH,
  _TPMM,
  _TPPK,
  _TQBD,
  _TUTN,
  _TZTR,
  _U0UZ,
  _U5GB,
  _U6GB,
  _U7UZ,
  _UDTG,
  _UFPK,
  _UJJO,
  _UKUZ,
  _UMCN,
  _UTUG,
  _UUUZ,
  _V8KW,
  _V9GH,
  _VDCD,
  _VDMZ,
  _VDTZ,
  _VDZA,
  _VFAL,
  _VFAU,
  _VFEG,
  _VFES,
  _VFGH,
  _VFGR,
  _VFIN,
  _VFRO,
  _VFTR,
  _VHVN,
  _VIBH,
  _VIKW,
  _VLVN,
  _VNVN,
  _VRCL,
  _VRSA,
  _W2FR,
  _WAUG,
  _WCGB,
  _WIGR,
  _WTKW,
  _WTPS,
  _XMML,
  _XPSN,
  _XUAE,
  _XUSA,
  _XUUS,
  _XZVE,
  _YAAU,
  _YEGB,
  _YMYE,
  _YRNA,
  _YSES,
  _YUES,
  _YXNA,
  _Z0ZW,
  _Z6BF,
  _Z7SL,
  _Z9AE,
  _Z9KW,
  _Z9SA,
  _Z9US,
  _ZABH,
  _ZAKW,
  _ZANG,
  _ZCTG,
  _ZEAZ,
  _ZFKR,
  _ZIKR,
  _ZJJO,
  _ZKSA,
  _ZNPK,
  _ZQUS,
  _ZSKR,
  _ZXES,
  avatar, logo
} from '../../assets/exports';
import Btn from '../../components/Btn';
import Footer from '../../components/Footer';
import { showError, showSuccessThenAction } from '../../components/Error';
import { Header } from '../../components/Header';
import { AntDesignIcons } from '../../components/Icons';
import { Textfield } from '../../components/exports';
import '../../css/Foreignairtime.css';
import  Flags  from 'react-flags-select';





  const buybet = [
    {
      iso: "AE",
      title: "United Arab Emirates",
      prefix: "971"
    },
    {
      iso: "AF",
      title: "Afghanistan",
      prefix: "93"
    },
    {
      iso: "AG",
      title: "Antigua",
      prefix: "1268"
    },
    {
      iso: "AI",
      title: "Anguilla",
      prefix: "1264"
    },
    {
      iso: "AL",
      title: "Albania",
      prefix: "355"
    },
    {
      iso: "AM",
      title: "Armenia",
      prefix: "374"
    },
    {
      iso: "AO",
      title: "Angola",
      prefix: "244"
    },
    {
      iso: "AR",
      title: "Argentina",
      prefix: "54"
    },
    {
      iso: "AU",
      title: "Australia",
      prefix: "61"
    },
    {
      iso: "AW",
      title: "Aruba",
      prefix: "297"
    },
    {
      iso: "AZ",
      title: "Azerbaijan",
      prefix: "994"
    },
    {
      iso: "BB",
      title: "Barbados",
      prefix: "1246"
    },
    {
      iso: "BD",
      title: "Bangladesh",
      prefix: "880"
    },
    {
      iso: "BE",
      title: "Belgium",
      prefix: "32"
    },
    {
      iso: "BF",
      title: "Burkina Faso",
      prefix: "226"
    },
    {
      iso: "BH",
      title: "Bahrain",
      prefix: "973"
    },
    {
      iso: "BI",
      title: "Burundi",
      prefix: "257"
    },
    {
      iso: "BJ",
      title: "Benin",
      prefix: "229"
    },
    {
      iso: "BM",
      title: "Bermuda",
      prefix: "1441"
    },
    {
      iso: "BO",
      title: "Bolivia",
      prefix: "591"
    },
    {
      iso: "BQ",
      title: "Bonaire",
      prefix: "599"
    },
    {
      iso: "BR",
      title: "Brazil",
      prefix: "55"
    },
    {
      iso: "BS",
      title: "The Bahamas",
      prefix: "1242"
    },
    {
      iso: "BT",
      title: "Bhutan",
      prefix: "975"
    },
    {
      iso: "BW",
      title: "Botswana",
      prefix: "267"
    },
    {
      iso: "BZ",
      title: "Belize",
      prefix: "501"
    },
    {
      iso: "CA",
      title: "Canada",
      prefix: "1"
    },
    {
      iso: "CD",
      title: "Democratic Republic of  the Congo",
      prefix: "243"
    },
    {
      iso: "CF",
      title: "Central African Republic",
      prefix: "236"
    },
    {
      iso: "CG",
      title: "Republic of the Congo",
      prefix: "242"
    },
    {
      iso: "CH",
      title: "Switzerland",
      prefix: "41"
    },
    {
      iso: "CI",
      title: "Ivory Coast",
      prefix: "225"
    },
    {
      iso: "CL",
      title: "Chile",
      prefix: "56"
    },
    {
      iso: "CM",
      title: "Cameroon",
      prefix: "237"
    },
    {
      iso: "CN",
      title: "China",
      prefix: "86"
    },
    {
      iso: "CO",
      title: "Colombia",
      prefix: "57"
    },
    {
      iso: "CR",
      title: "Costa Rica",
      prefix: "506"
    },
    {
      iso: "CU",
      title: "Cuba",
      prefix: "53"
    },
    {
      iso: "CV",
      title: "Cape Verde",
      prefix: "238"
    },
    {
      iso: "CW",
      title: "Curacao",
      prefix: "599"
    },
    {
      iso: "CY",
      title: "Cyprus",
      prefix: "357"
    },
    {
      iso: "CZ",
      title: "Czech Republic",
      prefix: "420"
    },
    {
      iso: "DE",
      title: "Germany",
      prefix: "49"
    },
    {
      iso: "DM",
      title: "Dominica",
      prefix: "1767"
    },
    {
      iso: "DO",
      title: "Dominican Republic",
      prefix: "18"
    },
    {
      iso: "DZ",
      title: "Algeria",
      prefix: "213"
    },
    {
      iso: "EC",
      title: "Ecuador",
      prefix: "593"
    },
    {
      iso: "EG",
      title: "Egypt",
      prefix: "20"
    },
    {
      iso: "ES",
      title: "Spain",
      prefix: "34"
    },
    {
      iso: "ET",
      title: "Ethiopia",
      prefix: "251"
    },
    {
      iso: "FJ",
      title: "Fiji",
      prefix: "679"
    },
    {
      iso: "FR",
      title: "France",
      prefix: "33"
    },
    {
      iso: "GD",
      title: "Grenada",
      prefix: "1473"
    },
    {
      iso: "GE",
      title: "Georgia",
      prefix: "995"
    },
    {
      iso: "GF",
      title: "French Guiana",
      prefix: "594"
    },
    {
      iso: "GH",
      title: "Ghana",
      prefix: "233"
    },
    {
      iso: "GM",
      title: "Gambia",
      prefix: "220"
    },
    {
      iso: "GN",
      title: "Guinea",
      prefix: "224"
    },
    {
      iso: "GP",
      title: "Guadeloupe",
      prefix: "590"
    },
    {
      iso: "GR",
      title: "Greece",
      prefix: "30"
    },
    {
      iso: "GT",
      title: "Guatemala",
      prefix: "502"
    },
    {
      iso: "GW",
      title: "Guinea Bissau",
      prefix: "245"
    },
    {
      iso: "GY",
      title: "Guyana",
      prefix: "592"
    },
    {
      iso: "HN",
      title: "Honduras",
      prefix: "504"
    },
    {
      iso: "HT",
      title: "Haiti",
      prefix: "509"
    },
    {
      iso: "ID",
      title: "Indonesia",
      prefix: "62"
    },
    {
      iso: "IE",
      title: "Ireland",
      prefix: "353"
    },
    {
      iso: "IN",
      title: "India",
      prefix: "91"
    },
    {
      iso: "IQ",
      title: "Iraq",
      prefix: "964"
    },
    {
      iso: "IT",
      title: "Italy",
      prefix: "39"
    },
    {
      iso: "JM",
      title: "Jamaica",
      prefix: "1876"
    },
    {
      iso: "JO",
      title: "Jordan",
      prefix: "962"
    },
    {
      iso: "KE",
      title: "Kenya",
      prefix: "254"
    },
    {
      iso: "KG",
      title: "Kyrgyzstan",
      prefix: "996"
    },
    {
      iso: "KH",
      title: "Cambodia",
      prefix: "855"
    },
    {
      iso: "KM",
      title: "Comoros",
      prefix: "269"
    },
    {
      iso: "KN",
      title: "St. Kitts",
      prefix: "1869"
    },
    {
      iso: "KR",
      title: "South Korea",
      prefix: "82"
    },
    {
      iso: "KW",
      title: "Kuwait",
      prefix: "965"
    },
    {
      iso: "KY",
      title: "Cayman Islands",
      prefix: "1345"
    },
    {
      iso: "KZ",
      title: "Kazakhstan",
      prefix: "7"
    },
    {
      iso: "LA",
      title: "Laos",
      prefix: "856"
    },
    {
      iso: "LB",
      title: "Lebanon",
      prefix: "961"
    },
    {
      iso: "LC",
      title: "St. Lucia",
      prefix: "1758"
    },
    {
      iso: "LK",
      title: "Sri Lanka",
      prefix: "94"
    },
    {
      iso: "LR",
      title: "Liberia",
      prefix: "231"
    },
    {
      iso: "LT",
      title: "Lithuania",
      prefix: "370"
    },
    {
      iso: "LU",
      title: "Luxembourg",
      prefix: "352"
    },
    {
      iso: "MA",
      title: "Morocco",
      prefix: "212"
    },
    {
      iso: "MD",
      title: "Moldova",
      prefix: "373"
    },
    {
      iso: "MF",
      title: "St. Martin",
      prefix: "590"
    },
    {
      iso: "MG",
      title: "Madagascar",
      prefix: "261"
    },
    {
      iso: "ML",
      title: "Mali",
      prefix: "223"
    },
    {
      iso: "MM",
      title: "Myanmar",
      prefix: "95"
    },
    {
      iso: "MQ",
      title: "Martinique",
      prefix: "596"
    },
    {
      iso: "MR",
      title: "Mauritania",
      prefix: "222"
    },
    {
      iso: "MS",
      title: "Montserrat",
      prefix: "1664"
    },
    {
      iso: "MW",
      title: "Malawi",
      prefix: "265"
    },
    {
      iso: "MX",
      title: "Mexico",
      prefix: "52"
    },
    {
      iso: "MY",
      title: "Malaysia",
      prefix: "60"
    },
    {
      iso: "MZ",
      title: "Mozambique",
      prefix: "258"
    },
    {
      iso: "NA",
      title: "Namibia",
      prefix: "264"
    },
    {
      iso: "NE",
      title: "Niger",
      prefix: "227"
    },
    {
      iso: "NG",
      title: "Nigeria",
      prefix: "234"
    },
    {
      iso: "NI",
      title: "Nicaragua",
      prefix: "505"
    },
    {
      iso: "NL",
      title: "Netherlands",
      prefix: "31"
    },
    {
      iso: "NP",
      title: "Nepal",
      prefix: "977"
    },
    {
      iso: "OM",
      title: "Oman",
      prefix: "968"
    },
    {
      iso: "PA",
      title: "Panama",
      prefix: "507"
    },
    {
      iso: "PE",
      title: "Peru",
      prefix: "51"
    },
    {
      iso: "PG",
      title: "Papua New Guinea",
      prefix: "675"
    },
    {
      iso: "PH",
      title: "Philippines",
      prefix: "63"
    },
    {
      iso: "PK",
      title: "Pakistan",
      prefix: "92"
    },
    {
      iso: "PL",
      title: "Poland",
      prefix: "48"
    },
    {
      iso: "PR",
      title: "Puerto Rico",
      prefix: "1"
    },
    {
      iso: "PT",
      title: "Portugal",
      prefix: "351"
    },
    {
      iso: "PY",
      title: "Paraguay",
      prefix: "595"
    },
    {
      iso: "QA",
      title: "Qatar",
      prefix: "974"
    },
    {
      iso: "RO",
      title: "Romania",
      prefix: "40"
    },
    {
      iso: "RW",
      title: "Rwanda",
      prefix: "250"
    },
    {
      iso: "SA",
      title: "Saudi Arabia",
      prefix: "966"
    },
    {
      iso: "SE",
      title: "Sweden",
      prefix: "46"
    },
    {
      iso: "SG",
      title: "Singapore",
      prefix: "65"
    },
    {
      iso: "SL",
      title: "Sierra Leone",
      prefix: "232"
    },
    {
      iso: "SN",
      title: "Senegal",
      prefix: "221"
    },
    {
      iso: "SR",
      title: "Suriname",
      prefix: "597"
    },
    {
      iso: "SV",
      title: "El Salvador",
      prefix: "503"
    },
    {
      iso: "SZ",
      title: "Eswatini",
      prefix: "268"
    },
    {
      iso: "TC",
      title: "Turks and Caicos",
      prefix: "1649"
    },
    {
      iso: "TD",
      title: "Chad",
      prefix: "235"
    },
    {
      iso: "TG",
      title: "Togo",
      prefix: "228"
    },
    {
      iso: "TH",
      title: "Thailand",
      prefix: "66"
    },
    {
      iso: "TJ",
      title: "Tajikistan",
      prefix: "992"
    },
    {
      iso: "TM",
      title: "Turkmenistan",
      prefix: "993"
    },
    {
      iso: "TN",
      title: "Tunisia",
      prefix: "216"
    },
    {
      iso: "TO",
      title: "Tonga",
      prefix: "676"
    },
    {
      iso: "TR",
      title: "Turkey",
      prefix: "90"
    },
    {
      iso: "TT",
      title: "Trinidad and Tobago",
      prefix: "1868"
    },
    {
      iso: "TW",
      title: "Taiwan",
      prefix: "886"
    },
    {
      iso: "TZ",
      title: "Tanzania",
      prefix: "255"
    },
    {
      iso: "UA",
      title: "Ukraine",
      prefix: "380"
    },
    {
      iso: "UG",
      title: "Uganda",
      prefix: "256"
    },
    {
      iso: "US",
      title: "United States",
      prefix: "1"
    },
    {
      iso: "UY",
      title: "Uruguay",
      prefix: "598"
    },
    {
      iso: "UZ",
      title: "Uzbekistan",
      prefix: "998"
    },
    {
      iso: "VC",
      title: "St. Vincent",
      prefix: "1784"
    },
    {
      iso: "VE",
      title: "Venezuela",
      prefix: "58"
    },
    {
      iso: "VG",
      title: "British Virgin Islands",
      prefix: "1284"
    },
    {
      iso: "VI",
      title: "U.S. Virgin Islands",
      prefix: "1340"
    },
    {
      iso: "VN",
      title: "Vietnam",
      prefix: "84"
    },
    {
      iso: "VU",
      title: "Vanuatu",
      prefix: "678"
    },
    {
      iso: "WS",
      title: "Samoa",
      prefix: "685"
    },
    {
      iso: "YE",
      title: "Yemen",
      prefix: "967"
    },
    {
      iso: "YT",
      title: "Mayotte",
      prefix: "262"
    },
    {
      iso: "ZA",
      title: "South Africa",
      prefix: "27"
    },
    {
      iso: "ZM",
      title: "Zambia",
      prefix: "260"
    },
    {
      iso: "ZW",
      title: "Zimbabwe",
      prefix: "263"
    }
  ];
  

  const providerImageMap = {
    TISN: _TISN,
    TITZ: _TITZ,
    TKID: _TKID,
    TMLB: _TMLB,
    TMPH: _TMPH,
    TPMM: _TPMM,
    TPPK: _TPPK,
    TQBD: _TQBD,
    TUTN: _TUTN,
    TZTR: _TZTR,
    U0UZ: _U0UZ,
    U5GB: _U5GB,
    U6GB: _U6GB,
    U7UZ: _U7UZ,
    UDTG: _UDTG,
    UFPK: _UFPK,
    UJJO: _UJJO,
    UKUZ: _UKUZ,
    UMCN: _UMCN,
    UTUG: _UTUG,
    UUUZ: _UUUZ,
    V8KW: _V8KW,
    V9GH: _V9GH,
    VDCD: _VDCD,
    VDMZ: _VDMZ,
    VDTZ: _VDTZ,
    VDZA: _VDZA,
    VFAL: _VFAL,
    VFAU: _VFAU,
    VFEG: _VFEG,
    VFES: _VFES,
    VFGH: _VFGH,
    VFGR: _VFGR,
    VFIN: _VFIN,
    VFRO: _VFRO,
    VFTR: _VFTR,
    VHVN: _VHVN,
    VIBH: _VIBH,
    VIKW: _VIKW,
    VLVN: _VLVN,
    VNVN: _VNVN,
    VRCL: _VRCL,
    VRSA: _VRSA,
    W2FR: _W2FR,
    WAUG: _WAUG,
    WCGB: _WCGB,
    WIGR: _WIGR,
    WTKW: _WTKW,
    WTPS: _WTPS,
    XMML: _XMML,
    XPSN: _XPSN,
    XUAE: _XUAE,
    XUSA: _XUSA,
    XUUS: _XUUS,
    XZVE: _XZVE,
    YAAU: _YAAU,
    YEGB: _YEGB,
    YMYE: _YMYE,
    YRNA: _YRNA,
    YSES: _YSES,
    YUES: _YUES,
    YXNA: _YXNA,
    Z0ZW: _Z0ZW,
    Z6BF: _Z6BF,
    Z7SL: _Z7SL,
    Z9AE: _Z9AE,
    Z9KW: _Z9KW,
    Z9SA: _Z9SA,
    Z9US: _Z9US,
    ZABH: _ZABH,
    ZAKW: _ZAKW,
    ZANG: _ZANG,
    ZCTG: _ZCTG,
    ZEAZ: _ZEAZ,
    ZFKR: _ZFKR,
    ZIKR: _ZIKR,
    ZJJO: _ZJJO,
    ZKSA: _ZKSA,
    ZNPK: _ZNPK,
    ZQUS: _ZQUS,
    ZSKR: _ZSKR,
    ZXES: _ZXES,
    OFGR: _OFGR,
  '9SUS': _9SUS,
    AALB: _AALB,
    ACIQ: _ACIQ,
    AEID: _AEID,
    AFCD: _AFCD,
    AFGM: _AFGM,
    AFSL: _AFSL,
    AFUG: _AFUG,
    AICD: _AICD,
    AIGH: _AIGH,
    AIIN: _AIIN,
    AIKE: _AIKE,
    AILK: _AILK,
    AIMW: _AIMW,
    AITZ: _AITZ,
    AIZM: _AIZM,
    AJID: _AJID,
    AQAF: _AQAF,
    ASES: _ASES,
    AVTR: _AVTR,
    AWAF: _AWAF,
    AXBD: _AXBD,
    BEKG: _BEKG,
    BEKZ: _BEKZ,
    BERU: _BERU,
    BETJ: _BETJ,
    BEUZ: _BEUZ,
    BFAZ: _BFAZ,
    BGBD: _BGBD,
    BHET: _BHET,
    BLIN: _BLIN,
    BMTJ: _BMTJ,
    BOAU: _BOAU,
    BTES: _BTES,
    BVBH: _BVBH,
    C1CN: _C1CN,
    C2CN: _C2CN,
    CEZA: _CEZA,
    CFGR: _CFGR,
    CFRO: _CFRO,
    CGKH: _CGKH,
    CHGN: _CHGN,
    CKCA: _CKCA,
    CMGN: _CMGN,
    CMLR: _CMLR,
    CNMY: _CNMY,
    CSES: _CSES,
    DGLK: _DGLK,
    DIMY: _DIMY,
    DJDZ: _DJDZ,
    DNNP: _DNNP,
    DSES: _DSES,
    E6AE: _E6AE,
    E7US: _E7US,
    ECBI: _ECBI,
    ECZW: _ECZW,
    ESAU: _ESAU,
    ESES: _ESES,
    ESGB: _ESGB,
    ESSA: _ESSA,
    ETAE: _ETAE,
    ETAF: _ETAF,
    ETEG: _ETEG,
    ETNG: _ETNG,
    EXID: _EXID,
    FAAE: _FAAE,
    FHUS: _FHUS,
    FIUS: _FIUS,
    FROM: _FROM,
    FRSA: _FRSA,
    FUAE: _FUAE,
    G0PH: _G0PH,
    G6GM: _G6GM,
    G7GN: _G7GN,
    GAGM: _GAGM,
    GBPH: _GBPH,
    GEBD: _GEBD,
    GLNG: _GLNG,
    GMVN: _GMVN,
    GPAE: _GPAE,
    GPSA: _GPSA,
    GPUS: _GPUS,
    H5ES: _H5ES,
    HAAE: _HAAE,
    HEPL: _HEPL,
    HMES: _HMES,
    HPES: _HPES,
    HSLK: _HSLK,
    HTID: _HTID,
    I2US: _I2US,
    I3US: _I3US,
    L2IE: _L2IE,
    L3US: _L3US,
    LCLR: _LCLR,
    LIUA: _LIUA,
    LRES: _LRES,
    LSES: _LSES,
    LTML: _LTML,
    LYAU: _LYAU,
    LYES: _LYES,
    LYGB: _LYGB,
    M4GW: _M4GW,
    M6KG: _M6KG,
    MARU: _MARU,
    MAUA: _MAUA,
    MBPK: _MBPK,
    MDDZ: _MDDZ,
    MFVN: _MFVN,
    MGAO: _MGAO,
    MKMZ: _MKMZ,
    MLSA: _MLSA,
    MOMA: _MOMA,
    MRRU: _MRRU,
    MRTJ: _MRTJ,
    MSLK: _MSLK,
    MTAF: _MTAF,
    MTBJ: _MTBJ,
    MTCG: _MTCG,
    MTCI: _MTCI,
    MTCM: _MTCM,
    MTCY: _MTCY,
    MTGH: _MTGH,
    MTGN: _MTGN,
    MTGW: _MTGW,
    MTNG: _MTNG,
    MTRW: _MTRW,
    MTSZ: _MTSZ,
    MTUG: _MTUG,
    MTYE: _MTYE,
    MTZA: _MTZA,
    MTZM: _MTZM,
    MVBJ: _MVBJ,
    MVCF: _MVCF,
    MVCI: _MVCI,
    MVNE: _MVNE,
    MVTG: _MVTG,
    N3NG: _N3NG,
    N4NG: _N4NG,
    N5GH: _N5GH,
    N6GH: _N6GH,
    N7CM: _N7CM,
    N8CI: _N8CI,
    NCNP: _NCNP,
    NENP: _NENP,
    NQNP: _NQNP,
    NYAE: _NYAE,
    O5KM: _O5KM,
    O6US: _O6US,
    O7GN: _O7GN,
    OMOM: _OMOM,
    ONBF: _ONBF,
    OODZ: _OODZ,
    OOMM: _OOMM,
    OOOM: _OOOM,
    OPBF: _OPBF,
    OPCI: _OPCI,
    OPGW: _OPGW,
    OPML: _OPML,
    OPSL: _OPSL,
    OPSN: _OPSN,
    ORCI: _ORCI,
    ORCM: _ORCM,
    OREG: _OREG,
    ORES: _ORES,
    ORGN: _ORGN,
    ORJO: _ORJO,
    ORMA: _ORMA,
    ORMD: _ORMD,
    ORMG: _ORMG,
    ORML: _ORML,
    ORNE: _ORNE,
    ORPL: _ORPL,
    ORRO: _ORRO,
    ORSL: _ORSL,
    ORSN: _ORSN,
    ORTN: _ORTN,
    OXKG: _OXKG,
    P0UZ: _P0UZ,
    PFMM: _PFMM,
    PGUS: _PGUS,
    PNAE: _PNAE,
    PNBH: _PNBH,
    PNKW: _PNKW,
    PNLB: _PNLB,
    PNOM: _PNOM,
    PNQA: _PNQA,
    PNSA: _PNSA,
    PNUS: _PNUS,
    PSPL: _PSPL,
    PWPH: _PWPH,
    QLGM: _QLGM,
    QLSL: _QLSL,
    R0AU: _R0AU,
    RAOM: _RAOM,
    RHAF: _RHAF,
    RJIN: _RJIN,
    RLBD: _RLBD,
    RPES: _RPES,
    RZUS: _RZUS,
    S6SA: _S6SA,
    S7US: _S7US,
    SAKE: _SAKE,
    SCKH: _SCKH,
    SFYE: _SFYE,
    SMPH: _SMPH,
    SSES: _SSES,
    STPH: _STPH,
    T4TJ: _T4TJ,
    T5TH: _T5TH,
    T6TH: _T6TH,
    T7TH: _T7TH,
    THPL: _THPL,
    TIGH: _TIGH,
    TILK: _TILK,
    "0JMM": _0JMM,
    "0RGM": _0RGM,
    "0RSL": _0RSL,
    "0UGM": _0UGM,
    "0USL": _0USL,
    "0VGM": _0VGM,
    "0WMW": _0WMW,
    "1AIN": _1AIN,
    "1DIN": _1DIN,
    "1GMZ": _1GMZ,
    "1KPH": _1KPH,
    "1PBJ": _1PBJ,
    "1QBJ": _1QBJ,
    "1SIN": _1SIN,
    "1UIN": _1UIN,
    "1VIN": _1VIN,
    "1ZTG": _1ZTG,
    "2AAE": _2AAE,
    "2AGB": _2AGB,
    "2ASA": _2ASA,
    "2AUS": _2AUS,
    "2FKM": _2FKM,
    "2NSN": _2NSN,
    "2RKZ": _2RKZ,
    "2SID": _2SID,
    "2VUS": _2VUS,
    "2XID": _2XID,
    "34BF": _34BF,
    "35CI": _35CI,
    "36GH": _36GH,
    "39EG": _39EG,
    "3AKZ": _3AKZ,
    "3GAE": _3GAE,
    "3GSA": _3GSA,
    "3HOM": _3HOM,
    "3SAU": _3SAU,
    "3SSG": _3SSG,
    "4AKZ": _4AKZ,
    "4FAZ": _4FAZ,
    "4HGH": _4HGH,
    "4RUS": _4RUS,
    "5ANG": _5ANG,
    "5CMR": _5CMR,
    "5FMR": _5FMR,
    "5GMR": _5GMR,
    "5MMR": _5MMR,
    "7MMR": _7MMR,
    "8MMR": _8MMR,
    "8TZA": _8TZA,
    "9CUS": _9CUS,
    "9MNG": _9MNG,
    "9RUS": _9RUS,
  };
  
  // Assuming `_TISN`, `_TITZ`, `_TKID`, etc., are imported images
  // from '../../assets/exports'
  

  




  const ForeignAirtime = ({ navigation }) => {
    const webViewRef = useRef(null); 
    const [search, setSearch] = useState('');
    const [active, setActive] = useState(0);
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [show, setShow] = useState(false);
    const [toggleCheckBox, setToggleCheckBox] = useState(false);
    const darkMode = useSelector((state) => state?.common?.darkMode);
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [dropActive, setDropActive] = useState(false);
    const token = useSelector((state) => state?.user?.token);
    const [loading, setLoading] = useState(false);
    const [AirtimeProducts, setAirtimeProducts] = useState([]);
    const [Airtime, setAirtime] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState();
    const amountFieldRef = useRef(null);
    const [loadingGiftCards, setLoadingGiftCards] = useState(true);
    const user = useSelector((state) => state?.user?.userDetails);
    const [selectedAirtimeIndex, setSelectedAirtimeIndex] = useState(null);
    const [selectedOption, setSelectedOption] = useState(undefined);
    const [giftcardRate, setGiftcardRate] = useState([]);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const history = useNavigate();

    const handleAction = () => {
      history('/Home'); // Adjust the route as needed
    };
  
    // Add your other state variables and ref here
  
    const handleSearch = (query) => {
      setSearch(query);
      if (!query) {
        setFilteredCountries([]);
      } else {
        const filtered = buybet.filter((country) =>
          country.title.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredCountries(filtered);
      }
    };

    const [productDetails, setProductDetails] = useState({
      sku: '',
      text: '',
      rate: '',
      code: ''
    });
  
    const handleCountrySelect = (index) => {
      setShow(false);
      const selectedCountry = search ? filteredCountries[index] : buybet[index];
      const activeIndex = buybet.findIndex(country => country.iso === selectedCountry.iso);
      setActive(activeIndex);
      setLoadingGiftCards(true);
      setSearch('');
      setFilteredCountries([]);
    };
  
    const handleAirtimeProviderSelection = async (index) => {
      setSelectedAirtimeIndex(index);
  
      try {
        const selectedAirtimeCode = AirtimeProducts[index]?.code;
        if (selectedAirtimeCode) {
          const { data } = await axios.get(
            `https://dev.api.teepay.site/service/getairtime-products?iso=${buybet[active].iso.toUpperCase()}&code=${selectedAirtimeCode}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
  
          if (data && Array.isArray(data[0]) && data[0].length > 0) {
            const productDetails = {
              sku: data[0][0].sku,
              text: data[0][0].display_text,
              rate: data[0][0].current_rate,
              code: data[0][0].provider_code,
            };
  
            setProductDetails(productDetails);
          } else {
            console.log('Product details not found or in the expected format.');
          }
        }
      } catch (error) {
        console.log('Error retrieving foreign airtime data:', error);
      }
    };
  
    useEffect(() => {
      const fetchProviders = async () => {
        try {
          setLoadingGiftCards(true);
          const { data } = await axios.get(
            `https://dev.api.teepay.site/service/getairtime-providers?iso=${buybet[active].iso.toUpperCase()}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
  
          if (data && Array.isArray(data) && data.length > 0) {
            const innerArray = data[0];
            if (Array.isArray(innerArray) && innerArray.length > 0) {
              const foreignAirtimeProducts = innerArray.map((provider) => {
                return {
                  vet: provider.name,
                  code: provider.code,
                  img: providerImageMap[provider.code],
                  phone_regex: provider.phone_regex,
                };
              });
  
              setAirtimeProducts(foreignAirtimeProducts);
              setLoadingGiftCards(false);
            } else {
              console.log('Inner array is empty or not in the expected format.');
            }
          } else {
            console.log('Outer array is empty or not in the expected format.');
          }
        } catch (error) {
          console.log('Error retrieving foreign airtime data:', error);
        }
      };
  
      if (token) {
        fetchProviders();
      }
    }, [active, token]);
  
    const buyForeignAirtime = async (values) => {
      const req_body = {
        "payment_method": "wallet",
        "service": "foreignairtime",
        "provider": buybet[active].iso,
        "service_plan_ref": productDetails.code,
        "receiver": values?.phoneNumber,
        "amount": values?.amount
      };
  
      if (values?.coupon) {
        req_body.coupon = values?.coupon;
      }
  
      try {
        setLoading(true);
        const { data } = await axios.post(
          'https://dev.api.teepay.site/service/purchase',
          req_body,
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        if (data) {
          showSuccessThenAction('Airtime has been bought successfully', handleAction);
          setLoading(false);
        }
      } catch (error) {
        showError(error?.response?.data?.message);
        setLoading(false);
      }
    };
  
    const initialValues = { phoneNumber: '', amount: '', coupon: '' };
  
    return (
      <div>
        <Header />
        <Container>
          <div className="top">
            <img source={logo} className="logo" />
            <div />
          </div>
  
          <div className="header">
            <p>International Topup</p>
          </div>
  
          <div style={{ marginTop: 20 }}>
            <p> International Topup Country</p>
          </div>
  
          <>
  <div className="select" onClick={() => setShow(!show)}>
    {buybet[active].iso && (
      <CountryFlag
      countryCode={buybet[active].iso}
      svg
      style={{ width: '25px', height: 'auto' }}
    />
    )}
    <p className="select_text">{buybet[active].title}</p>
    <p className="select_text">{buybet[active].prefix}</p>
  </div>

  {show && (
    <div className="dropdown_wrapper">
      <input
        type="text"
        placeholder="Search for a country"
        value={search}
        onChange={(e) => handleSearch(e.target.value)}
        style={{
          backgroundColor: darkMode ? '#303030' : '#FFFFFF',
          color: darkMode ? '#FFFFFF' : '#000000',
          padding: '8px 10px',
          marginBottom: 10,
          borderRadius: 5,
          border: `1px solid ${darkMode ? '#666' : '#999'}`,
        }}
      />

      {(search ? filteredCountries : buybet).map((country, index) => (
        <div
        className="dropdown_item"
        onClick={() => {
          setShow(!show);
          handleCountrySelect(index); // Pass the country object to handleSelectCountry
          setLoadingGiftCards(true);
        }}
        >
          <CountryFlag
        countryCode={country.iso}
        svg
        style={{ width: '25px', height: 'auto' }}
      />
          <p className="select_text">{country.title}</p>
          <p className="select_text">{country.prefix}</p>
        </div>
      ))}
    </div>
  )}
</>


<div style={{ marginTop: 20, marginBottom: 10, padding: 30 }}>
    <p style={{ marginBottom: 50, fontSize: 18, fontWeight: 'bold', color: 'grey', paddingLeft: 30 }}>Available Providers in {buybet[active].title}</p>
    {loadingGiftCards ? (
  <p style={{ marginBottom: 50, fontSize: 18, fontWeight: 'bold', color: 'grey', paddingLeft: 30 }}>Loading Providers...</p>
) : AirtimeProducts.length > 0 ? (
  <div className='giftcardContainer'>
    {AirtimeProducts.map((provider, index) => (
      <div
        key={index}
        className={`giftcardItem ${selectedAirtimeIndex === index ? 'selectedGiftcard' : ''}`}
        onClick={() => handleAirtimeProviderSelection(index)}
      >
        <img source={provider.img} className='bill_img' />
        <p className='selectText'>{provider.vet}</p>
      </div>
    ))}
  </div>
) : (
  <p>No Providers Available</p>
)}
</div>



  
<Formik
  initialValues={initialValues}
  onSubmit={(values) => {
    buyForeignAirtime(values);
  }}
>
  {({ handleSubmit, values }) => (
    <div style={{ marginTop: 30 }}>
      <div ref={amountFieldRef}>
        <div>
          <p className="warning">Begin your Number with {buybet[active].prefix}</p>
          <Textfield
            name="phoneNumber"
            title="Phone Number"
            style={{ marginTop: 0 }}
            keyboardType="number-pad"
          />
          <p className="warning">Minimum and Maximum Airtime : {productDetails.text}</p>
          <p className="warning">
            Amount should be value in USD currency multiplied by : {giftcardRate}
          </p>
          <Textfield name="amount" title="Amount" style={{ marginTop: 0 }} keyboardType="number-pad" />
          <Textfield name="coupon" title="Coupon" style={{ marginTop: 0 }} />
        </div>
      </div>

      <Btn text="Proceed" onClick={handleSubmit} loading={loading} />
    </div>
  )}
</Formik>
</Container>
  <Footer />
      </div>
    );
  };
  
  export default ForeignAirtime;
  