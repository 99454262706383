import axios from 'axios';
import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Col, Container, Row } from 'react-bootstrap';
import Btn from '../components/Btn';
import { showSuccessThenAction } from '../components/Error';
import { Textfield } from '../components/Textfield';
import { logo } from '../assets/exports';
import Footer from '../components/Footer';

import '../css/styles.css'; // Import your CSS file for styling

const ResetPassword = ({ history }) => {
  const [screen, setScreen] = useState(0);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAction = () => {
    history('/');
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid format').required('Required'),
    otp: yup.string().required('Required'),
    newPassword: yup.string().required('Required'),
    confirmPassword: yup
      .string()
      .required('Required')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const initialValues = {
    email: '',
    otp: '',
    newPassword: '',
    confirmPassword: '',
  };

  const initResetPassword = async (values) => {
    setLoading(true);
    try {
      const url = 'https://dev.api.teepay.site/auth/init-reset-password';
      const { data } = await axios.post(url, { email: values.email });
      if (data) {
        console.log(data, 'duro');
        setLoading(false);
        setScreen(1);
        setEmail(values.email);
      }
    } catch (error) {
      console.log('error', error?.response?.data);
      setLoading(false);
    }
  };

  const resetPassword = async (values) => {
    console.log({ email, otp: values.otp, password: values.newPassword }, 'don');
    setLoading(true);
    try {
      const url = 'https://dev.api.teepay.site/auth/reset-password';
      const { data } = await axios.post(url, { email, otp: values.otp, password: values.newPassword });
      if (data) {
        console.log(data, 'duro');
        setLoading(false);
        showSuccessThenAction('Password successfully reset', handleAction);
      }
    } catch (error) {
      console.log('error', error?.response?.data);
      setLoading(false);
    }
  };

  return (
    <div>
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={6}>
          <img src={logo} alt="Logo" className="logo1" />

          <p className="reset">Reset Password</p>

          <Formik initialValues={initialValues} onSubmit={screen === 0 ? initResetPassword : resetPassword} validationSchema={validationSchema}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="align-end">
                  <Textfield name="email" title="Email" icon="email" />
                  {screen === 1 && (
                    <>
                      <Textfield name="otp" title="Enter OTP" icon="lock" type="number" />
                      <Textfield name="newPassword" title="Enter new password" type="password" />
                      <Textfield name="confirmPassword" title="Confirm Password" type="password" />
                    </>
                  )}
                  <Btn
                  variant="primary"
                  style={{ marginTop: '20px' }}
                  className='btn'
                  onClick={() => {
                    console.log('Login button clicked');
                    handleSubmit();
                  }}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Login'}
                </Btn>
                </div>
              </form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
    <Footer />
    </div>
  );
};

export default ResetPassword;
