export const showError = (message) => {
  alert(`Error: ${message}`);
};

export const showSuccess = (message) => {
  alert(`Success: ${message}`);
};

export const showSuccessThenAction = (message, action) => {
  alert(`Success: ${message}`);
  action();
};
