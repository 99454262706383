import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  darkMode:false,
}

export const commonSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    toggleDarkMode: (state, action) => {
      console.log(action,'nappy');
      state.darkMode = action.payload
    },
  },
})

export const { toggleDarkMode } = commonSlice.actions

export default commonSlice.reducer