import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Download, Jointeepay, Paybills } from '../assets/exports';
import { Awoof } from '../assets/exports';
import { Dontrunout } from '../assets/exports';

const MyCarousel = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Jointeepay}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Awoof}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Dontrunout}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Paybills}
          alt="Fourth slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Download}
          alt="Fifth slide"
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default MyCarousel;
