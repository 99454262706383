import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import ReactModal from 'react-modal';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  Paybills,
  aedc,
  benin,
  eedc,
  ekedc,
  ibedc,
  ikedc,
  jed,
  kedc,
  kedco,
  logo,
  phed
} from '../../assets/exports';
import { showError, showSuccessThenAction } from '../../components/Error';
import { AntDesignIcons, EntypoIcon } from '../../components/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { Textfield } from '../../components/exports';
import Btn from '../../components/Btn';
import '../../css/Bills.css';
import { Header } from '../../components/Header';
import  Footer from '../../components/Footer';
import Banner from '../../components/Banner';
import { useNavigate } from 'react-router-dom';

// Define the bet and drops arrays here
const betprepaid = [
  {
    bet: 'Ikeja Electricity Prepaid',
    vet: 'IKEDC',
    img: ikedc,
},
{
    bet: 'Ibadan Electricity Prepaid',
    vet: 'IBEDC',
    img: ibedc,
},
{
    bet: 'Eko Electricity Prepaid',
    vet: 'EKEDC',
    img: ekedc,
},
{
    bet: 'Abuja Electricity Prepaid',
    vet: 'AEDC',
    img: aedc,
},
{
  bet: 'Benin Electricity Prepaid',
  vet: 'BEDC',
  img: benin,
},
{
  bet: 'Jos Electricity Prepaid',
  vet: 'JED',
  img: jed,
},
{
    bet: 'Kaduna Electricity Prepaid',
    vet: 'KEDCO',
    img: kedc,
},
{
    bet: 'Enugu Electricity Prepaid',
    vet: 'EEDC',
    img: eedc,
},
{
    bet: 'PortHacourt Electricity Prepaid',
    vet: 'PHEDC',
    img: phed,
},
];


const betpostpaid = [
    {
        bet: 'Ikeja Electricity Postpaid',
        vet: 'IKEDC',
        img: ikedc,
    },
    {
        bet: 'Ibadan Electricity Postpaid',
        vet: 'BEDC',
        img: ibedc,
    },
    {
        bet: 'Eko Electricity Postpaid',
        vet: 'EKEDC',
        img: ekedc,
    },
    {
        bet: 'Abuja Electricity Postpaid',
        vet: 'AEDC',
        img: aedc,
    },
    {
      bet: 'Benin Electricity Prepaid',
      vet: 'BEDC',
      img: benin,
    },
    {
      bet: 'Jos Electricity Prepaid',
      vet: 'JED',
      img: jed,
    },
    {
        bet: 'Kaduna Electricity Postpaid',
        vet: 'KEDCO',
        img: kedc,
    },
    {
        bet: 'Enugu Electricity Postpaid',
        vet: 'EEDC',
        img: eedc,
    },
    {
        bet: 'PortHacourt Electricity Postpaid',
        vet: 'PHEDC',
        img: phed,
    },
];

const drops = ['Prepaid', 'Postpaid'];

// Use ReactModal.setAppElement to set the root element for accessibility
ReactModal.setAppElement('#root');

const Bills = ({ navigation }) => {
  const history = useNavigate();
  const handleAction = () => {
    history('/Home'); // Adjust the route as needed
  };

  const [active, setActive] = useState(0);
  const [show, setShow] = useState(false);
  const [toggleCheckBox, setToggleCheckBox] = useState(false);
  const [activeBill, setActiveBill] = useState(1);
  const [meterType, setMeterType] = useState(drops[0]);

  const [drop, setDrop] = useState(drops[0]);
  const [dropActive, setDropActive] = useState(false);
  const darkMode = useSelector((state) => state?.common?.darkMode);
  const token = useSelector((state) => state?.user?.token);
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [verificationTriggered, setVerificationTriggered] = useState(false);
  const [meterValue, setMeterNumber] = useState('');
  const [verificationResult, setVerificationResult] = useState(null);
  const [meterNumberText, setMeterNumberText] = useState(''); // Initialize with an empty string



  const formikRef = useRef(null);

const handleFormSubmit = async (values) => {
  verifyMeterNumber(values);
};

const handlePay = () => {
  // Close the modal
  setShowModal(false);

  // Get Formik's values using the ref
  const values = formikRef.current?.values;


  // Call the second function (buyElectricity)
  buyElectricity(values);
};






    const verifyMeterNumber = async (values) => {
      if (token) {
          setLoading(true);
          const providerArray = meterType === 'Prepaid' ? betprepaid : betpostpaid;
          const url = `https://dev.api.teepay.site/service/verify-receiver`;
          const headers = {
              Authorization: `Bearer ${token}`,
          };
          const reqBody = {
              "service": "electricity",
              "provider": providerArray[active].vet,
              "receiver": values?.meterNumber,
          };

          try {
              const res = await axios.post(url, reqBody, { headers });
              if (res) {
                  const transactionData = res.data.body;
                  setLoading(false);
                  console.log(res.data, 'res');
                  setTransactionDetails(transactionData);
                  setShowModal(true);
              }
          } catch (error) {
              setLoading(false);
              console.log(error?.response?.data?.message, 'error');
              { showError(error?.response?.data?.message)};
          }
      } else {
          { showError('Unauthorized request') }
      }
  };

  const providerArray = meterType === 'Prepaid' ? betprepaid : betpostpaid;



  const buyElectricity = async (values) => {
    if (token) {
        setLoading(true);
        // Determine the provider array based on meterType
        const providerArray = meterType === 'Prepaid' ? betprepaid : betpostpaid;
        const url = `https://dev.api.teepay.site/service/purchase`;
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const reqBody = {
            "payment_method": "wallet",
            "service": "electricity",
            "provider": providerArray[active].vet,
            "receiver": values?.meterNumber,
            "amount": values?.amount
        };

        if (values?.coupon) {
            reqBody.coupon = values?.coupon;
        }

        try {
            const res = await axios.post(url, reqBody, {
                headers,
                timeout: 600000, // Timeout in milliseconds (10 minutes)
            });
            if (res) {
                console.log(res, 'res');
                showSuccessThenAction('Electricity has been bought successfully, Please wait for a few moment to receive your Token', handleAction);
            }
            setLoading(false);
        } catch (error) {
            console.log(error?.response?.data?.message, 'error');
            showError(error?.response?.data?.message);
            setLoading(false);
        }
    } else {
        showError('Unauthorized request');
    }
};


useEffect(() => {
  // Start of Tawk.to Script
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement('script'),
      s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();
  // End of Tawk.to Script

  // Clean up the script when the component unmounts
  return () => {
    // Add any cleanup code here, if necessary
  };
}, []); // Empty depe

  return (
    <div>
      <Header />
    <Container>
      <Col>
      </Col>

      <h1>Electricity</h1>
      <div>
      <Banner imageUrl={Paybills} altText="Paybils" />
      </div>
      

      

               <div>
                    <p>Please Note: A Fee of #150 will be charged per electricity bill purchase.</p>
                </div>

      <div className='drop' onClick={() => setDropActive(!dropActive)}>
        <p>{meterType}</p>
        <FontAwesomeIcon icon={faChevronDown}  color="#25D366" />
      </div>

      {dropActive && (
        <div className='drop_list'>
          {drops.map((_, i) => (
            <div
              key={i}
              className={drop === _ ? 'drop_list_item_ACTIVE' : 'drop_list_item'}
              onClick={() => {
                setMeterType(_);
                setDropActive(!dropActive);
              }}
            >
              <p>{_}</p>
            </div>
          ))}
        </div>
      )}


<div className='select' onClick={() => setShow(!show)}>
        <img src={meterType === 'Prepaid' ? betprepaid[active].img : betpostpaid[active].img} className='select_img' alt="electricity-logo" />
        <p className='select_text'>{meterType === 'Prepaid' ? betprepaid[active].bet : betpostpaid[active].bet}</p>
      </div>

      {show && (
        <div className='dropdown_wrapper'>
          {meterType === 'Prepaid' ? (
          betprepaid.map((_, i) => (
            <div
              className='dropdown_item'
              key={i}
              onClick={() => {
                setShow(!show);
                setActive(i);
              }}
            >
              <img src={_.img} className='select_img' alt="electricity-logo" />
              <p className='select_text'>{_.bet}</p>
            </div>
          ))
          ) : (
            betpostpaid.map((_, i) => (
              <div
                className='dropdown_item'
                key={i}
                onClick={() => {
                  setShow(!show);
                  setActive(i);
                }}
              >
                <img src={_.img} className='select_img' alt="electricity-logo" />
                <p className='select_text'>{_.bet}</p>
              </div>
            ))
            )}
        </div>
      )}

      <Formik
        innerRef={formikRef}
        initialValues={{ amount: '', meterNumber: '', coupon: '' }}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({ handleSubmit }) => (
          <div>
            <p> Amount must be a minimum of 1000 NGN</p>
            <Textfield name='amount' title='Amount' />
            <Textfield name='meterNumber' title='Meter Number' />

            <Textfield name='coupon' title='Coupon' />

            <Btn
              variant="primary"
              text={loading ? 'Verifying...' : 'Verify Meter'}
              type='submit'
              style={{ marginTop: '20px' }}
              className='btn'
              onClick={() => {
                console.log('Login button clicked');
                handleSubmit();
              }}
              disabled={loading}
            />
          </div>
        )}
      </Formik>
      <div>
      </div>

<ReactModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Transaction Details"
      >
        <div className="closeButtonContainer">
          <div className="closeButton" onClick={() => setShowModal(false)}>
            <p className="closeButtonText">Close</p>
          </div>
        </div>

        {/*Display transaction details here */}
        {transactionDetails && (
          <div>
            <div>
          
          <p className='modalHeaderp'>
            - # {formikRef.current?.values?.amount}
          </p>
        </div>
        <div>
            <div className='row'>
              <Col>
              <span className='value rightAligned'>Provider:</span>&nbsp;&nbsp;&nbsp;
              <span className='value leftAligned'>{betprepaid[active].bet.toUpperCase()}</span>
              </Col>
              
            </div>
            <div className='row'>
              <Col>
              <span className='value rightAligned'>Meter Owner:</span>&nbsp;&nbsp;&nbsp;
              <span className='value leftAligned'>{transactionDetails.fullname}</span>
              </Col>
              
            </div>
            <div className='row'>
              <Col>
              <span className='value rightAligned'>Meter Number:</span>&nbsp;&nbsp;&nbsp;
              <span className='value leftAligned'>{formikRef.current.values.meterNumber}</span>
              </Col>
              
            </div>
            <div className='row'>
              <Col>
              <span className='value rightAligned'>Meter Type:</span>&nbsp;&nbsp;&nbsp;
              <span className='value leftAligned'>{drop}</span>

              </Col>
              
            </div>
            {formikRef.current?.values?.coupon && (
              <div className='row'>
                <Col>
                <span className='value rightAligned'>Coupon:</span>&nbsp;&nbsp;&nbsp;
                <span className='value leftAligned'>{formikRef.current.values.coupon}</span>
                </Col>
                
              </div>
            )}
          </div>
          </div>
        )}

          <Btn
              variant="primary"
              text={loading ? 'Paying...' : 'Pay'}
              type='submit'
              style={{ marginTop: '60px' }}
              className='btn'
              onClick={() => {
                console.log('Login button clicked');
                handlePay();
              }}
              disabled={loading}
            />
      </ReactModal>
      
      <Col>
        
      </Col>
    </Container>
    <Footer />
    </div>
  );
};

export default Bills;
