import axios from 'axios';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { loginpic, logo } from './assets/exports';
import { showError } from './components/Error';
import Btn from './components/Btn';
import { Textfield } from './components/Textfield';
import { saveToken, saveUserDetails } from './redux/user';
import LoadingSpinner from './components/LoadingSpinner';

import './css/Login.css';

const Login = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state?.user?.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const initializeWebSocket = () => {
  
    const ws = new WebSocket('wss://dev.api.teepay.site');
  
        ws.onopen = () => {
            console.log('WebSocket connection opened');
            ws.send(JSON.stringify({ type: 'init' , token: token }));
        };

        ws.onerror = (error) => {
          console.error('WebSocket error:', error);
        };


     // Handle incoming WebSocket messages
        ws.onmessage = (message)=>{
            console.log('Received Websocket Message', JSON.parse(message.data));
            let data = JSON.parse(message.data);

    };
  
    ws.onclose = (e) => {
      console.log('WebSocket connection closed:', e.code, e.reason);
    };
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid format').required('Required'),
    password: yup.string().required('Password is required').typeError('Password must be a string'),
  });

  const login = async (values) => {
    setLoading(true);
    try {
      const res = await axios.post('https://dev.api.teepay.site/auth/login', values);
      console.log(res.data);
      if (res) {
        console.log(res.data?.body?.user?.email);
        setLoading(false);
        dispatch(saveToken(res.data?.body?.token[0].token));
        dispatch(saveUserDetails(res.data));
        navigate('/home');
      }
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
  
      if (error.response) {
        // Check for the network error
        if (!navigator.onLine) {
          window.alert('No internet connection. Please check your network.');
        } else {
          if (error.response.data?.message === "Your email has not been verified.") {
          } else {
            showError(error.response.data?.message);
          }
        }
      } else {
        // Handle other types of errors
        showError('An error occurred. Please try again.');
      }
    }
  };

  useEffect(() => {
    initializeWebSocket();
  }, []);

  useEffect(() => {
    // Start of Tawk.to Script
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    // End of Tawk.to Script

    // Clean up the script when the component unmounts
    return () => {
      // Add any cleanup code here, if necessary
    };
  }, []); // Empty depe

  return (
    <Container className=''>
      <Row className="justify-content-center align-items-center">
      <Col xs={12} md={6} lg={6} className="d-none d-md-block">
          {/* Add your image here */}
          <img src={loginpic} alt="Image" className="login-image" />
      </Col>
        <Col xs={12} md={6}>
          <img src={logo} alt="Logo" className="logo1"/>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={(values) => login(values)} // Pass values to the login function
            validationSchema={validationSchema}
          >
            {({ handleSubmit }) => (
              <div>
                <div className="email" ><Textfield name='email' title='Email' type='email'/></div>
                <div className="password" ><Textfield name='password' title='Password' type='password'/></div>
                <div>
                <li className="forgot-password"><Link to="/ResetPassword">Forgot Password?</Link></li>
                </div>
                <Btn
                  variant="primary"
                  text={loading ? 'Logging In...' : 'Login'}
                  type="submit"
                  style={{ marginTop: '20px' }}
                  className='btn'
                  onClick={() => {
                    console.log('Login button clicked');
                    handleSubmit();
                  }}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Login'}
                </Btn>
              </div>
            )}
          </Formik>
          <div className="t-wrapper mt-3">
            <span className="t1">New user? </span>
            <li className="t2"><Link to="/idverification">Create Account</Link></li>
          </div>
          <div>
            <p className='t3'>By Logging in you agree to the <a href="https://teepay.site/privacy-policy/" target="_blank" rel="noopener noreferrer">
      Terms and Conditions
    </a> of TEEPAYEEDATA</p>
          </div>
          
        </Col>
      </Row>
    </Container>
  );
};

export default Login;


