import axios from 'axios';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
    avatar, logo
} from '../../assets/exports';
import Btn from '../../components/Btn';
import { showError } from '../../components/Error';
import { Textfield } from '../../components/Textfield';
import { styles as sx, width } from '../../helpers/styles';
import  Footer from '../../components/Footer';
import ReactModal from 'react-modal';
import  { Header } from '../../components/Header';
import '../../css/TopUp.css';

export default function TopUp({ navigation }) {
    const navigationFunction = (i) => {
        i === 0 && navigation.navigate('app_bet');
        i === 2 && navigation.navigate('app_buy_data');
    }

    const formikRef = useRef(null);
    const token = useSelector((state) => state?.user?.token);
    const bank = useSelector((state) => state?.user?.userDetails?.body?.bank_account);
    console.log(bank, 'token');
    const darkMode = useSelector((state) => state?.common?.darkMode);
    const [charges, setCharges] = useState(0);

    const [activeTab, setActiveTab] = useState(0);
    // weedanjola
    const [showWebView, setShowWebView] = useState('');
    const bankReference = useSelector((state) => state?.user?.userDetails?.body?.bank_account?.customer_ref);
    console.log(bankReference, 'bankReference');

    const bankDetails = [
        {
            t1: 'Bank',
            t2: bank?.bank_name,
        },
        {
            t1: 'Account Name',
            t2: bank?.account_name,
        },
        {
            t1: 'Account Number',
            t2: bank?.account_number,
        },
    ]

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [show, setShow] = useState(false);
    console.log(show, 'show');
    const [dashboardData, setDashboardData] = useState({});


    useEffect(() => {
        const getDashboardData = async () => {
            try {
                const res = await axios.get('https://dev.api.teepay.site/user/dashboard', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })

                console.log(res.data, 'dashboard data');
                setDashboardData(res.data?.body);
            } catch (error) {
                { showError(error?.response?.data) }
                console.log(error.response.data.message)
            }
        };

        if (token) {
            getDashboardData();
        }
    }, [])


    const topUpWallet = async (amount) => {
        setLoading2(true);

        const req_body = {
            amount,
        };

        try {
            const res = await axios.post(
                'https://dev.api.teepay.site/wallet/init-fund-wallet',
                req_body,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log(res.data, 'response from the payment endpoint');
            if (res) {
                setLoading2(false);

                const completeLink = `https://dev.api.teepay.site/${res.data?.body?.link}`;
            window.open(completeLink, '_blank');
            }
        } catch (error) {
            setLoading2(false);
            console.log(error.response.data, 'error from the payment endpoint');
        }
    };

    const topUpWalletWithBank = async (amount) => {
        setLoading(true);
        const reqBodyForBankTopUp = {
            "reference": bankReference,
        };

        console.log(`Bearer ${token}`, 'tokens')

        try {
            const res = await axios.post('https://dev.api.teepay.site/wallet/verify-fund-wallet', reqBodyForBankTopUp, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            setLoading(false);
            console.log(res.data, 'response from the payment endpoint');
            if (res) {
                const completeLink = `https://dev.api.teepay.site/${res.data?.body?.link}`;
                window.open(completeLink, '_blank');
            }
        } catch (error) {
            setLoading(false);
            console.log(error.response.data, 'error from the payment endpoint');
        }
    };

    {/*const webViewRef = useRef<WebView | null>(null);
    console.log(webViewRef, 'webViewRef');

    // const handleNavigationStateChange = (event: any) => {
    //     //navState: WebViewNavigation
    //     // Check if the payment is successful based on the URL or other criteria
    //     if (event.url.includes('cancel')) {
    //         setShowWebView('');
    //         // Call the onClose function when the cancel event is detected
    //     }

    //     if (navState.url.includes('success')) {
    //         // Close the WebView
    //         webViewRef.current?.stopLoading(); // Stop any ongoing loading
    //         // Additional cleanup or navigation can be performed here
    //     }
    // };

    const handleNavigationStateChange = (event) => {
        console.log(event, 'event');
        const { url } = event;

        // Check if the payment is canceled based on the URL or other criteria
        if (url.includes('cancel')) {
            // Assuming setShowWebView is a state setter function to hide the WebView
            setShowWebView('');
            // Call the onClose function when the cancel event is detected
            // onClose(); // Uncomment this line if you want to call the onClose function here
        }

        // Check if the payment is successful based on the URL or other criteria
        if (url.includes('success')) {
            // Assuming webViewRef is a reference created using the useRef hook
            // and assigned to the ref prop of the WebView
            webViewRef.current?.stopLoading(); // Stop any ongoing loading in the WebView

            // Additional cleanup or navigation can be performed here after a successful payment
            // For example, you might want to show a success message or navigate to a success screen.
        }
    };*/}


    useEffect(() => {
        // Start of Tawk.to Script
        var Tawk_API = Tawk_API || {},
          Tawk_LoadStart = new Date();
        (function () {
          var s1 = document.createElement('script'),
            s0 = document.getElementsByTagName('script')[0];
          s1.async = true;
          s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
          s1.charset = 'UTF-8';
          s1.setAttribute('crossorigin', '*');
          s0.parentNode.insertBefore(s1, s0);
        })();
        // End of Tawk.to Script
    
        // Clean up the script when the component unmounts
        return () => {
          // Add any cleanup code here, if necessary
        };
      }, []); // Empty depe


    return (
        <div>
            <Header />
       
        <Container>
            <Col>
              
            </Col>

                <div className='first_view'>
                    <h1 className='user'>Wallet Top-up</h1>
                </div>

                <div className='tabs'>
                    {
                        ['Card', 'Bank Transfer'].map((_, i) => {
                            return (
                                <div
                                    className={activeTab === i ? 'tab_active' : 'tab'}
                                    onClick={() => setActiveTab(i)}
                                >
                                    <p
                                        className={activeTab === i ? 'tab_text_active' : 'tab_text_inactive'}
                                    >
                                        {_}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>

                {
                    activeTab === 0 ?
                        <div>
                            {/* <View style={styles.pay_row}>
                                <View style={styles.credit_card}>
                                    <Text style={styles.credit_card_name}>VISA</Text>
                                    <Text style={styles.card_number}>**** 3849</Text>
                                    <Text style={styles.cvv}>23/07       761 </Text>
                                </View>

                                <View style={styles.add_new_card}>
                                    <View style={styles.add_plus}>
                                        <EntypoIcon name="plus" size={26} color="white" />
                                    </View>

                                    <Text style={styles.add_plus_text}>New credit card</Text>
                                </View>
                            </View> */}
                            <div>
                                          <p>Please Enter an Amount and Confirm</p>
                            </div>

                            <div>
                                <p>
                                   Charges: {charges}
                                </p>
                                </div>


                            <Formik
                                initialValues={{ amount: '' }}
                                onSubmit={(values) => {
                                    console.log(values, 'super')
                                    const amount = parseFloat(values.amount);
                                    const calculatedCharges = amount * 0.015;
                                    setCharges(calculatedCharges);
                                    topUpWallet(values.amount)
                                }}
                            >
                                {({values, handleChange, handleSubmit }) =>
                                    <>
                                        <Textfield name='amount' title='Amount' keyboardType="number-pad" onChange={(e) => {
                  handleChange(e);
                  // Calculate 1.5% of the entered amount as charges
                  const amount = parseFloat(e.target.value);
                  const calculatedCharges = amount * 0.015;
                  setCharges(calculatedCharges);
                }}/>
                                        <Btn
                  variant="primary"
                  text={loading ? 'Processing...' : 'TopUp'}
                  type="submit"
                  style={{ marginTop: '20px' }}
                  className="btn"
                  onClick={() => {
                    console.log('Login button clicked');
                    handleSubmit();
                  }}
                  loading={loading}
                />
                                    </>
                                }
                            </Formik>


                        </div>
                        :
                        <div>
                            {/* <View style={styles.pay_row}>
                                <View style={styles.credit_card}>
                                    <Text style={styles.credit_card_name}>VISA</Text>
                                    <Text style={styles.card_number}>**** 3849</Text>
                                    <Text style={styles.cvv}>23/07       761 </Text>
                                </View>

                                <View style={styles.add_new_card}>
                                    <View style={styles.add_plus}>
                                        <EntypoIcon name="plus" size={26} color="white" />
                                    </View>

                                    <Text style={styles.add_plus_text}>New credit card</Text>
                                </View>
                            </View> */}

                            <Formik
                                initialValues={{ amount: '' }}
                                onSubmit={async (values) => {
                                    console.log(values, 'values');
                                    topUpWallet(values.amount);
                                }}
                                innerRef={formikRef}
                            >
                                {
                                    ({ values, handleChange, handleSubmit }) => (
                                        <div>
                                            {/* <Textfield
                                                name='amount'
                                                title='Amount'
                                            /> */}
                                            <div>
                                                <p className=''>You Can Transfer to your Personal Virtual Account
                                                 Below and It Will be Credited into your Wallet</p>
                                                <p className='bankHeader'>Personal Virtual Bank Details</p>

                                                <div className='bankDetail_container'>
                                                    {
                                                        bankDetails.map((_, i) => {
                                                            return (
                                                                <div key={i} className='bankDetail_row'>
                                                                    <p className='bankDetail'>{_?.t1}</p>
                                                                    <div>
                                                                         <p className='bankDetail'>{_?.t2}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>

                                            {/* <Btn
                                                text='Confirm'
                                                style={{ marginTop: 30 }}
                                                onPress={handleSubmit}
                                                loading={loading2}
                                            /> */}
                                        </div>
                                    )
                                }
                            </Formik>



                            {/* <Text style={styles.verify_bvn}>Please verify with your<Text style={styles.verify_bvn2}> BVN</Text></Text> */}
                        </div>
                }

                {/*<ReactModal visible={showWebView !== ''} transparent={true}>

                    <div style={styles.closeButtonContainer}>
                        <div
                            style={styles.closeButton}
                            onClick={() => setShowWebView('')}>
                           <p style={styles.closeButtonText}>Close</p>
                        </div>
                   </div>
                   
                    <WebView
                        source={{ uri: showWebView }}
                        style={{ flex: 1, paddingTop: 0, }}
                        ref={webViewRef}
                        onMessage={(event) => {
                            console.log(event,'heart');
                            const messageData = event.nativeEvent.data;
                            console.log('Received message from WebView:', messageData);
                        }}
                        onNavigationStateChange={(state) => {
                            console.log(state, 'state')
                            // Check if the new URL contains a specific string indicating a cancellation
                            // if (newNavState.url.includes('cancel-payment')) {
                            //     // Close the WebView here
                            //     webViewRef?.current.stopLoading(); // Stop loading the current page
                            //     setShowWebView(''); // Clear the showWebView state to close the WebView
                            // }
                        }}
                    />
                    </ReactModal>*/}
                    
        </Container>
        <Footer />
        </div>
    )
}