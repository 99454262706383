// Your React component
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  airtime,
  data,
  tv,
  betting,
  Vector,
  ohm, book, world
} from '../../assets/exports';
import { showError } from '../../components/Error';
import Footer from '../../components/Footer';
import { Header } from '../../components/Header';
import MyCarousel from '../../components/MyCarousel';
import '../../css/styles.css';
import FloatingText from './FloatingText';

const services = [
  {
    name: 'Airtime',
    icon: airtime,
  },
  {
    name: 'Electricity',
    icon: ohm,
  },
  {
    name: 'Data Bundles',
    icon: data,
  },
  {
    name: 'TV',
    icon: tv,
  },
  {
    name: 'Betting',
    icon: betting,
  },
  {
    name: 'Giftcards',
    icon: Vector,
  },
  {
    name: 'Education',
    icon: book,
  },
  {
    name: 'International Topup',
    icon: world,
  }
];

{/*
  ,*/}

const Home = ({ navigation }) => {
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [latestNotification, setLatestNotification] = useState('');
  const [refreshInterval, setRefreshInterval] = useState(null);
  const [loading, setLoading] = useState(true);
  const userName = useSelector((state) => state?.user?.userDetails);
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const token = useSelector((state) => state?.user?.token);

  const navigationFunction = (i) => {
    i === 0 && history('/Airtime');
    i === 1 && history('/Bills');
    i === 2 && history('/BuyData');
    i === 3 && history('/Tv');
    i === 4 && history('/Bet');
    i === 5 && history('/Giftcards');
    i === 6 && history('/Education');
    i === 7 && history('/ForeignAirtime');
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get('https://dev.api.teepay.site/notification?limit=20&offset=0', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        // Assuming the API response contains an array of notifications
        // You can modify this based on the actual API response structure
        const lastNotifications = response.data.body.notifications.slice(-20); // Get the last 20 notifications
        setNotifications(lastNotifications);
        const lastNotification = lastNotifications[0];
        if (lastNotification) {
          setLatestNotification(lastNotification.message);
        }
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      // Set loading to false when fetching is done
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const history = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (token) {
      const getUserTransactionHistory = async () => {
        try {
          const { data } = await axios.get(
            'https://dev.api.teepay.site/transaction',
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          console.log(data?.body?.transactions, 'transaction history data');
          console.log(token);
          if (data) {
            setTransactionHistory(data?.body?.transactions);
          }
        } catch (error) {
          console.log(
            error.response?.data,
            'error loading transaction data'
          );
        }
      };

      const getDashboardData = async () => {
        try {
          const res = await axios.get(
            'https://dev.api.teepay.site/user/dashboard',
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          console.log(res.data, 'dashboard data');
          setDashboardData(res.data?.body);
        } catch (error) {
          showError(error?.response?.data);
          console.log(error.response.data.message);
        }
      };

      if (token) {
        getUserTransactionHistory();
        getDashboardData();
      }
    }
  }, [token]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    // Format the date to a readable string, e.g., "Mon, Nov 22, 2023 10:30 AM"
    const formattedDate = date.toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' });
    return formattedDate;
  };

  const getAmountClassName = (type, status) => {
    if (status === 'pending') {
      return 'pending';
    } else if (type === 'debit' && status === 'completed') {
      return 'debit-success';
    } else if (type === 'credit' && status === 'completed') {
      return 'credit-success';
    } else {
      return ''; // No additional class for other cases
    }
  };


  useEffect(() => {
    // Load the Reloadly widget script
    const reloadlyScript = document.createElement('script');
    reloadlyScript.src = 'https://cdn.reloadly.com/widget/v2/reloadly-widget.js';
    reloadlyScript.async = true;
    reloadlyScript.charset = 'UTF-8';
    document.body.appendChild(reloadlyScript);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(reloadlyScript);
    };
  }, []);
  

  useEffect(() => {
    // Start of Tawk.to Script
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    // End of Tawk.to Script

    // Clean up the script when the component unmounts
    return () => {
      // Add any cleanup code here, if necessary
    };
  }, []); // Empty depe


  return (
    <div>
      <div className='container'>
      <Header />
      </div>
      

    <Container>
    
    <Col xs={12} className="">
         
         <div className=''>
           <span className="welcome_back">Welcome back,</span>
           
         </div>
         
       </Col>
       <span className="username1">
             {dashboardData?.user?.username || ''}
        </span>
       <Col md={6} xs={12}>
       <div className="wallet-container">
           <div className="">
             <p className="balance_title">Balance</p>
             <div className="balance">
               {parseFloat(dashboardData?.wallet?.balance).toLocaleString('en-NG', {
                 style: 'currency',
                 currency: 'NGN',
               })}
             </div>
           </div>
           <div className='plus' 
                onClick={() => {
                   console.log('Topup button clicked');
                   history('/TopUp');
                 }}>
             <FontAwesomeIcon name='plus' icon={faPlus} size={28} color="white" />
           </div>
         </div>
       </Col>
        <Col>
          <div>
            <FloatingText text={latestNotification} />
          </div>
        </Col>

        
        <Col xs={12}>
          <div className="services-container">
            {services.map((item, index) => (
              <div
                key={index}
                className="service-item"
                onClick={() => {
                  navigationFunction(index);
                  setActiveIndex(index);
                }}
              >
                <div className='service'>
                  <div className='service_icon_wrapper'>
                    <img src={item.icon} alt="Service Icon" className='service_icon_wrapper'/>
                  </div>
                  <p className='service_name'>{item.name}</p>
                </div>
              </div>
            ))}
          </div>
        </Col>

        <MyCarousel />

        <Col xs={12}>
          <div className="classict" >
            <div className="transaction-container">
            <h3 className="recent_transactions">Recent Transactions</h3>
            <span>
              <Link to="/Transactions" className='see_more'>See more...</Link>
            </span>
            </div>
        

            <Col xs={12}>
                {dashboardData?.recent_transactions?.slice(0, 6).map((transaction, index) => (
              <div key={index} className="mt-3">
                 <Col md={10}>
                   <div className='t-container'>
                     <p>{transaction.short_desc || ''}</p>
                     <p className='t3'>{formatDate(transaction.createdAt)}</p>
                    <div className={`amount ${(() => {
                       if (transaction.type === 'debit') {
                          if (transaction.status === 'completed' || transaction.status === 'failed') {
                          return 'red';
                        } else if (transaction.status === 'pending') {
                          return 'orange';
                        }
                      } else if (transaction.type === 'credit') {
                      if (transaction.status === 'completed') {
                         return 'green';
                        } else if (transaction.status === 'pending') {
                         return 'orange';
                        }
                      }
                        return 'black'; // Default color if none of the conditions match
                      })()}`}>
                    {transaction.type === 'debit' && transaction.status === 'success' ? '-' : ''}
                    {transaction.type === 'credit' && transaction.status === 'success' ? '+' : ''}
                    {parseFloat(transaction.amount).toLocaleString('en-NG', {
                      style: 'currency',
                      currency: 'NGN',
                    })}
                    </div>
                   </div>
                  </Col>
              </div>
             ))}
          </Col>
          </div>
        </Col>
        <div className='download'>
          <h1>Download Our Mobile Apps</h1>
          <p>
You can download our mobile app from the Google Playstore if you are an android user and from the Apple Store if you are an IOS user.

Do well to reach out to us via our customer care number and via our email address from the contact below
          </p>
        </div>
    </Container>
    <Footer />
    </div>
   
  );
};

export default Home;




