import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Login from './OnboardingPage';
import GiftCards from './Pages/GiftCards';
import Otp from './Pages/Otp';
import ResetPassword from './Pages/ResetPassword';
import Signup from './Pages/Signup';
import Transactions from './Pages/Transactions';
import Airtime from './Pages/home/Airtime';
import Bet from './Pages/home/Bet';
import Bills from './Pages/home/Bills';
import BuyData from './Pages/home/BuyData';
import Home from './Pages/home/Home';
import Tv from './Pages/home/Tv';
import Education from './Pages/home/Education';
import Contact from './Pages/profile/Contact';
import EditProfile from './Pages/profile/EditProfile';
import NotificationScreen from './Pages/profile/NotificationsScreen';
import Profile from './Pages/profile/Profile';
import Refferal from './Pages/profile/Refferal';
import AppDojah from './Pages/home/Verification';
import TopUp from './Pages/send/TopUp';
import LogoComponent from './components/LogoComponent';
import ForeignAirtime from './Pages/home/ForeignAirtime';
import axios from './axios-config'; 

function App() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let inactivityTimeout;

    const resetApp = () => {
      // Navigate to the loading screen when inactive for 2 minutes
      navigate('/');
      setLoading(false);
    };

    const startInactivityTimer = () => {
      inactivityTimeout = setTimeout(resetApp, 60 * 60 * 1000); // 2 minutes in milliseconds
    };

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimeout);
      startInactivityTimer();
    };

    // Start the inactivity timer on component mount
    startInactivityTimer();

    // Reset the inactivity timer on user activity (e.g., mouse movement, key press)
    window.addEventListener('mousemove', resetInactivityTimer);
    window.addEventListener('keypress', resetInactivityTimer);

    // Clear the timer and remove event listeners on component unmount
    return () => {
      clearTimeout(inactivityTimeout);
      window.removeEventListener('mousemove', resetInactivityTimer);
      window.removeEventListener('keypress', resetInactivityTimer);
    };
  }, [navigate]);

  useEffect(() => {
    // Simulate loading for 6 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 10000);

    // Clear the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
        <Routes>
          {loading ? (
            // Render LogoComponent during loading
            <Route path="/" element={<LogoComponent />} />
          ) : (
            // Render Login component after loading
            <Route path="/" element={<Login />} />
          )}
          <Route path="/home" element={<Home />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/Airtime" element={<Airtime />} />
          <Route path="/Bills" element={<Bills />} />
          <Route path="/BuyData" element={<BuyData />} />
          <Route path="/Bet" element={<Bet />} />
          <Route path="/Tv" element={<Tv />} />
          <Route path="/GiftCards" element={<GiftCards />} />
          <Route path="/Transactions" element={<Transactions />} />
          <Route path="/NotificationsScreen" element={<NotificationScreen />} />
          <Route path="/Otp" element={<Otp />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/TopUp" element={<TopUp />} />
          <Route path="/Refferal" element={<Refferal />} />
          <Route path="/EditProfile" element={<EditProfile />} />
          <Route path="/Education" element={<Education />} />
          <Route path="/ForeignAirtime" element={<ForeignAirtime />} />
          <Route path="/idverification" element={<AppDojah />} />
          
        </Routes>
    </div>
  );
}

export default App;

