import axios from 'axios';
import { Formik } from 'formik';
import React, {  useCallback,useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
    Amx,
    Bestrate2,
    Target,
    amazon, apple, aud, avatar, binance, cad, eaplay, ebay, euro,
    footlocker,
    freefire,
    gbp, giftCard, googleplay, itunes, logo, macys, netflix, nike, nordstrom, nzd, othergiftcards, psn, razergold, sephora, steam, usa, vanilla, xbox
} from '../assets/exports';
import CountryFlag from 'react-country-flag';
import { showError, showSuccessThenAction } from '../components/Error';
import { AntDesignIcons, EntypoIcon } from '../components/Icons';
import { Textfield } from '../components/exports';
import Btn from '../components/Btn';
import { Header } from '../components/Header';
import Footer from '../components/Footer';
import '../css/Giftcards.css';
import { Container, Col } from 'react-bootstrap';
import Banner from '../components/Banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

const bet = [
    {
        bet: 'Amazon',
        img: amazon,
    },
    {
        bet: 'Apple',
        img: apple,
    },
    {
        bet: 'Nordstrom',
        img: nordstrom,
    },
    {
        bet: 'Steam',
        img: steam,
    },
    {
        bet: 'Google Play',
        img: googleplay,
    },
    {
        bet: 'Macys',
        img: macys,
    },
    {
        bet: 'eBay',
        img: ebay,
    },
    {
        bet: 'Nike',
        img: nike,
    },
    {
        bet: 'Footlocker',
        img: footlocker,
    },
    {
        bet: 'Razer Gold',
        img: razergold,
    },
    {
        bet: 'Sephora',
        img: sephora,
    },
    {
        bet: 'Xbox',
        img: xbox,
    },
    {
        bet: 'Target',
        img: Target,
    },
    {
        bet: 'Amx 3779',
        img: Amx,
    },
    {
        bet: 'Vanilla',
        img: vanilla,
    },
    {
        bet: 'Others',
        img: othergiftcards,
    }
];

const bills_arr = [
    {
        img: giftCard,
        name: 'Buy Giftcards',
    },
    {
        img: giftCard,
        name: 'Sell Giftcards',
    },
];

const currencies = [
    {
        name: 'USD',
        img: usa,
    },
    {
        name: 'EUR',
        img: euro,
    },
    {
        name: 'GBP',
        img: gbp,
    },
    {
        name: 'CAD',
        img: cad,
    },
    {
        name: 'AUD',
        img: aud,
    },
    {
        name: 'NZD',
        img: nzd,
    },
];


const buybet = [
  {
    isoName: "AF",
    name: "Afghanistan",
    currencyCode: "AFN",
    currencyName: "Afghan Afghani",
    flagUrl: "https://s3.amazonaws.com/rld-flags/af.svg"
  },
  {
    isoName: "AL",
    name: "Albania",
    currencyCode: "ALL",
    currencyName: "Albanian Lek",
    flagUrl: "https://s3.amazonaws.com/rld-flags/al.svg"
  },
  {
    isoName: "DZ",
    name: "Algeria",
    currencyCode: "DZD",
    currencyName: "Algerian Dinar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/dz.svg"
  },
  {
    isoName: "AS",
    name: "American Samoa",
    currencyCode: "USD",
    currencyName: "US Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/as.svg"
  },
  {
    isoName: "AO",
    name: "Angola",
    currencyCode: "AOA",
    currencyName: "Angolan Kwanza",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ao.svg"
  },
  {
    isoName: "AI",
    name: "Anguilla",
    currencyCode: "XCD",
    currencyName: "East Caribbean Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ai.svg"
  },
  {
    isoName: "AG",
    name: "Antigua and Barbuda",
    currencyCode: "XCD",
    currencyName: "East Caribbean Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ag.svg"
  },
  {
    isoName: "AR",
    name: "Argentina",
    currencyCode: "ARS",
    currencyName: "Argentine Peso",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ar.svg"
  },
  {
    isoName: "AM",
    name: "Armenia",
    currencyCode: "AMD",
    currencyName: "Armenian Dram",
    flagUrl: "https://s3.amazonaws.com/rld-flags/am.svg"
  },
  {
    isoName: "AW",
    name: "Aruba",
    currencyCode: "AWG",
    currencyName: "Aruban Florin",
    flagUrl: "https://s3.amazonaws.com/rld-flags/aw.svg"
  },
  {
    isoName: "BS",
    name: "Bahamas",
    currencyCode: "BSD",
    currencyName: "Bahamian Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/bs.svg"
  },
  {
    isoName: "BH",
    name: "Bahrain",
    currencyCode: "BHD",
    currencyName: "Bahraini Dinar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/bh.svg"
  },
  {
    isoName: "BD",
    name: "Bangladesh",
    currencyCode: "BDT",
    currencyName: "Bangladeshi Taka",
    flagUrl: "https://s3.amazonaws.com/rld-flags/bd.svg"
  },
  {
    isoName: "BB",
    name: "Barbados",
    currencyCode: "BBD",
    currencyName: "Barbadian Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/bb.svg"
  },
  {
    isoName: "BZ",
    name: "Belize",
    currencyCode: "BZD",
    currencyName: "Belize Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/bz.svg"
  },
  {
    isoName: "BJ",
    name: "Benin",
    currencyCode: "XOF",
    currencyName: "CFA Franc BCEAO",
    flagUrl: "https://s3.amazonaws.com/rld-flags/bj.svg"
  },
  {
    isoName: "BM",
    name: "Bermuda",
    currencyCode: "BMD",
    currencyName: "Bermudan Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/bm.svg"
  },
  {
    isoName: "BO",
    name: "Bolivia",
    currencyCode: "BOB",
    currencyName: "Bolivian Boliviano",
    flagUrl: "https://s3.amazonaws.com/rld-flags/bo.svg"
  },
  {
    isoName: "BW",
    name: "Botswana",
    currencyCode: "BWP",
    currencyName: "Botswanan Pula",
    flagUrl: "https://s3.amazonaws.com/rld-flags/bw.svg"
  },
  {
    isoName: "BR",
    name: "Brazil",
    currencyCode: "BRL",
    currencyName: "Brazilian Real",
    flagUrl: "https://s3.amazonaws.com/rld-flags/br.svg"
  },
  {
    isoName: "VG",
    name: "British Virgin Islands",
    currencyCode: "USD",
    currencyName: "US Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/vg.svg"
  },
  {
    isoName: "BF",
    name: "Burkina Faso",
    currencyCode: "XOF",
    currencyName: "CFA Franc BCEAO",
    flagUrl: "https://s3.amazonaws.com/rld-flags/bf.svg"
  },
  {
    isoName: "BI",
    name: "Burundi",
    currencyCode: "BIF",
    currencyName: "Burundian Franc",
    flagUrl: "https://s3.amazonaws.com/rld-flags/bi.svg"
  },
  {
    isoName: "KH",
    name: "Cambodia",
    currencyCode: "USD",
    currencyName: "US Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/kh.svg"
  },
  {
    isoName: "CM",
    name: "Cameroon",
    currencyCode: "XAF",
    currencyName: "CFA Franc BEAC",
    flagUrl: "https://s3.amazonaws.com/rld-flags/cm.svg"
  },
  {
    isoName: "CA",
    name: "Canada",
    currencyCode: "CAD",
    currencyName: "Canadian Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ca.svg"
  },
  {
    isoName: "CV",
    name: "Cape Verde",
    currencyCode: "CVE",
    currencyName: "Cape Verdean Escudo",
    flagUrl: "https://s3.amazonaws.com/rld-flags/cv.svg"
  },
  {
    isoName: "KY",
    name: "Cayman Islands",
    currencyCode: "KYD",
    currencyName: "Cayman Islands Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ky.svg"
  },
  {
    isoName: "CF",
    name: "Central African Republic",
    currencyCode: "XAF",
    currencyName: "CFA Franc BEAC",
    flagUrl: "https://s3.amazonaws.com/rld-flags/cf.svg"
  },
  {
    isoName: "CL",
    name: "Chile",
    currencyCode: "CLP",
    currencyName: "Chilean Peso",
    flagUrl: "https://s3.amazonaws.com/rld-flags/cl.svg"
  },
  {
    isoName: "CN",
    name: "China",
    currencyCode: "CNY",
    currencyName: "Chinese Yuan",
    flagUrl: "https://s3.amazonaws.com/rld-flags/cn.svg"
  },
  {
    isoName: "CO",
    name: "Colombia",
    currencyCode: "COP",
    currencyName: "Colombian Peso",
    flagUrl: "https://s3.amazonaws.com/rld-flags/co.svg"
  },
  {
    isoName: "CG",
    name: "Congo",
    currencyCode: "XAF",
    currencyName: "CFA Franc BEAC",
    flagUrl: "https://s3.amazonaws.com/rld-flags/cg.svg"
  },
  {
    isoName: "CR",
    name: "Costa Rica",
    currencyCode: "CRC",
    currencyName: "Costa Rican Colón",
    flagUrl: "https://s3.amazonaws.com/rld-flags/cr.svg"
  },
  {
    isoName: "CI",
    name: "Côte d'Ivoire",
    currencyCode: "XOF",
    currencyName: "CFA Franc BCEAO",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ci.svg"
  },
  {
    isoName: "CU",
    name: "Cuba",
    currencyCode: "CUP",
    currencyName: "Cuban Peso",
    flagUrl: "https://s3.amazonaws.com/rld-flags/cu.svg"
  },
  {
    isoName: "CY",
    name: "Cyprus",
    currencyCode: "EUR",
    currencyName: "Euro",
    flagUrl: "https://s3.amazonaws.com/rld-flags/cy.svg"
  },
  {
    isoName: "DM",
    name: "Dominica",
    currencyCode: "XCD",
    currencyName: "East Caribbean Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/dm.svg"
  },
  {
    isoName: "DO",
    name: "Dominican Republic",
    currencyCode: "DOP",
    currencyName: "Dominican Peso",
    flagUrl: "https://s3.amazonaws.com/rld-flags/do.svg"
  },
  {
    isoName: "EC",
    name: "Ecuador",
    currencyCode: "USD",
    currencyName: "US Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ec.svg"
  },
  {
    isoName: "EG",
    name: "Egypt",
    currencyCode: "EGP",
    currencyName: "Egyptian Pound",
    flagUrl: "https://s3.amazonaws.com/rld-flags/eg.svg"
  },
  {
    isoName: "SV",
    name: "El Salvador",
    currencyCode: "USD",
    currencyName: "US Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/sv.svg"
  },
  {
    isoName: "ET",
    name: "Ethiopia",
    currencyCode: "ETB",
    currencyName: "Ethiopian Birr",
    flagUrl: "https://s3.amazonaws.com/rld-flags/et.svg"
  },
  {
    isoName: "FJ",
    name: "Fiji",
    currencyCode: "FJD",
    currencyName: "Fijian Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/fj.svg"
  },
  {
    isoName: "FR",
    name: "France",
    currencyCode: "EUR",
    currencyName: "Euro",
    flagUrl: "https://s3.amazonaws.com/rld-flags/fr.svg"
  },
  {
    isoName: "GM",
    name: "Gambia",
    currencyCode: "GMD",
    currencyName: "Gambian Dalasi",
    flagUrl: "https://s3.amazonaws.com/rld-flags/gm.svg"
  },
  {
    isoName: "GE",
    name: "Georgia",
    currencyCode: "GEL",
    currencyName: "Georgian Lari",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ge.svg"
  },
  {
    isoName: "DE",
    name: "Germany",
    currencyCode: "EUR",
    currencyName: "Euro",
    flagUrl: "https://s3.amazonaws.com/rld-flags/de.svg"
  },
  {
    isoName: "GH",
    name: "Ghana",
    currencyCode: "GHS",
    currencyName: "Ghanaian Cedi",
    flagUrl: "https://s3.amazonaws.com/rld-flags/gh.svg"
  },
  {
    isoName: "GD",
    name: "Grenada",
    currencyCode: "XCD",
    currencyName: "East Caribbean Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/gd.svg"
  },
  {
    isoName: "GT",
    name: "Guatemala",
    currencyCode: "GTQ",
    currencyName: "Guatemalan Quetzal",
    flagUrl: "https://s3.amazonaws.com/rld-flags/gt.svg"
  },
  {
    isoName: "GN",
    name: "Guinea Conakry",
    currencyCode: "GNF",
    currencyName: "Guinean Franc",
    flagUrl: "https://s3.amazonaws.com/rld-flags/gn.svg"
  },
  {
    isoName: "GW",
    name: "Guinea-Bissau",
    currencyCode: "XOF",
    currencyName: "CFA Franc BCEAO",
    flagUrl: "https://s3.amazonaws.com/rld-flags/gw.svg"
  },
  {
    isoName: "GY",
    name: "Guyana",
    currencyCode: "GYD",
    currencyName: "Guyanaese Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/gy.svg"
  },
  {
    isoName: "HT",
    name: "Haiti",
    currencyCode: "HTG",
    currencyName: "Haitian Gourde",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ht.svg"
  },
  {
    isoName: "HN",
    name: "Honduras",
    currencyCode: "HNL",
    currencyName: "Honduran Lempira",
    flagUrl: "https://s3.amazonaws.com/rld-flags/hn.svg"
  },
  {
    isoName: "IN",
    name: "India",
    currencyCode: "INR",
    currencyName: "Indian Rupee",
    flagUrl: "https://s3.amazonaws.com/rld-flags/in.svg"
  },
  {
    isoName: "ID",
    name: "Indonesia",
    currencyCode: "IDR",
    currencyName: "Indonesian Rupiah",
    flagUrl: "https://s3.amazonaws.com/rld-flags/id.svg"
  },
  {
    isoName: "IQ",
    name: "Iraq",
    currencyCode: "IQD",
    currencyName: "Iraqi Dinar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/iq.svg"
  },
  {
    isoName: "IT",
    name: "Italy",
    currencyCode: "EUR",
    currencyName: "Euro",
    flagUrl: "https://s3.amazonaws.com/rld-flags/it.svg"
  },
  {
    isoName: "JM",
    name: "Jamaica",
    currencyCode: "JMD",
    currencyName: "Jamaican Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/jm.svg"
  },
  {
    isoName: "JO",
    name: "Jordan",
    currencyCode: "JOD",
    currencyName: "Jordanian Dinar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/jo.svg"
  },
  {
    isoName: "KZ",
    name: "Kazakhstan",
    currencyCode: "KZT",
    currencyName: "Kazakhstani Tenge",
    flagUrl: "https://s3.amazonaws.com/rld-flags/kz.svg"
  },
  {
    isoName: "KE",
    name: "Kenya",
    currencyCode: "KES",
    currencyName: "Kenyan Shilling",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ke.svg"
  },
  {
    isoName: "KW",
    name: "Kuwait",
    currencyCode: "KWD",
    currencyName: "Kuwaiti Dinar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/kw.svg"
  },
  {
    isoName: "KG",
    name: "Kyrgyzstan",
    currencyCode: "KGS",
    currencyName: "Kyrgystani Som",
    flagUrl: "https://s3.amazonaws.com/rld-flags/kg.svg"
  },
  {
    isoName: "LA",
    name: "Laos",
    currencyCode: "LAK",
    currencyName: "Laotian Kip",
    flagUrl: "https://s3.amazonaws.com/rld-flags/la.svg"
  },
  {
    isoName: "LB",
    name: "Lebanon",
    currencyCode: "LBP",
    currencyName: "Lebanese Pound",
    flagUrl: "https://s3.amazonaws.com/rld-flags/lb.svg"
  },
  {
    isoName: "LR",
    name: "Liberia",
    currencyCode: "USD",
    currencyName: "US Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/lr.svg"
  },
  {
    isoName: "MK",
    name: "Macedonia",
    currencyCode: "MKD",
    currencyName: "Macedonian Denar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/mk.svg"
  },
  {
    isoName: "MG",
    name: "Madagascar",
    currencyCode: "MGA",
    currencyName: "Malagasy Ariary",
    flagUrl: "https://s3.amazonaws.com/rld-flags/mg.svg"
  },
  {
    isoName: "MW",
    name: "Malawi",
    currencyCode: "MWK",
    currencyName: "Malawian Kwacha",
    flagUrl: "https://s3.amazonaws.com/rld-flags/mw.svg"
  },
  {
    isoName: "MY",
    name: "Malaysia",
    currencyCode: "MYR",
    currencyName: "Malaysian Ringgit",
    flagUrl: "https://s3.amazonaws.com/rld-flags/my.svg"
  },
  {
    isoName: "ML",
    name: "Mali",
    currencyCode: "XOF",
    currencyName: "CFA Franc BCEAO",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ml.svg"
  },
  {
    isoName: "MQ",
    name: "Martinique",
    currencyCode: "EUR",
    currencyName: "Euro",
    flagUrl: "https://s3.amazonaws.com/rld-flags/mq.svg"
  },
  {
    isoName: "MX",
    name: "Mexico",
    currencyCode: "MXN",
    currencyName: "Mexican Peso",
    flagUrl: "https://s3.amazonaws.com/rld-flags/mx.svg"
  },
  {
    isoName: "MD",
    name: "Moldova",
    currencyCode: "MDL",
    currencyName: "Moldovan Leu",
    flagUrl: "https://s3.amazonaws.com/rld-flags/md.svg"
  },
  {
    isoName: "MA",
    name: "Morocco",
    currencyCode: "MAD",
    currencyName: "Moroccan Dirham",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ma.svg"
  },
  {
    isoName: "MZ",
    name: "Mozambique",
    currencyCode: "MZN",
    currencyName: "Mozambican Metical",
    flagUrl: "https://s3.amazonaws.com/rld-flags/mz.svg"
  },
  {
    isoName: "MM",
    name: "Myanmar",
    currencyCode: "MMK",
    currencyName: "Myanma Kyat",
    flagUrl: "https://s3.amazonaws.com/rld-flags/mm.svg"
  },
  {
    isoName: "NA",
    name: "Namibia",
    currencyCode: "NAD",
    currencyName: "Namibian Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/na.svg"
  },
  {
    isoName: "NR",
    name: "Nauru",
    currencyCode: "AUD",
    currencyName: "Australian Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/nr.svg"
  },
  {
    isoName: "NP",
    name: "Nepal",
    currencyCode: "NPR",
    currencyName: "Nepalese Rupee",
    flagUrl: "https://s3.amazonaws.com/rld-flags/np.svg"
  },
  {
    isoName: "NL",
    name: "Netherlands",
    currencyCode: "EUR",
    currencyName: "Euro",
    flagUrl: "https://s3.amazonaws.com/rld-flags/nl.svg"
  },
  {
    isoName: "AN",
    name: "Netherlands Antilles",
    currencyCode: "ANG",
    currencyName: "Netherlands Antillean Guilder",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ant.svg\n"
  },
  {
    isoName: "NI",
    name: "Nicaragua",
    currencyCode: "NIO",
    currencyName: "Nicaraguan Córdoba",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ni.svg"
  },
  {
    isoName: "NE",
    name: "Niger",
    currencyCode: "XOF",
    currencyName: "CFA Franc BCEAO",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ne.svg"
  },
  {
    isoName: "NG",
    name: "Nigeria",
    currencyCode: "NGN",
    currencyName: "Nigerian Naira",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ng.svg"
  },
  {
    isoName: "OM",
    name: "Oman",
    currencyCode: "OMR",
    currencyName: "Omani Rial",
    flagUrl: "https://s3.amazonaws.com/rld-flags/om.svg"
  },
  {
    isoName: "PK",
    name: "Pakistan",
    currencyCode: "PKR",
    currencyName: "Pakistani Rupee",
    flagUrl: "https://s3.amazonaws.com/rld-flags/pk.svg"
  },
  {
    isoName: "PS",
    name: "Palestine",
    currencyCode: "ILS",
    currencyName: "Israeli New Sheqel",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ps.svg"
  },
  {
    isoName: "PA",
    name: "Panama",
    currencyCode: "USD",
    currencyName: "US Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/pa.svg"
  },
  {
    isoName: "PG",
    name: "Papua New Guinea",
    currencyCode: "PGK",
    currencyName: "Papua New Guinean Kina",
    flagUrl: "https://s3.amazonaws.com/rld-flags/pg.svg"
  },
  {
    isoName: "PY",
    name: "Paraguay",
    currencyCode: "PYG",
    currencyName: "Paraguayan Guarani",
    flagUrl: "https://s3.amazonaws.com/rld-flags/py.svg"
  },
  {
    isoName: "PE",
    name: "Peru",
    currencyCode: "PEN",
    currencyName: "Peruvian Nuevo Sol",
    flagUrl: "https://s3.amazonaws.com/rld-flags/pe.svg"
  },
  {
    isoName: "PH",
    name: "Philippines",
    currencyCode: "PHP",
    currencyName: "Philippine Peso",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ph.svg"
  },
  {
    isoName: "PL",
    name: "Poland",
    currencyCode: "PLN",
    currencyName: "Polish Zloty",
    flagUrl: "https://s3.amazonaws.com/rld-flags/pl.svg"
  },
  {
    isoName: "PT",
    name: "Portugal",
    currencyCode: "EUR",
    currencyName: "Euro",
    flagUrl: "https://s3.amazonaws.com/rld-flags/pt.svg"
  },
  {
    isoName: "PR",
    name: "Puerto Rico",
    currencyCode: "USD",
    currencyName: "US Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/pr.svg"
  },
  {
    isoName: "QA",
    name: "Qatar",
    currencyCode: "QAR",
    currencyName: "Qatari Rial",
    flagUrl: "https://s3.amazonaws.com/rld-flags/qa.svg"
  },
  {
    isoName: "RO",
    name: "Romania",
    currencyCode: "EUR",
    currencyName: "Euro",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ro.svg"
  },
  {
    isoName: "RU",
    name: "Russia",
    currencyCode: "RUB",
    currencyName: "Russian Ruble",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ru.svg"
  },
  {
    isoName: "RW",
    name: "Rwanda",
    currencyCode: "RWF",
    currencyName: "Rwandan Franc",
    flagUrl: "https://s3.amazonaws.com/rld-flags/rw.svg"
  },
  {
    isoName: "KN",
    name: "Saint Kitts And Nevis",
    currencyCode: "XCD",
    currencyName: "East Caribbean Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/kn.svg"
  },
  {
    isoName: "LC",
    name: "Saint Lucia",
    currencyCode: "XCD",
    currencyName: "East Caribbean Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/lc.svg"
  },
  {
    isoName: "VC",
    name: "Saint Vincent And The Grenadines",
    currencyCode: "XCD",
    currencyName: "East Caribbean Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/vc.svg"
  },
  {
    isoName: "WS",
    name: "Samoa",
    currencyCode: "WST",
    currencyName: "Samoan Tala",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ws.svg"
  },
  {
    isoName: "SA",
    name: "Saudi Arabia",
    currencyCode: "SAR",
    currencyName: "Saudi Riyal",
    flagUrl: "https://s3.amazonaws.com/rld-flags/sa.svg"
  },
  {
    isoName: "SN",
    name: "Senegal",
    currencyCode: "XOF",
    currencyName: "CFA Franc BCEAO",
    flagUrl: "https://s3.amazonaws.com/rld-flags/sn.svg"
  },
  {
    isoName: "SL",
    name: "Sierra Leone",
    currencyCode: "SLL",
    currencyName: "Sierra Leonean Leone",
    flagUrl: "https://s3.amazonaws.com/rld-flags/sl.svg"
  },
  {
    isoName: "SG",
    name: "Singapore",
    currencyCode: "SGD",
    currencyName: "Singapore Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/sg.svg"
  },
  {
    isoName: "ZA",
    name: "South Africa",
    currencyCode: "ZAR",
    currencyName: "South African Rand",
    flagUrl: "https://s3.amazonaws.com/rld-flags/za.svg"
  },
  {
    isoName: "ES",
    name: "Spain",
    currencyCode: "EUR",
    currencyName: "Euro",
    flagUrl: "https://s3.amazonaws.com/rld-flags/es.svg"
  },
  {
    isoName: "LK",
    name: "Sri Lanka",
    currencyCode: "LKR",
    currencyName: "Sri Lankan Rupee",
    flagUrl: "https://s3.amazonaws.com/rld-flags/lk.svg"
  },
  {
    isoName: "SR",
    name: "Suriname",
    currencyCode: "SRD",
    currencyName: "Surinamese Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/sr.svg"
  },
  {
    isoName: "SZ",
    name: "Eswatini",
    currencyCode: "SZL",
    currencyName: "Swazi Lilangeni",
    flagUrl: "https://s3.amazonaws.com/rld-flags/sz.svg"
  },
  {
    isoName: "TJ",
    name: "Tajikistan",
    currencyCode: "TJS",
    currencyName: "Tajikistani Somoni",
    flagUrl: "https://s3.amazonaws.com/rld-flags/tj.svg"
  },
  {
    isoName: "TZ",
    name: "Tanzania",
    currencyCode: "TZS",
    currencyName: "Tanzanian Shilling",
    flagUrl: "https://s3.amazonaws.com/rld-flags/tz.svg"
  },
  {
    isoName: "TH",
    name: "Thailand",
    currencyCode: "THB",
    currencyName: "Thai Baht",
    flagUrl: "https://s3.amazonaws.com/rld-flags/th.svg"
  },
  {
    isoName: "CD",
    name: "The Democratic Republic Of Congo",
    currencyCode: "USD",
    currencyName: "US Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/cd.svg"
  },
  {
    isoName: "TG",
    name: "Togo",
    currencyCode: "XOF",
    currencyName: "CFA Franc BCEAO",
    flagUrl: "https://s3.amazonaws.com/rld-flags/tg.svg"
  },
  {
    isoName: "TO",
    name: "Tonga",
    currencyCode: "TOP",
    currencyName: "Tongan Pa?anga",
    flagUrl: "https://s3.amazonaws.com/rld-flags/to.svg"
  },
  {
    isoName: "TT",
    name: "Trinidad and Tobago",
    currencyCode: "TTD",
    currencyName: "Trinidad and Tobago Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/tt.svg"
  },
  {
    isoName: "TN",
    name: "Tunisia",
    currencyCode: "TND",
    currencyName: "Tunisian Dinar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/tn.svg"
  },
  {
    isoName: "TR",
    name: "Turkey",
    currencyCode: "TRY",
    currencyName: "Turkish Lira",
    flagUrl: "https://s3.amazonaws.com/rld-flags/tr.svg"
  },
  {
    isoName: "TC",
    name: "Turks And Caicos Islands",
    currencyCode: "USD",
    currencyName: "US Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/tc.svg"
  },
  {
    isoName: "UG",
    name: "Uganda",
    currencyCode: "UGX",
    currencyName: "Ugandan Shilling",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ug.svg"
  },
  {
    isoName: "UA",
    name: "Ukraine",
    currencyCode: "UAH",
    currencyName: "Ukrainian Hryvnia",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ua.svg"
  },
  {
    isoName: "AE",
    name: "United Arab Emirates",
    currencyCode: "AED",
    currencyName: "United Arab Emirates Dirham",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ae.svg"
  },
  {
    isoName: "GB",
    name: "United Kingdom",
    currencyCode: "GBP",
    currencyName: "British Pound Sterling",
    flagUrl: "https://s3.amazonaws.com/rld-flags/gb.svg"
  },
  {
    isoName: "US",
    name: "United States",
    currencyCode: "USD",
    currencyName: "US Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/us.svg"
  },
  {
    isoName: "UY",
    name: "Uruguay",
    currencyCode: "UYU",
    currencyName: "Uruguayan Peso",
    flagUrl: "https://s3.amazonaws.com/rld-flags/uy.svg"
  },
  {
    isoName: "UZ",
    name: "Uzbekistan",
    currencyCode: "UZS",
    currencyName: "Uzbekistan Som",
    flagUrl: "https://s3.amazonaws.com/rld-flags/uz.svg"
  },
  {
    isoName: "VU",
    name: "Vanuatu",
    currencyCode: "VUV",
    currencyName: "Vanuatu Vatu",
    flagUrl: "https://s3.amazonaws.com/rld-flags/vu.svg"
  },
  {
    isoName: "VN",
    name: "Vietnam",
    currencyCode: "VND",
    currencyName: "Vietnamese Dong",
    flagUrl: "https://s3.amazonaws.com/rld-flags/vn.svg"
  },
  {
    isoName: "YE",
    name: "Yemen",
    currencyCode: "YER",
    currencyName: "Yemeni Rial",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ye.svg"
  },
  {
    isoName: "ZM",
    name: "Zambia",
    currencyCode: "ZMW",
    currencyName: "ZMW",
    flagUrl: "https://s3.amazonaws.com/rld-flags/zm.svg"
  },
  {
    isoName: "ZW",
    name: "Zimbabwe",
    currencyCode: "ZWL",
    currencyName: "Zimbabwean Dollar (2009)",
    flagUrl: "https://s3.amazonaws.com/rld-flags/zw.svg"
  },
  {
    isoName: "VE",
    name: "Venezuela",
    currencyCode: "VEF",
    currencyName: "Bolivar Fuerte",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ve.svg"
  },
  {
    isoName: "GR",
    name: "Greece",
    currencyCode: "EUR",
    currencyName: "Euro",
    flagUrl: "https://s3.amazonaws.com/rld-flags/gr.svg"
  },
  {
    isoName: "SO",
    name: "Somalia",
    currencyCode: "USD",
    currencyName: "US Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/so.svg"
  },
  {
    isoName: "BY",
    name: "Belarus",
    currencyCode: "BYN",
    currencyName: "Belarusian ruble",
    flagUrl: "https://s3.amazonaws.com/rld-flags/by.svg"
  },
  {
    isoName: "KM",
    name: "Comoros",
    currencyCode: "KMF",
    currencyName: "Comorian Franc",
    flagUrl: "https://s3.amazonaws.com/rld-flags/km.svg"
  },
  {
    isoName: "MR",
    name: "Mauritania",
    currencyCode: "MRU",
    currencyName: "Mauritanian Ouguiya",
    flagUrl: "https://s3.amazonaws.com/rld-flags/mr.svg"
  },
  {
    isoName: "BE",
    name: "Belgium",
    currencyCode: "EUR",
    currencyName: "Euro",
    flagUrl: "https://s3.amazonaws.com/rld-flags/be.svg"
  },
  {
    isoName: "MS",
    name: "Montserrat",
    currencyCode: "XCD",
    currencyName: "East Caribbean Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ms.svg"
  },
  {
    isoName: "KR",
    name: "South Korea",
    currencyCode: "KRW",
    currencyName: "South Korean Won",
    flagUrl: "https://s3.amazonaws.com/rld-flags/kr.svg"
  },
  {
    isoName: "IE",
    name: "Ireland",
    currencyCode: "EUR",
    currencyName: "Euro",
    flagUrl: "https://s3.amazonaws.com/rld-flags/ie.svg"
  },
  {
    isoName: "FI",
    name: "Finland",
    currencyCode: "EUR",
    currencyName: "Euro",
    flagUrl: "https://s3.amazonaws.com/rld-flags/fi.svg"
  },
  {
    isoName: "AU",
    name: "Australia",
    currencyCode: "AUD",
    currencyName: "Australian Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/au.svg"
  },
  {
    isoName: "AT",
    name: "Austria",
    currencyCode: "EUR",
    currencyName: "Euro",
    flagUrl: "https://s3.amazonaws.com/rld-flags/at.svg"
  },
  {
    isoName: "JP",
    name: "Japan",
    currencyCode: "JPY",
    currencyName: "Japanese Yen",
    flagUrl: "https://s3.amazonaws.com/rld-flags/jp.svg"
  },
  {
    isoName: "HK",
    name: "Hong Kong",
    currencyCode: "HKD",
    currencyName: "Hong Kong Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/hk.svg"
  },
  {
    isoName: "TW",
    name: "Taiwan",
    currencyCode: "TWD",
    currencyName: "Taiwan Dollar",
    flagUrl: "https://s3.amazonaws.com/rld-flags/tw.svg"
  }
]

const drops = ['E-code', 'Physical-card'];

export default function GiftCards({ navigation }) {
    const [activeBill, setActiveBill] = useState(0);
  const [isBuying, setIsBuying] = useState(false);
  const [isSelling, setIsSelling] = useState(false);
  const [giftCardCountries, setGiftCardCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [active, setActive] = useState(0);
  const [show, setShow] = useState(false);
  const [toggleCheckBox, setToggleCheckBox] = useState(false);
  const darkMode = useSelector((state) => state?.common?.darkMode);
  const [loaded, setLoaded] = useState(false);
  const [drop, setDrop] = useState(drops[0]);
  const [dropActive, setDropActive] = useState(false);
  const token = useSelector((state) => state?.user?.token);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(drops[0]);
  const [GiftCardProducts, setGiftCardProducts] = useState([]);
  const [selectedGiftCardIndex, setSelectedGiftCardIndex] = useState(null);
  const amountFieldRef = useRef(null);
  const [quantity, setQuantity] = useState(1);
  const [loadingGiftCards, setLoadingGiftCards] = useState(true);
  const user = useSelector((state) => state?.user?.userDetails);
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [searchQuery, setSearchQuery] = useState('');
  const [search, setSearch] = useState('');
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [giftcardRate, setGiftcardRate] = useState([]);// Add this state variable


console.log(user);
  const [selectedAmountItem, setSelectedAmountItem] = useState(null);


  const handleAmountItemSelection = (amountItem) => {
    setSelectedAmountItem(amountItem);
  };

  const usdtotalAmount = selectedAmountItem * quantity;
  const totalAmount = (usdtotalAmount * giftcardRate)
  
  useEffect(() => {
    // This block will be executed after the state has been updated
    console.log(selectedAmountItem);
  }, [selectedAmountItem]);


  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  console.log(quantity);
  



  const handleBuyAction = () => {
    setActiveBill(0);
    setIsBuying(!isBuying);
    setIsSelling(false);
  };

  const handleSellAction = () => {
    setActiveBill(1);
    setIsSelling(!isSelling);
    setIsBuying(false);
  };


  


  const [productDetails, setProductDetails] = useState({
    vet: '',
    img: '',
    id: '',
    amount: [],
  });

  const handleGiftCardSelection = async (index) => {
    setSelectedGiftCardIndex(index);
  
    // Use the ref to scroll to the amount field
  
    try {
      // Fetch product details using the selected gift card's productId
      const selectedGiftCardProductId = giftCards[index]?.id;
      if (selectedGiftCardProductId) {
        const { data } = await axios.get(
          `https://dev.api.teepay.site/service/get-giftcardsbyid?productId=${selectedGiftCardProductId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (data) {
            // Assuming 'data' contains the product details
            const productDetails = {
              vet: data.productName,
              img: data.logoUrls,
              id: data.productId,
              type: data.denominationType,
              amount: data.fixedRecipientDenominations,
            };
  
          // Set the product details in your state or do whatever you need with it
          setProductDetails(productDetails);
          console.log(productDetails.amount);

          if (amountFieldRef.current) {
            amountFieldRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    } catch (error) {
      console.log(error, 'Error fetching product details');
    }
  };

  const [giftCards, setGiftCards] = useState([]);

    // ... (rest of your component)


    useEffect(() => {
        const fetchGiftCards = async () => {
          try {
            const { data } = await axios.get(`https://dev.api.teepay.site/service/get-giftcards?countryCode=${buybet?.[active]?.isoName.toUpperCase()}`, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
    
            console.log(data, 'success retrieveing data plans');
            if (data) {
                setGiftCardProducts(data);

                const GiftcardProducts = data.map(giftcard => ({
                    vet: giftcard.productName, // Assuming the country object has a 'name' property
                    img: giftcard.logoUrls,
                    id: giftcard.productId,
                    amount: giftcard.fixedRecipientDenominations,
                    // You need to define a function to get the image based on the country
                  }));
                  setGiftCards(GiftcardProducts);
            }
          } catch (error) {
            console.log(error, 'yutes');
          } finally {
            // Set loadingGiftCards to false when the gift cards are loaded
            setLoadingGiftCards(false);
          }
        };
    
        if (token && buybet[active]?.isoName) {
          console.log('it can fire now');
          fetchGiftCards();
        }
      }, [token, buybet, active]);
  
  // Run the effect whenever the 'token' changes

  // Function to get the image based on the country (adjust this according to your actual data structure)
 
    

  const handleDropSelection = (selectedOption) => {
    setLoadingGiftCards(true);
    setSelectedOption(selectedOption);
    // Set loadingGiftCards to true when the drop-down selection changes
   
  };


    const [selectedCurrency, setSelectedCurrency] = useState('');

    const [selectedImage, setSelectedImage] = useState(null);
    const [imageBase64, setImageBase64] = useState(null);

    const [currency, setCurrency] = useState('');

    const openImagePicker = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';

        input.addEventListener('change', (event) => {
            const file = event.target.files && event.target.files[0];

            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const selectedImage = {
                        base64: reader.result?.toString() || null,
                        type: file.type || null,
                        uri: URL.createObjectURL(file) || null,
                    };
                    console.log('Selected Image:', selectedImage);
                    setImageBase64(selectedImage.base64);
                    setSelectedImage(selectedImage);
                };
                reader.readAsDataURL(file);
            }
        });

        input.click();
    };

    const handleAction = () => {
        navigation.navigate('app', { screen: 'app', key: Date.now() });
    };

    const buyGiftCard = async (values) => {
        try {
            setLoading(true);
            const url = 'https://dev.api.teepay.site/service/buy-giftcards';
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const reqBody = {
              "payment_method": "wallet",
              "service": "Giftscard",
              "customerIdentifier": user.body.user.full_name,
              "productId": productDetails.id,
              "recipientEmail": user.body.user.email,
              "quantity": quantity,
              "senderName": user.body.user.full_name,
              "unitPrice": usdtotalAmount
            };

            console.log(reqBody);

            if (values?.coupon) {
                reqBody.coupon = values?.coupon;
            }

            console.log(reqBody, 'reqBody');

            const res = await axios.post(url, reqBody, { headers });
            showSuccessThenAction('Gift card buy successfully Initiated', handleAction);
            console.log(res);
        } catch (error) {
            showError('There was an issue processing your order, please bare with us', error);
        } finally {
            setLoading(false);
        }
    };


    const debouncedBuyGiftCard = useCallback(
      _.debounce(async (values) => {
        setIsSubmitting(true);
        await buyGiftCard(values);
        setIsSubmitting(false);
      }, 1000), // Adjust the debounce delay as needed
      [buyGiftCard]
    );

    const sellGiftCard = async (values) => {
        setLoading(true);
        const url = 'https://dev.api.teepay.site/service/sell-giftcard';
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const reqBody = {
            "provider": buybet[active].bet.toUpperCase(),
            "type": drops[active],
            "currency": currency,
            "amount": parseFloat(values.amount),
            "transactions": values?.transactions,
            "image": imageBase64,
        };

        if (values?.coupon) {
            reqBody.coupon = values?.coupon;
        }
        console.log(reqBody, 'reqBody');

        try {
            const res = await axios.post(url, reqBody, { headers });
            showSuccessThenAction('Gift card sell has been successfully initiated', handleAction);
            console.log(res);
        } catch (error) {
            console.error('Error in sellGiftCard:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Start of Tawk.to Script
        var Tawk_API = Tawk_API || {},
          Tawk_LoadStart = new Date();
        (function () {
          var s1 = document.createElement('script'),
            s0 = document.getElementsByTagName('script')[0];
          s1.async = true;
          s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
          s1.charset = 'UTF-8';
          s1.setAttribute('crossorigin', '*');
          s0.parentNode.insertBefore(s1, s0);
        })();
        // End of Tawk.to Script
    
        // Clean up the script when the component unmounts
        return () => {
          // Add any cleanup code here, if necessary
        };
      }, []); // Empty depe



      const filteredGiftCards = giftCards.filter(giftCard =>
        giftCard.vet.toLowerCase().includes(searchQuery.toLowerCase())
      );
    
      const handleSearch = (query) => {
        setSearch(query);
        if (!query) {
          setFilteredCountries([]);
        } else {
          const filtered = buybet.filter((country) =>
            country.name.toLowerCase().includes(query.toLowerCase())
          );
          setFilteredCountries(filtered);
        }
      };
      
      
      const handleSelectCountry = (index) => {
        setShow(false);
        const selectedCountry = search ? filteredCountries[index] : buybet[index];
        const activeIndex = buybet.findIndex((country) => country.name === selectedCountry.name);
        setActive(activeIndex);
        setLoadingGiftCards(true);
        setSearch('');
        setFilteredCountries([]);
      };


      useEffect(() => {
        const getGiftcardRate = async () => {
            try {
                const { data } = await axios.get(`https://dev.api.teepay.site/service/giftcard-rates`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
    
                console.log(data, 'success retrieving rate');
                if (data && data.body && data.body.rates && data.body.rates.length > 0) {
                    const rate = data.body.rates[0].rate; // Assuming rates is an array with one object containing the rate
                    setGiftcardRate(rate);
                }
            } catch (error) {
                console.log(error, 'error fetching gift card rate');
                // Handle error
            }
        };
    
        if (token) {
            console.log('it can fire now');
            getGiftcardRate();
        }
    }, [token]);

    return (
        <div>
            <Header />
       
        <Container>
            <div className=''>
                <Col>
                  
                </Col>

                <div className="">
                    <h1>Giftcards</h1>
                    
                </div>

                <div>
      <Banner imageUrl={Bestrate2} altText="topup" />
      </div>

                <div className="bills_container">
                    {bills_arr.map((_, i) => (
                        <div
                            key={i}
                            className={activeBill === i ? "bill_active" : "bill"}
                            onClick={() => setActiveBill(i)}
                        >
                            <img src={_.img} className="bill_img" alt={_.name} />
                            <p className={activeBill === i ? "bill_p_active" : "bill_p"}>{_.name}</p>
                        </div>
                    ))}
                </div>
                <>
  <div style={{ marginTop: 30 }}>
    <h4>Giftcard Country</h4>
  </div>

  <div className="select" onClick={() => setShow(!show)}>
    {activeBill === 1 ? (
      <img
        src={bet[active].img}
        className="select_img"
        alt={bet[active].bet}
      />
    ) : null}
    {activeBill === 0 ? (
      <CountryFlag
        countryCode={buybet[active].isoName}
        svg
        style={{ width: '25px', height: 'auto' }}
      />
    ) : null}
    <p>{activeBill === 0 ? buybet[active].name : bet[active].bet}</p>
  </div>

  {show && (
    <div
      className="dropdown_wrapper"
      style={{ backgroundColor: darkMode ? '#202020' : '' }}
    >
      <input
        type="text"
        placeholder="Search for a country"
        value={search}
        onChange={(e) => handleSearch(e.target.value)}
        style={{
          backgroundColor: darkMode ? '#303030' : '#FFFFFF',
          color: darkMode ? '#FFFFFF' : '#000000',
          padding: '8px 10px',
          marginBottom: 10,
          borderRadius: 5,
          border: `1px solid ${darkMode ? '#666' : '#999'}`,
        }}
      />

      {(filteredCountries.length > 0
        ? filteredCountries
        : activeBill === 0
        ? buybet
        : bet
      ).map((_, i) => (
        <div
          className="dropdown_item"
          key={i}
          onClick={() => {
            setShow(!show);
            handleSelectCountry(i); // Pass the country object to handleSelectCountry
            setLoadingGiftCards(true);
          }}
        >
          {activeBill === 0 ? (
            <CountryFlag
              countryCode={_.isoName}
              svg
              style={{ width: '25px', height: 'auto' }}
            />
          ) : null}
          <p>{_.name}</p>
        </div>
      ))}
    </div>
  )}
</>



                {activeBill === 1 ? (
                    <>
                        <div className="drop" onClick={() => setDropActive(!dropActive)}>
                            <p style={{ color: 'white' }}>{drop}</p>
                            <FontAwesomeIcon icon={faChevronDown}  color="#25D366" />
                            
                        </div>

                        {dropActive && (
                            <div className="drop_list">
                                {drops.map((_, i) => (
                                    <div
                                        key={i}
                                        className={drop === _ ? "drop_list_item_ACTIVE" : "drop_list_item"}
                                        onClick={() => {
                                            setDrop(_);
                                            setDropActive(!dropActive);
                                            
                                        }}
                                    >
                                        <p style={{ color: 'black' }}>{_}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                ) : null}

{activeBill === 0 ? (
<div style={{ marginTop: 20, marginBottom: 10 }}>

    <h4>Currency</h4>
    <div className="currency_container">
            <div className="currency_wrapper">
                <img src={usa} className="currency_img" alt={buybet?.[active]?.bet} />
                <p className="currency">USD</p>
            </div>
    </div>
</div> ): null }


{activeBill === 0 ? (
  <div style={{ marginTop: 20, marginBottom: 10 }}>
    <h4 style={{ marginBottom: 50 }}>Available Giftcards in {buybet[active].name}</h4>

    <input
      type="text"
      placeholder={`Search for a GiftCard in ${buybet?.[active]?.bet}`}
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      style={{
        backgroundColor: darkMode ? '#303030' : '#FFFFFF',
        color: darkMode ? '#FFFFFF' : '#000000',
        padding: '8px 10px',
        marginBottom: 10,
        borderRadius: 5,
        border: `1px solid ${darkMode ? '#666' : '#999'}`,
      }}
    />

    {searchQuery && filteredGiftCards.length === 0 ? (
      <p className='select_text'>No matching gift cards found</p>
    ) : (
      <div className="giftcard_container">
        {(searchQuery ? filteredGiftCards : giftCards).map((giftCard, index, originalIndex) => (
          <div
            key={index}
            className={`giftcard_item ${selectedGiftCardIndex === index ? 'selected' : ''}`}
            onClick={() => handleGiftCardSelection(index)}
            style={searchQuery && filteredGiftCards.length > 0 && filteredGiftCards[0].vet !== giftCard.vet ? { display: 'none' } : null}
          >
            <img src={giftCard.img} className="giftcard_img" alt={giftCard.vet} />
            <p className="giftcard_name">{giftCard.vet}</p>
          </div>
        ))}
      </div>
    )}

    {loadingGiftCards ? (
      <p className='select_text'>Loading Giftcards.....</p>
    ) : null}
  </div>
) : null}


      <Formik
  initialValues={{ amount: '', transactions: '', coupon: '' }}
  onSubmit={(values) => {
    if (activeBill === 0) {
      buyGiftCard(values);
    } else {
      sellGiftCard(values);
    }
  }}
>

{(formikProps) => (
 
    <div>
      {selectedGiftCardIndex !== null && (
        <div ref={amountFieldRef}>
         {activeBill === 0 ? (
          <div>
            <h4>
              Available GiftCard Amounts for {giftCards[selectedGiftCardIndex]?.vet}
            </h4>
            <div className="giftcard_container1">
              {productDetails?.amount?.map((amountItem, i) => (
                <div
                  key={i}
                  className={`giftcard_item1 ${selectedGiftCardIndex === i ? 'selected' : ''}`}
                  onClick={() => handleAmountItemSelection(amountItem)}
                >
                  <img src={usa} className="currency_img" alt='usd' />
      <div className="giftcard1_text">
        <p className="giftcard_usd">USD</p>
        <p className="giftcard_amountitem">{amountItem}</p>
        </div>
                </div>
              ))}
            </div> 
          </div>  ) : null }
        </div>  
      )}

{activeBill === 0 ? (
<div className="quantity-container">
        <p className="quantity-label">Quantity:</p>
        <div className="quantity-controls">
          <button
            className="quantity-button"
            onClick={decrementQuantity}
            disabled={quantity === 1}
          >
            -
          </button>
          <p className="quantity-value">{quantity}</p>
          <button
            className="quantity-button"
            onClick={incrementQuantity}
          >
            +
          </button>
        </div>
      </div> ) : null }

      {activeBill === 0 ? (
     <div>
     <h4> Giftcard Invoice Summary</h4>

     <p>Giftcard Name: {giftCards[selectedGiftCardIndex]?.vet}</p>
     <p>Giftcard Country: {buybet?.[active]?.bet} </p>
     <p>Giftcard Amount: {usdtotalAmount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
     <p>Quantity:{quantity}</p>
     <p>Amount to be debited: {totalAmount.toLocaleString('en-NG', { style: 'currency', currency: 'NGN' })}</p>
     </div> )
      : null }


{activeBill === 1 ? (
      <Textfield name='transactions' title='Transactions Details' style={{ marginTop: 0 }} />  ) : null}
      <Textfield name='coupon' title='Coupon' style={{ marginTop: 0 }} />

      {activeBill === 0 ? null : (
        <div style={{ marginTop: 30 }}>
          <div style={{ marginTop: 10, marginBottom: 20 }}>
            <p className="bill_p">
              Please upload an image of your Giftcard.
              Note that E-code users should take a screenshot of their e-code and upload an image.
              Include all of the necessary information you will like the team to know in the Transaction Details.
            </p>
          </div>

          <div className="upload" onClick={openImagePicker}>
            <p className="upload_p">Upload</p>
          </div>

          {selectedImage && (
            <img
              src={selectedImage.uri}
              className="selectedImage"
              alt="Selected Giftcard"
            />
          )}
        </div>
      )}


{activeBill === 0 && (
        <Btn
          text='Buy'
          onClick={async () => {
            // The onSubmit function will conditionally call debouncedBuyGiftCard if activeBill is 0.
            // This will pass the form values to the function.
            debouncedBuyGiftCard(await formikProps.handleSubmit());
          }}
          loading={loading || isSubmitting}
        />
      )}

{activeBill === 1 && (
        <Btn
          text='Sell'
          onClick={async () => {
            // The onSubmit function will conditionally call debouncedBuyGiftCard if activeBill is 0.
            // This will pass the form values to the function.
            formikProps.handleSubmit();
          }}
          loading={loading || isSubmitting}
        />
      )}
    </div>
)}
</Formik>


            </div>
            <Col>
              
            </Col>
        </Container>
        <Footer />
        </div>
    );
}
