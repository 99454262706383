import axios from 'axios';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Topup,
  avatar,
  bet9ja,
  betking,
  logo,
  sportybet
} from '../../assets/exports';
import { showError, showSuccessThenAction } from '../../components/Error';
import { AntDesignIcons, EntypoIcon } from '../../components/Icons';
import  Btn  from '../../components/Btn';
import { Textfield } from '../../components/exports';
import { Header } from '../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
} from 'react-bootstrap';
import Footer from '../../components/Footer';
import '../../css/Buydata.css';
import Banner from '../../components/Banner';
import { useNavigate } from 'react-router-dom';


const BuyData = ({ navigation }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ['CORPORATE GIFTING', 'SME', 'SME2', 'GIFTING'];
  const history = useNavigate();

  const handleAction = () => {
    history('/Home'); // Adjust the route as needed
  };

  const data_bundles = [
    {
      bundle: '200 MB',
      price: 3300,
      duration: '3 days',
    },
    {
      bundle: '700GB',
      price: 5200,
      duration: '3 days',
    },
    {
      bundle: '1TB',
      price: 300,
      duration: '1 day',
    },
    {
      bundle: '50 MB',
      price: 100,
      duration: '3 day',
    },
    {
      bundle: '5 GB',
      price: 1200,
      duration: '3 days',
    },
    {
      bundle: '10 GB',
      price: 700,
      duration: '3 days',
    },
  ];

  const darkMode = useSelector((state) => state?.common?.darkMode);
  const [dataPlans, setDataPlans] = useState([]);
  const [dataPlantype, setDataPlantype] = useState([]);

  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state?.user?.token);

  const drops = [
    'Airtel', 'Glo', 'Mtn', '9mobile',
  ];

  const [dropActive, setDropActive] = useState(false);
  const [drop, setDrop] = useState(drops[0]);

  const [selectedDataTab, setSelectedDataTab] = useState('Data Bundles');

  const handleDataTabChange = (tab) => {
    setSelectedDataTab(tab);
  };


  const filteredDataPlans = dataPlans.filter((plan) => {
    const isMTN = drop.toUpperCase() === 'MTN';
    const isAirtel = drop === "Airtel";
    const isGlo = drop === "Glo";
    const is9mobile = drop === "9mobile";
  
    if (isMTN && activeTab === 0) {
      // Display dataplans with IDs from 270 to 282 for MTN and tab 0
      return plan?.plan_type === 'CORPORATE GIFTING'
    }

    if (isMTN && activeTab === 1) {
      // Display dataplans with IDs from 270 to 282 for MTN and tab 0
      return plan?.plan_type === 'SME'
    }

    if (isMTN && activeTab === 2) {
      // Display dataplans with IDs from 270 to 282 for MTN and tab 0
      return plan?.plan_type === 'SME2'
    }

    if (isMTN && activeTab === 3) {
      // Display dataplans with IDs from 270 to 282 for MTN and tab 0
      return plan?.plan_type === 'GIFTING'
    }

    if (isAirtel && activeTab === 0) {
      // Display dataplans with IDs from 270 to 282 for MTN and tab 0
      return plan?.plan_type === 'CORPORATE GIFTING'
    }


    if (isGlo && activeTab === 0) {
      // Display dataplans with IDs from 270 to 282 for MTN and tab 0
      return plan?.plan_type === 'CORPORATE GIFTING'
    }

    if (is9mobile && activeTab === 0) {
      // Display dataplans with IDs 323 to 329, 353, and 354 for 9mobile and tab 0
      return plan?.plan_type === 'CORPORATE GIFTING'
    }
  });
  

  useEffect(() => {
    const getProductPlans = async () => {
      try {
        const { data } = await axios.get(`https://dev.api.teepay.site/service/product-plans?service=data&provider=${drop.toUpperCase()}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log(data?.body?.plans, 'success retrieveing data plans');
        if (data) {
          setDataPlans(data?.body?.plans);
          setDataPlantype(data?.body?.plans?._?.plan_type);

          setDataPlantype(data?.body?.plans?.map(plan => plan.plan_type));

        }
      } catch (error) {
        console.log(error, 'yutes');
        showError(error?.response?.data);
      }
    };

    if (token) {
      console.log('it can fire now');
      getProductPlans();
    }
  }, [drop]);

  const [choosenDataPlan, setChoosenDataPlan] = useState({});
  const [coupon, setCoupon] = useState('');
  console.log(choosenDataPlan, 'choosenDataPlan');

  const buyData = async (values) => {
    setLoading(true);
    const req_body = {
      "payment_method": "wallet",
      "service": "data",
      "provider": drop.toUpperCase(),
      "receiver": values?.phoneNumber,
      "service_plan_ref": choosenDataPlan?.plan,
      
    };

    if (values?.coupon) {
      req_body.coupon = values?.coupon;
    }

    console.log(req_body, 'req_body')

    try {
      const res = await axios.post('https://dev.api.teepay.site/service/purchase', req_body, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      console.log(res.data);
      setLoading(false);
      showSuccessThenAction('Data has been bought successfully', handleAction);
      setChoosenDataPlan({});
    } catch (error) {
      setLoading(false);
      showError(error.response.data?.message);
      console.log(error.response.data, 'error.response.data');
    }
  };

  useEffect(() => {
    // Start of Tawk.to Script
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    // End of Tawk.to Script

    // Clean up the script when the component unmounts
    return () => {
      // Add any cleanup code here, if necessary
    };
  }, []); // Empty depe

  return (
    <div>
      <Header />

    <Container>
      <Col>
        
      </Col>

      <div className='header'>
        <h1>Data Bundle</h1>
      </div>
      <div>
      <Banner imageUrl={Topup} altText="topup" />
      </div>
     

      <Formik initialValues={{ phoneNumber: '', amount: '', coupon: '' }} onSubmit={(values) => buyData(values)}>
        {({ handleSubmit }) => (
          <>
            <div className=''>
              <Textfield
                name='phoneNumber'
                title='Phone No'
                style={{ marginTop: 0 }}
                keyboardType="number-pad"
              />
            </div>

            <div className='text-center'>
              <p className='warning'>Please Ensure to select the right Network to avoid loss of funds</p>
            </div>

            <div className=''>
              <p style={{ color: 'black' }}>Network</p>
              <div className='drop' onClick={() => setDropActive(!dropActive)}>
                <p>{drop}</p>
                <FontAwesomeIcon icon={faChevronDown}  color="#25D366" />
              </div>
            </div>

            {dropActive &&
              <div className='drop_list'>
                {drops.map((_, i) => (
                  <div
                    key={i}
                    className={drop === _ ? 'drop_list_item_ACTIVE' : 'drop_list_item'}
                    onClick={() => {
                      setDrop(_);
                      setDropActive(!dropActive);
                    }}
                  >
                    <p>{_}</p>
                  </div>
                ))}
              </div>
            }

<div className='tabs'>
  {drop.toUpperCase() === 'MTN' ? (
    // Render all tabs if dropActive is 2
    tabs.map((_, i) => (
      <div
        key={i}
        className={activeTab === i ? 'tab_active' : 'tab'}
        onClick={() => {
          setActiveTab(i);
          handleDataTabChange(_);
        }}
      >
        <p className={activeTab === i ? 'tab_text_active' : 'tab_text_inactive'}>
          {_}
        </p>
      </div>
    ))
  ) : (
    // Render only the first tab if dropActive is not 2
    <div
      className={activeTab === 0 ? 'tab_active' : 'tab'}
      onClick={() => {
        setActiveTab(0);
        handleDataTabChange(tabs[0]);
      }}
    >
      <p className={activeTab === 0 ? 'tab_text_active' : 'tab_text_inactive'}>
        {tabs[0]}
      </p>
    </div>
  )}
</div>

            <div className='data_bundle_container text-center'>
  {filteredDataPlans.length === 0 ? (
    <p className='select_text'>Loading Bundles.....</p>
  ) : (
    filteredDataPlans.map((_, i) => {
      const dataPlan = _?.short_desc?.split('for');
      const plantype = _?.plan_type;
      const isSelected = choosenDataPlan === _;

      return (
        <div
          key={i}
          className={`data_bundle_item ${isSelected ? 'selected' : ''}`}
          onClick={() => setChoosenDataPlan(_)}
        >
          <div className='data_bundle_item_v1'>
            <p className={`data_price ${isSelected ? 'selected' : ''}`}>{dataPlan[0]}</p>
          </div>
          <div className='data_bundle_item_v2'>
            <p className={`data_bundle ${isSelected ? 'selected' : ''}`}>
              {parseFloat(_?.amount).toLocaleString('en-NG', { style: 'currency', currency: 'NGN' })}
            </p>
            <p className={`data_duration ${isSelected ? 'selected' : ''}`}>{dataPlan[1]}</p>
          </div>
        </div>
      );
    })
  )}
</div>


            <div className=''>
              <Textfield
                name='coupon'
                value={coupon}
                title='Coupon'
                style={{ marginTop: 0 }}
              />
            </div>

            <Btn text='Proceed' className='btn' onClick={() => {
              handleSubmit();
            }} loading={loading} />
          </>
        )}
      </Formik>

      <Col>
       
      </Col>
    </Container>
     <Footer />
    </div>
  );
};

export default BuyData;
