// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user.js'; // Update this path

const store = configureStore({
  reducer: {
    user: userReducer,
    // Add other reducers if you have them
  },
  // Other store configurations if needed
});

export default store;
