import React, { useEffect, useRef } from 'react';
import '../../css/styles.css'; // Import the CSS file for styling

const FloatingText = ({ text }) => {
  const textRef = useRef(null);

  useEffect(() => {
    const moveText = () => {
      const textElement = textRef.current;

      if (textElement) {
        const animationDuration = 20000; // Adjust the duration as needed
        const distance = window.innerWidth + textElement.offsetWidth;

        textElement.style.transform = `translateX(${distance}px)`;
        textElement.style.transition = `transform ${animationDuration}ms linear`;

        // Reset the position after the animation completes
        setTimeout(() => {
          textElement.style.transform = 'translateX(-100%)';
          textElement.style.transition = 'none';
          moveText(); // Repeat the animation
        }, animationDuration);
      }
    };

    moveText(); // Start the animation

    return () => {
      // Clean up on component unmount
      const textElement = textRef.current;
      if (textElement) {
        textElement.style.transform = 'none';
        textElement.style.transition = 'none';
      }
    };
  }, []);

  return (
    <div className="floating-text" ref={textRef}>
      {text}
    </div>
  );
};

export default FloatingText;
