import React, { useEffect, useRef, useState } from 'react';
import Dojah from 'react-dojah';
import { Link, useNavigate } from 'react-router-dom';


const AppDojah = ({route}) => {
  const navigation = useNavigate();
  const appID = '65ded764dd3ad4003f2bc422';
  //const { userName }  = route.params; // Destructure the userName from params
    
  const reference_id = 'userName' + 'teepay';
  console.log(reference_id, 'usernameid');
  const publicKey = 'prod_pk_xHnqM7MlIdoEdRt3SAlAoja1D';
  const type = "custom";

  const config = {
  	widget_id: "65dee81bdd3ad4003f2bd9e5",
    pages: [], //this is generated from easyonboard 
  };


  const metadata = {
    user_id: {reference_id},
  };
 

  /**
   * @param {String} type
   * This method receives the type
   * The type can only be one of:
   * loading, begin, success, error, close
   * @param {String} data
   * This is the data from doja
   */
  const response = (type, data) => {
    console.log(type, data);
    if(type === 'success'){
      navigation('/')
    }else if(type === 'error'){
      navigation('/')
    }else if(type === 'close'){
      navigation('/')
    }else if(type === 'begin'){
    }else if(type === 'loading'){
    }
  }

  return (
    <Dojah
      response={response}
      appID={appID}
      publicKey={publicKey}
      type={type}
      config={config}
      metadata={metadata}
    />
  );
}

export default AppDojah