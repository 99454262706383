import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userDetails: {},
  token: localStorage.getItem('token') || '',
  bankDetails: {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    saveBankDetails: (state, action) => {
      state.bankDetails = action.payload;
    },
    deleteUserDetails: (state) => {
      state.userDetails = {}; // Corrected from state.bankDetails to state.userDetails
    },
    deleteBankDetails: (state) => {
      state.bankDetails = {};
    },
    saveToken: (state, { payload }) => {
      state.token = payload;
      localStorage.setItem('token', payload);
    },
  },
});

export const { saveUserDetails, saveToken, deleteUserDetails, saveBankDetails, deleteBankDetails } = userSlice.actions;

export default userSlice.reducer;