// ContactForm.js
import React ,{ useState} from 'react';
import { Formik } from 'formik';
import  Textfield  from './Textfield2';
import Btn from './Btn';
import { Alert, Container, Col} from 'react-bootstrap';


const ContactForm = () => {
  const sendEmail = (values) => {
    const { subject, phone, message } = values;
  
    // Construct the mailto URI
    const mailtoURI = `mailto:teepay000@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(`Phone: ${phone}\n\n${message}`)}`;
  
    // Open the default email app
    window.location.href = mailtoURI;
  };
  const [loading, setLoading] = useState(false);
  

  return (
    <div>
    <Container>
      <h1>Contact Us</h1>
      <Formik
        initialValues={{
          subject: '',
          phone: '',
          message: '',
        }}
        onSubmit={(values) => {
          const body = {
            subject: values.subject,
            phone: values.phone,
            message: values.message,
          };
          setLoading(true);

          sendEmail(body);
        }}
      >
        {({ handleSubmit }) => (
          <div>
            <Col className='text-left mb-3'> <span style={{ color: 'white' }}>Subject</span></Col>
           
            <Textfield name="subject" title="Subject" icon="user" />
            <Col className='text-left mb-3'> <span style={{ color: 'white' }}>Phone</span></Col>
            <Textfield name="phone" title="Phone" icon="email" />
            <Col className='text-left mb-3'> <span style={{ color: 'white' }} >Message</span></Col>
            <Textfield name="message" title="Message" />

           <Btn
                  variant="primary"
                  text=  'Send'
                  type="submit"
                  style={{ marginTop: '30px' }}
                  className="btn"
                  onClick={() => {
                    console.log('Login button clicked');
                    handleSubmit();
                  }}
                  disabled={loading}
                />
          </div>
        )}
      </Formik>
    </Container>
    </div>
  );
}

export default ContactForm;


