import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AntDesignIcons } from '../../components/Icons';
import { styles as sx, width } from '../../helpers/styles';
import { Header } from '../../components/Header';
import { logo } from '../../assets/exports';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
} from 'react-bootstrap';
import Footer from '../../components/Footer';
import '../../css/NotificationScreen.css';

    
export default function NotificationsScreen({ navigation }) {
  const darkMode = useSelector((state) => state?.common?.darkMode);

  const token = useSelector((state) => state?.user?.token); // Get the authorization token

  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status
  

  // Function to fetch notifications from the API
  const fetchNotifications = async () => {
    try {

        const response = await axios.get('https://dev.api.teepay.site/notification?limit=20&offset=0', {
           headers: {
            Authorization: `Bearer ${token}`,
           },
         }
          );

        if (response.status === 200) {
          // Assuming the API response contains an array of notifications
          // You can modify this based on the actual API response structure
          console.log('API Response:', response.data.body.notifications);
          const last20Notifications = response.data.body.notifications.slice(-20); // Get the last 20 notifications
          setNotifications(last20Notifications);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      } finally {
        // Set loading to false when fetching is done
        setLoading(false);
      }
    };

    

    const handleRefresh = () => {
      // Call the fetchNotifications function to manually refresh
      fetchNotifications();
    };

  useEffect(() => {
    // Fetch notifications when the component mounts
    fetchNotifications();
  }, []);

  useEffect(() => {
    // Start of Tawk.to Script
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    // End of Tawk.to Script

    // Clean up the script when the component unmounts
    return () => {
      // Add any cleanup code here, if necessary
    };
  }, []); // Empty depe
      
  return (
    <div>
      <Header />
    <Container>

      <Col>
        
      </Col>

        <div>
          <div className='header'>
            <h1>Notifications</h1>
          </div>

          {/* Manual Refresh Button */}
        <div onClick={handleRefresh}>
            <p className='refreshButton'>Refresh</p>
        </div>

        {loading ? (
          <p>Loading Notifications .....</p>
         ) : (
            notifications.map((notification, index) => (
                <div key={index} className='notificationItem'>
                  <div className='notificationContent'>
                     <img
                       src={logo}
                       className='notificationItemlogo'
                      />
                      <div className='notificationText'>
                          <p className='subject'>{notification.subject}</p>
                          <p className='message'>{notification.message}</p>
                          <p className='createdAt'>{new Date(notification.createdAt).toLocaleDateString('en-US', {
                                                         year: 'numeric',
                                                         month: 'long',
                                                         day: 'numeric',
                                                         hour: '2-digit',
                                                         minute: '2-digit',
                                       })}
                          </p>
                      </div>
                  </div>
                        
                </div>
            ))
          )}

        </div>
        <Col>
          
        </Col>
    </Container>
    <Footer />
    </div>
  );
}