import axios from 'axios';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  Container,
  Row
} from 'react-bootstrap';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import Banner from '../../components/Banner';
import Btn from '../../components/Btn';
import { showError, showSuccessThenAction } from '../../components/Error';
import Footer from '../../components/Footer';
import { Header } from '../../components/Header';
import { Textfield } from '../../components/exports';
import '../../css/Tv.css';

import {
  TVimage,
  dstv,
  gotv,
  showmax,
  startimes
} from '../../assets/exports';
import { useNavigate } from 'react-router-dom';

const bet = [
  {
      bet: 'DSTV',
      img: dstv,
  },
  {
          bet: 'STARTIMES',
          img: startimes,
      },
  {
      bet: 'GOTV',
      img: gotv,
  },
  {
    bet: 'DSTVSHOWMAX',
    img: showmax,
},
]

export default function Tv({ navigation }) {
  const [active, setActive] = useState(0);
  const [show, setShow] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const tabs = ['Hot Offers'];

  const darkMode = useSelector((state) => state?.common?.darkMode);
  const [tvPlans, setTvPlans] = useState([]);
  const [selectedTvPlan, setSelectedTvPlan] = useState(null);
  const token = useSelector((state) => state?.user?.token);
  const [loading, setLoading] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const history = useNavigate();

  const formikRef = useRef(null);

  const handleFormSubmit = async (values) => {
    console.log(values);
    if (bet[active].bet.toUpperCase() === 'DSTVSHOWMAX') {
      ShowmaxSubscription(values);
    } else {
      verifySmartCardNumber(values);
    }  
  };

  const handlePay = () => {
    setShowModal(false);
    const values = formikRef.current.values;
  
    if (bet[active].bet.toUpperCase() === 'DSTVSHOWMAX') {
      ShowmaxSubscription(values);
    } else {
      tvSubscription(values);
    }
  };

  useEffect(() => {
    const getProductPlans = async () => {
      try {
        const { data } = await axios.get(`https://dev.api.teepay.site/service/product-plans?service=tv&provider=${bet[active].bet.toUpperCase()}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (data) {
          setTvPlans(data?.body?.plans);
        }
        console.log(data);
      } catch (error) {
        console.log(error, 'error getting data plans');
        showError(error?.response?.data?.message);
      }
    };

    if (token) {
      getProductPlans();
    }
  }, [active, token]);

  const verifySmartCardNumber = async (values) => {
    if (token) {
      setLoading(true);
      const url = `https://dev.api.teepay.site/service/verify-receiver`;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const reqBody = {
        "service": "tv",
        "provider": bet[active].bet,
        "receiver": values?.smartcard,
        
      };

      console.log(values?.smartcard);

      try {
        const res = await axios.post(url, reqBody, { headers });
        if (res) {
          const transactionData = res.data.body;
          setLoading(false);
          console.log(res.data, 'res');
          setTransactionDetails(transactionData);
          setShowModal(true);
        }
      } catch (error) {
        setLoading(false);
        showError(error?.response?.data?.message);
      }
    } else {
      showError('Unauthorized request');
    }
  };

  const tvSubscription = async (values) => {
    if (token) {
      setLoading(true);
      const url = `https://dev.api.teepay.site/service/purchase`;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const reqBody = {
        "payment_method": "wallet",
        "service": "tv",
        "provider": bet[active].bet,
        "receiver": values?.smartcard,
        "service_plan_ref": selectedTvPlan?.plan
      };

      if (values?.coupon) {
        reqBody.coupon = values?.coupon;
      }

      const handleAction = () => {
        history('/Home'); // Adjust the route as needed
      };

      try {
        const res = await axios.post(url, reqBody, { headers });
        if (res) {
          setLoading(false);
          { showSuccessThenAction('Tv Subscription has been bought successfully', handleAction)}
        }
      } catch (error) {
        setLoading(false);
        showError(error?.response?.data?.message);
      }
    } else {
      {showError('Unauthorized request')}
    }
  };



  const ShowmaxSubscription = async (values) => {
    if (token) {
      setLoading(true);
      const url = `https://dev.api.teepay.site/service/purchase`;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const reqBody = {
        "payment_method": "wallet",
        "service": "tv",
        "provider": bet[active].bet,
        "receiver": "1234",
        "service_plan_ref": selectedTvPlan?.plan,
      };

      if (values?.coupon) {
        reqBody.coupon = values?.coupon;
      }

      const handleAction = () => {
        history('/Home'); // Adjust the route as needed
      };

      try {
        const res = await axios.post(url, reqBody, { headers });
        if (res) {
          setLoading(false);
          { showSuccessThenAction('Tv Subscription has been bought successfully', handleAction)}
        }
      } catch (error) {
        setLoading(false);
        showError(error?.response?.data?.message);
      }
    } else {
      {showError('Unauthorized request')}
    }
  };

  useEffect(() => {
    // Start of Tawk.to Script
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    // End of Tawk.to Script

    // Clean up the script when the component unmounts
    return () => {
      // Add any cleanup code here, if necessary
    };
  }, []); // Empty depe

  return (
    <div>
      <Header />
    <Container>


      <Col>
        
      </Col>

      <div>
        <h1>CableTv Subscription</h1>
      </div>


      <div className='select' onClick={() => setShow(!show)}>
        <img src={bet[active].img} className='select_img' alt="Tv" />
        <span className='select_text'>{bet[active].bet}</span>
      </div>

      {show && (
        <div className='dropdown_wrapper'>
          {bet.map((_, i) => (
            <div
              className='dropdown_item'
              key={i}
              onClick={() => {
                setShow(!show);
                setActive(i);
              }}
            >
              <img src={_.img} className='select_img' alt="electricity-logo" />
              <p className='select_text'>{_.bet}</p>
            </div>
          ))}
        </div>
      )}

                <div>
                    <p>Please Note: A Fee of #150 will be charged CableTv Subscription purchase.</p>
                </div>

      <Formik
        innerRef={formikRef}
        initialValues={{ smartcard: '', coupon: '' }}
        onSubmit={(values) => { handleFormSubmit(values) }}
      >
        {
          ({ handleSubmit, setFieldValue, values }) => (
            <div>
              {bet[active].bet.toUpperCase() !== 'SHOWMAX' && (
        <Textfield
          name='smartcard'
          title='Smartcard Number'
          type="number"
          onChange={(e) => setFieldValue('smartcard', e.target.value)}
        />
      )}

              <div>
                {
                  tabs.map((_, i) => (
                    <div
                      key={i}
                      onClick={() => setActiveTab(i)}
                      className={activeTab === i ? 'tab tab_active' : 'tab'}
                    >
                      <span className={activeTab === i ? 'tab_p_inactive tab_p_active' : 'tab_p_inactive'}>
                        { _ }
                      </span>
                    </div>
                  ))
                }
              </div>
           <Row className='mt-3'>
           <div className='data_bundle_container text-center'>
  {tvPlans.length !== 0 &&
    <div className='tv_plans_wrapper'>
      {tvPlans.map((_, i) => {
        const tvPlanDescription = _.short_desc?.split('for'); // Split TV plan description
        return (
          <div
            key={i}
            onClick={() => {
              setSelectedTvPlan(_);
            }}
            className={`data_bundle_item ${selectedTvPlan === _ ? 'selected' : ''}`}
          >
            <div className='data_bundle_item_v1'>
              <p className={`data_price ${selectedTvPlan === _ ? 'selected' : ''}`}>
                {(_.amount).toLocaleString('en-NG', { style: 'currency', currency: 'NGN' })}
              </p>
            </div>
            <div className='data_bundle_item_v2'>
              <p className={`data_bundle ${selectedTvPlan === _ ? 'selected' : ''}`}>{tvPlanDescription[0]}</p>
              <p className={`data_duration ${selectedTvPlan === _ ? 'selected' : ''}`}>{tvPlanDescription[2]}</p>
            </div>
          </div>
        );
      })}
    </div>
  }
</div>
           </Row>

              <Textfield
                name='coupon'
                title='Coupon'
                onChange={(e) => setFieldValue('coupon', e.target.value)}
                value={values.coupon}
              />

<Btn
        variant="primary"
        text={loading ? 'Checking...' : (bet[active].bet.toUpperCase() === 'SHOWMAX' ? 'Pay' : 'Verify Smart Card')}
        type="submit"
        style={{ marginTop: '20px' }}
        className="btn"
        onClick={() => {
          console.log('Button clicked');
          handleSubmit();
        }}
        disabled={loading}
      />
            </div>
          )
        }
      </Formik>

      <ReactModal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <div className='modal-container'>
          <div>
            <p className='modalHeaderp'>
              - NGN {selectedTvPlan?.amount}
            </p>
          </div>
          <div>
            <div className='row'>
              <span className='value rightAligned'>Provider:</span>
              <span className='value leftAligned'>{bet[active].bet.toUpperCase()}</span>
            </div>
            <div className='row'>
              <span className='value rightAligned'>Full Name:</span>
              <span className='value leftAligned'>{transactionDetails?.fullname}</span>
            </div>
            <div className='row'>
              <span className='value rightAligned'>Smartcard Number:</span>
              <span className='value leftAligned'>{formikRef.current?.values?.smartcard}</span>
            </div>
            <div className='row'>
              <span className='value rightAligned'>Tv Plan:</span>
              <span className='value leftAligned'>{selectedTvPlan?.short_desc}</span>
            </div>
            {formikRef.current?.values?.coupon && (
              <div className='row'>
                <span className='value rightAligned'>Coupon:</span>
                <span className='value leftAligned'>{formikRef.current?.values?.coupon}</span>
              </div>
            )}
          </div>
        </div>
              <Btn
                  variant="primary"
                  text={loading ? 'Paying...' : 'Pay'}
                  type="submit"
                  style={{ marginTop: '20px' }}
                  className="btn"
                  onClick={() => {
                    console.log('Login button clicked');
                    handlePay();
                  }}
                  disabled={loading}
                />
      </ReactModal>
      <Col>
        
      </Col>
    </Container>
    <Footer />
    </div>
  );
}

