// Footer.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone, faClock } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';
import ContactForm from './ContactForm';
import { logo } from '../assets/exports';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>About Us</h3>
          <span>
            Teepay is a Bill Payment mobile application owned by TEEPAYEEDATA. We aim to bring cheaper and better bill payment methods to all of our users.
          </span>
        </div>

        <div className="footer-section">
        <ContactForm />
        </div>

        <div className="footer-section">
          <h3>Quick info</h3>
          <p><FontAwesomeIcon icon={faMapMarkerAlt} /> Akure South, Ondo, Nigeria</p>
          <p><FontAwesomeIcon icon={faEnvelope} /> teepay000@gmail.com</p>
          <p><FontAwesomeIcon icon={faWhatsapp} /> +2349063788634</p>
          <p><FontAwesomeIcon icon={faClock} /> 24/7</p>
        </div>

        <div className="footer-section">
  <h4>
    <a href="https://teepay.site/privacy-policy/" target="_blank" rel="noopener noreferrer">
      Terms and Conditions
    </a>
  </h4>
  {/* Add your Terms and Conditions content or link here */}
</div>
      </div>

      <div className="footer-bottom">
        <img src={logo} alt="Teepay Logo" className="footer-logo" />
        <span>&copy; 2023 Teepay. All rights reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;

