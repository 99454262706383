import React, { useState } from 'react';
import { useField } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faEye, faEyeSlash, faIdCard } from '@fortawesome/free-solid-svg-icons';
import './Textfield.css';  // Import the external CSS file

const Textfield = (props) => {
  const { name, title, icon, type, keyboardType, value, handleChange, onClick } = props;
  const [field, meta, helpers] = useField(name);
  const [visible, setVisible] = useState(false);

  return (
    <div className="textfield">
      <div>
        <div className="title_wrapper">
          <span className="title">{title}</span>
        </div>

        <input
          defaultValue={value}
          value={value ? value : field.value}
          onChange={(event) => handleChange ? handleChange(event.target.value) : helpers.setValue(event.target.value)}
          className="input"
          type={keyboardType || 'text'}
          autoComplete="off"
          autoCorrect={false}
          onClick={onClick} 
          type={type === 'password' ? (visible ? 'text' : 'password') : type}
        />
      </div>

      <div className="icon_wrapper" onClick={type === 'password' ? () => setVisible(!visible) : onClick }>
        {icon === 'user' && <FontAwesomeIcon icon={faUser} size="lg" color="#4C9049" />}
        {icon === 'email' && <FontAwesomeIcon icon={faEnvelope} size="lg" color="#4C9049" />}
        {icon === 'phone' && <FontAwesomeIcon icon={faPhone} size="lg" color="#4C9049" />}
        {type === 'password' && visible && <FontAwesomeIcon icon={faEye} size="lg" color="#4C9049" />}
        {type === 'password' && !visible && <FontAwesomeIcon icon={faEyeSlash} size="lg" color="#4C9049" />}
      </div>
      <span className="error">{meta.error}</span>
    </div>
  );
};

export { Textfield };







