// LogoComponent.js
import React, { useEffect, useState } from 'react';
import { logo } from '../assets/exports';
import './LogoComponent.css';

const LogoComponent = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay (e.g., 6 seconds) to show the loading animation
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 6000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className='body'>
    <div className={`logo-container ${loading ? 'loading' : ''}`}>
      <div className={`green-circle ${loading ? 'zoom-animation' : ''}`}>
        <img className={`rolling-logo ${loading ? 'loading-logo-animation' : ''}`} src={logo} alt="Logo" />
      </div>
    </div>
    </div>
  );
};

export default LogoComponent;




