import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Btn from '../../components/Btn';
import Footer from '../../components/Footer';
import {
    Header,
    Textfield
} from '../../components/exports';
import '../../css/Profile.css';

export default function EditProfile({ navigation }) {
    const darkMode = useSelector((state) => state?.common?.darkMode);
    //const [avatarsrc, setAvatarsrc] = useState(avatarUri ? { uri: avatarUri } : avatar); // Initialize based on avatarUri if available, or use default avatar

    const userDetails = useSelector((state) => state?.user?.userDetails);

    useEffect(() => {
        // Start of Tawk.to Script
        var Tawk_API = Tawk_API || {},
          Tawk_LoadStart = new Date();
        (function () {
          var s1 = document.createElement('script'),
            s0 = document.getElementsByTagName('script')[0];
          s1.async = true;
          s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
          s1.charset = 'UTF-8';
          s1.setAttribute('crossorigin', '*');
          s0.parentNode.insertBefore(s1, s0);
        })();
        // End of Tawk.to Script
    
        // Clean up the script when the component unmounts
        return () => {
          // Add any cleanup code here, if necessary
        };
      }, []); // Empty depe


      interface CustomImage {
        base64: string | null;
        type: string | null;
        uri: string | null;
      }
      
      const selectImage = async () => {
        try {
          // Simulating image selection in a web environment
          const fileInput = document.createElement('input');
          fileInput.type = 'file';
          fileInput.accept = 'image/*';
          fileInput.click();
      
          fileInput.addEventListener('change', async (event) => {
            const target = event.target;
            const selectedFile = target.files?.[0];
      
            if (selectedFile) {
              const reader = new FileReader();
              reader.onload = () => {
                const selectedImage = {
                  base64: reader.result?.toString().split(',')[1] || null,
                  type: selectedFile.type || null,
                  uri: reader.result?.toString() || null,
                };
                setAvatarSource({ uri: reader.result?.toString() || '' });
                 saveAvatarToStorage(reader.result?.toString() || '');
              };
      
              reader.readAsDataURL(selectedFile);
            }
          });
        } catch (error) {
          console.error('Error during image selection:', error);
        }
      };
      
      // Function to save the selected image to localStorage
      const saveAvatarToStorage = async (uri) => {
        try {
          localStorage.setItem('Avatar', uri);
          console.log('Avatar Saved Successfully');
          // Export the new avatar for use in other components
          // You can use a global state management library like Redux for this
          // Or pass it as a prop to other components
        } catch (error) {
          console.error('Error saving avatar to localStorage:', error);
        }
      };
      
      const getAvatarFromStorage = () => {
        try {
          const avatarUri = localStorage.getItem('Avatar');
          return avatarUri;
        } catch (error) {
          console.error('Error getting avatar from localStorage:', error);
          return null;
        }
      };
      
      const [avatarUri, setAvatarUri] = useState(getAvatarFromStorage()); // Step 2
      const [avatarSource, setAvatarSource] = useState(avatarUri ? { uri: avatarUri } : '');


      useEffect(() => {
        const fetchAvatar = async () => {
          const uri = await getAvatarFromStorage(); // Step 3
          setAvatarUri(uri); // Step 4
        };
        fetchAvatar();
      }, []);



      const handleImageChange = (event) => {
        const selectedFile = event.target.files[0];
      
        if (selectedFile) {
          const reader = new FileReader();
          reader.onload = () => {
            const selectedImage = {
              uri: reader.result,
            };
            setAvatarSource(selectedImage);
            saveAvatarToStorage(reader.result);
          };
      
          reader.readAsDataURL(selectedFile);
        }
      };
      
      // Define a default image URL or handle null case accordingly
      const defaultImageURL = 'default_image_url'; 


      const navigate = useNavigate();

      const handleSave = async (values) => {
        // Your save logic here
    
        // After saving, navigate to another page or exit the current page
        navigate('/Profile');

        };




    return (
        <div>
            <Header />
        <Container>
            <div>
                <Col>
                  
                </Col>
                <div>
                    <h1>Edit Profile</h1>

                    <div className=''>
                    <div>
                    <input
  type="file"
  accept="image/*"
  onChange={(e) => handleImageChange(e)}
/>
<img
  src={avatarSource.uri || defaultImageURL}
  alt="Profile Pic"
  className='profile_pic'
/>
                    </div>

                        <p className='refferal'>Referral code</p>
                        <p className='refferal_code'>
                         {userDetails?.body?.user?.referral_code}
                        </p>
                    </div>

                    <Formik
                        initialValues={{
                            fullName: '',
                            email: '',
                            userName: '',
                            phone: '',
                            
                        }}
                        onSubmit={handleSave}
                    >
                        {
                            ({ handleSubmit }) => (
                                <div>
                                    <Textfield name='fullName' title='Fullname' />
                                    <Textfield name='email' title='Email' />
                                    <Textfield name='userName' title='Username' />
                                    <Textfield name='phone' title='Phone' />

                                    <Btn text='Save' type='submit' onClick={handleSave} />
                                </div>
                            )
                        }
                    </Formik>
                </div>
            </div>
            
        </Container>
        <Footer />
        </div>
    )
}