import axios from 'axios';
import { Formik, useField} from 'formik';
import React, { useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import * as yup from 'yup';
import Footer from '../components/Footer';
import { logo } from '../assets/exports';
import { Textfield } from '../components/Textfield';
import { Link, useNavigate } from 'react-router-dom';
import { showError } from '../components/Error';
import Btn from '../components/Btn';
import DatePicker from 'react-datepicker'; // Import the date picker
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';


import '../css/Signup.css'; // Add your CSS file for styling

const Signup = ({ }) => {
  const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid format').required('Required'),
    password: yup
      .string()
      .required('Password is required')
      .typeError('Password must be a string')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*/?&])[A-Za-z\d@$!/%*?&]{8,}$/,
        'Password must have at least 8 characters, including an uppercase letter, a lowercase letter, a number, and a special character'
      ),
    confirmPassword: yup
      .string()
      .required('Re-Password is required')
      .oneOf([yup.ref('password'), null], 'Re-Password must match the Password'),
    phoneNumber: yup
      .string()
      .required('Phone number is required')
      .matches(/^[0-9]{11}$/, 'Phone number must be 11 digits'),
    dob: yup.string().required('Date Of Birth is required').typeError('Date of Birth must be provided'),
    bvn: yup.string().test('is-bvn-required', 'Bank Verification Number is required', function (value) {
      const nin = this.parent.nin;
      if (!nin && !value) {
        return this.createError({
          path: 'bvn',
          message: 'Bank Verification Number is required',
        });
      }
      return true;
    }).matches(/^[0-9]{11}$/, 'BVN must be 11 digits'),
    nin: yup.string().test('is-nin-required', 'National Identification Number is required', function (value) {
      const bvn = this.parent.bvn;
      if (!bvn && !value) {
        return this.createError({
          path: 'nin',
          message: 'National Identification Number is required',
        });
      }
      return true;
    }).matches(/^[0-9]{11}$/, 'NIN must be 11 digits'),
  });

  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dobFieldValue, setDobFieldValue] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  


  const handleDateChange = (date, setFieldValue) => {
    setShowDatePicker(false);
  
    if (date) {
      setSelectedDate(date);
      const formattedDate = format(date, 'dd-MMM-yyyy');
      console.log('Formatted Date:', formattedDate);
      setFieldValue('dob', formattedDate);
      setDobFieldValue(formattedDate); // Use the full ISO string
    }
  };

  const signUp = async (values) => {
    try {
      
       
      console.log('values', values);
      const res = await axios.post('https://dev.api.teepay.site/auth/register', values);

      setLoading(false);
      const userData = { ...values, token: res.data?.body?.token };
      console.log(userData);
      history('/Otp', {state : userData});
      // Pass user data to the OTP screen
    } catch (error) {
      setLoading(false);
      console.error('Signup failed:', error);
      showError(error.response.data?.message);
    }
  };

  return (
    <div>
    <Container>
    <div className="signup-body">
      <img src={logo} alt="Logo" className="logo" />
      <h1>Sign Up</h1>
      <Formik
   initialValues={{
    email: '',
    fullName: '',
    userName: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    referralCode: '',
    bvn: '',
    nin: '',
    dob: '',
  }}
  onSubmit={(values, { setSubmitting }) => {
    // Disable the submit button during the asynchronous submission
    setSubmitting(true);

    const trimmedValues = {
      ...values,
      email: values.email.trim(),
      userName: values.userName.trim(),
    };

    if (!trimmedValues.bvn && !trimmedValues.nin) {
      showError('Either BVN or NIN is required');
      setSubmitting(false); // Enable the submit button
      return;
    }

    const body = {
      "full_name": trimmedValues.fullName,
      "username": trimmedValues.userName,
      "email": trimmedValues.email,
      "phone": trimmedValues.phoneNumber,
      "password": trimmedValues.password,
      "cpassword": trimmedValues.confirmPassword,
      "dob": trimmedValues.dob,
    };

    if (trimmedValues.bvn) {
      body.bvn = trimmedValues.bvn;
    }

    if (trimmedValues.nin) {
      body.nin = trimmedValues.nin;
    }

    if (trimmedValues.referralCode) {
      body.referred_by = trimmedValues.referralCode;
    }

    signUp(body)
      .finally(() => {
        // Enable the submit button after the submission is complete
        setSubmitting(false);
      });
  }}
  validationSchema={validationSchema}
>
  {({ values, handleSubmit,  setFieldValue}) => (
    <div className="align-end">
      <p className='warning'> Ensure to fill in your full name as it is on your bvn/nin else our system will not verify you</p>
      <Textfield name="fullName" title="Fullname" icon="user" />
      <Textfield name="email" title="Email" icon="email" />
      <Textfield name="userName" title="Username" icon="user" />
      <Textfield name="phoneNumber" title="Phone" icon="phone" keyboardType="number-pad" />
      <Textfield name="password" title="Password" type="password" />
      <Textfield name="confirmPassword" title="Re-Password" type="password" />
      <Textfield name="referralCode" title="Referral-Code(Optional)" />
      <p className='warning'> Provide Either your BVN/NIN in the respective field below</p>
      <Textfield name="bvn" title="Bank Verification Number" keyboardType={'number-pad'} />
      <Textfield name="nin" title="National Identification Number" keyboardType={'number-pad'} />
      <Textfield
  name="dob"
  title="Date Of Birth"
  onClick={() => setShowDatePicker(true)}
  value={dobFieldValue}
/>

{showDatePicker && (
  <DatePicker
    selected={startDate}
    onChange={(date) => {
      setStartDate(date); // Update the startDate state
      handleDateChange(date, setFieldValue); // Call the handleDateChange function
    }}
    dateFormat="dd-MMM-yyyy"
  />
)}

      <div>
            <p className='t3'>By Signing Up you agree to the <a href="https://teepay.site/privacy-policy/" target="_blank" rel="noopener noreferrer">
      Terms and Conditions
    </a> of TEEPAYEEDATA</p>
          </div>

      <Btn
        variant="primary"
        text={loading ? 'Signing Up...' : 'Sign Up'}
        type="submit"
        style={{ marginTop: '20px' }}
        className="btn"
        onClick={() => {
          console.log('Login button clicked');
          handleSubmit();
        }}
        disabled={loading}
      />
    </div>
  )}
</Formik>

      <div className="t-wrapper">
        <span className="t1">Already a user? </span>
        <span className="t2" onClick={() => history('/')}>Login here</span>
      </div>
    </div>
    </Container>
    <Footer />
    </div>
  );
}

export default Signup;
