import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
    Col,
    Container
} from 'react-bootstrap';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import {
    avatar,
    dstv
} from '../assets/exports';
import { showError } from '../components/Error';
import Footer from '../components/Footer';
import { Header } from '../components/Header';
import '../css/Transactions.css';

export default function Transactions({ navigation }) {
    const transactions = [
        {
            img: dstv,
            transaction: 'Compact Subscription',
            time: '28 Nov 2021 • 12:01 am ',
            amount: 212,
            status: 'negative',
        },
        {
            img: avatar,
            transaction: 'Recieved from John Obi',
            time: '28 Nov 2021 • 12:01 am ',
            amount: 200,
            status: 'positive',
        },
        {
            img: dstv,
            transaction: 'Compact Subscription',
            time: '28 Nov 2021 • 12:01 am ',
            amount: 212,
            status: 'negative',
        },
        {
            img: avatar,
            transaction: 'Recieved from John Obi',
            time: '28 Nov 2021 • 12:01 am ',
            amount: 200,
            status: 'positive',
        },
        {
            img: dstv,
            transaction: 'Compact Subscription',
            time: '28 Nov 2021 • 12:01 am ',
            amount: 212,
            status: 'negative',
        },
        {
            img: avatar,
            transaction: 'Recieved from John Obi',
            time: '28 Nov 2021 • 12:01 am ',
            amount: 200,
            status: 'positive',
        },
    ];

    const userDetails = useSelector((state) => state?.user?.userDetails);
    const token = useSelector((state) => state?.user?.token);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [dashboardData, setDashboardData] = useState({});
    const darkMode = useSelector((state) => state?.common?.darkMode);
    console.log(transactionHistory,'transactionHistory');
    const [showModal, setShowModal] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const getUserTransactionHistory = async (newOffset) => {
            try {
                const { data } = await axios.get(`https://dev.api.teepay.site/transaction?limit=20&offset=${newOffset}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log(data?.body?.transactions, 'transaction history data');
                if (data) {
                    setTransactionHistory(data?.body?.transactions);
                }
            } catch (error) {
                console.log(error.response?.data, 'error loading transaction data');
            }
        };

        const getDashboardData = async () => {
            try {
                const res = await axios.get('https://dev.api.teepay.site/user/dashboard', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log(res.data, 'dashboard data');
                setDashboardData(res.data?.body);
            } catch (error) {
                { showError(error?.response?.data) }
                console.log(error.response.data.message)
            }
        };

        if (token) {
            getUserTransactionHistory(offset);
            getDashboardData();
        }

    }, [offset]);

    const handlePageChange = (newOffset) => {
        setOffset(newOffset);
    };



    const handleTransactionClick = async (transactionId) => {
        try {
            console.log('Fetching transaction details for ID:', transactionId);
            const response = await axios.get(`https://dev.api.teepay.site/transaction/${transactionId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            const transactionData = response.data.body;
            setTransactionDetails(transactionData);
            console.log('Transaction details:', transactionData);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching transaction details:', error);
        }
    };
    const getAmountClassName = (type, status) => {
        if (status === 'pending') {
          return 'pending';
        } else if (type === 'debit' && status === 'success') {
          return 'debit-success';
        } else if (type === 'credit' && status === 'success') {
          return 'credit-success';
        } else {
          return ''; // No additional class for other cases
        }
      };

      const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        // Format the date to a readable string, e.g., "Mon, Nov 22, 2023 10:30 AM"
        const formattedDate = date.toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' });
        return formattedDate;
      };

      useEffect(() => {
        // Start of Tawk.to Script
        var Tawk_API = Tawk_API || {},
          Tawk_LoadStart = new Date();
        (function () {
          var s1 = document.createElement('script'),
            s0 = document.getElementsByTagName('script')[0];
          s1.async = true;
          s1.src = 'https://embed.tawk.to/6574950d70c9f2407f7ddc63/1hh7mcgss';
          s1.charset = 'UTF-8';
          s1.setAttribute('crossorigin', '*');
          s0.parentNode.insertBefore(s1, s0);
        })();
        // End of Tawk.to Script
    
        // Clean up the script when the component unmounts
        return () => {
          // Add any cleanup code here, if necessary
        };
      }, []); // Empty depe

    return (
        <div>
        <Container>
            <Col>
              <Header />
            </Col>

                <div className='header'>
                    <h1>Transactions</h1>
                </div>

                {/* Pagination */}
                <div className='paginationContainer'>
                {offset > 0 && (
                        <div
                            className='Previous'
                            onClick={() => handlePageChange(offset - 1)} // Go to previous page
                        >
                            <p>Previous</p>
                        </div>
                    )}
                    {transactionHistory.length > 0 && (
                         <div
                            className='Next'
                            onClick={() => handlePageChange(offset + 1)}
                         >
                           <p>Next</p>
                        </div>
                    )}
                </div>

                {
                    transactionHistory.map((_, i) => {
                        return (
                            <div
                                className='transaction'
                                key={i}
                                onClick={() => handleTransactionClick(_?.id)}
                            >
                                <div className=''>
                                    {/* <img
                                        src={_.img}
                                        className=''{styles.transaction_img}
                                    /> */}
                                    <div>
                                        <p className='transaction_name'>{_?.short_desc || ''}</p>
                                        <p className='time'>{formatDate(_?.createdAt)}</p>
                                    </div>
                                </div>

                                <div className={`amount ${(() => {
  if (_?.type === 'debit') {
    if (_?.status === 'completed' || _?.status === 'failed') {
      return 'red';
    } else if (_?.status === 'pending') {
      return 'orange';
    }
  } else if (_?.type === 'credit') {
    if (_?.status === 'completed') {
      return 'green';
    } else if (_?.status === 'pending') {
      return 'orange';
    }
  }
  return 'black'; // Default color if none of the conditions match
})()}`}>
  {_?.type === 'debit' && _?.status === 'success' ? '-' : ''}
  {_?.type === 'credit' && _?.status === 'success' ? '+' : ''}
  {parseFloat(_?.amount).toLocaleString('en-NG', {
    style: 'currency',
    currency: 'NGN',
  })}
</div>
                            </div>
                        )
                    })
                }

<ReactModal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
    <div className='modalContainer'>

                

                

        {/* Display transaction details here */}
        {transactionDetails && (
            <div>
                <div>
                    <h3>Transaction Details</h3>
                </div>
                <p className='items'>Transaction ID: {transactionDetails.transaction.id}</p>
                <p className='items'>Transaction Reference: {transactionDetails.transaction.parent_ref}</p>
                <p className='items'>Amount: {transactionDetails.transaction.amount}</p>
                <p className='items'>Date: {formatDate(transactionDetails.transaction.createdAt)}</p>
                <p className='items'>Description: {transactionDetails.transaction.short_desc}</p>
                <p className='items'>Status: {transactionDetails.transaction.status}</p>
                <p className='items'>Type: {transactionDetails.transaction.type}</p>
                <p className='items'>Payment Method: {transactionDetails.transaction.payment_method}</p>
                <p className='items'>Provider: {transactionDetails.transaction.provider}</p>
                <p className='items'>Receiver: {transactionDetails.transaction.receiver}</p>
                <p className='items'>Token: {transactionDetails.transaction.value}</p>
                <p className='items'>GiftCard Number: {transactionDetails.transaction.cardNumber}</p>
                <p className='items'>GiftCard PinCode: {transactionDetails.transaction.pinCode}</p>
                <p className='items'>Coupon: {transactionDetails.transaction.coupon}</p>
                <p className='items'>Discount: {transactionDetails.transaction.discount}</p>
            </div>
        )}

        <div
            className='closeButton'
            onClick={() => setShowModal(false)}
        >
            <p className='closeButtonp'>Close</p>
        </div>
    </div>
</ReactModal>
<Col>
 
</Col>
        </Container>
        <Footer />
        </div>
    )
}